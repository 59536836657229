<template>
        <ValidationObserver tag="div" ref="observer" v-slot="{ invalid, validated, passes, validate }">
            <PageLayout
                @drop.prevent="dragover = false"
                @dragover.prevent="dragover = true"
                @dragenter.prevent="dragover = true"
                @dragend.prevent="dragover = false"
                :disabled="false"
            >
                <template :disabled="closed">
                    <v-row :disabled="closed">
                        <v-col cols="12">
                            <v-row class="flex-nowrap">
                                <v-col cols="12">
                                    <v-btn :disabled="userBlock" :to="{name: 'deal'}" v-if="false">
                                        <v-icon color="primary">mdi-less-than</v-icon>
                                    </v-btn>
                                    <h1>
                                        {{ heading }} {{ business_process && business_process.name ? '( ' + business_process.name + ' )' : '' }}
                                        <v-icon :disabled="userBlock" v-if="!loadingDeal" :color="favorite ? 'primary' : ''" size="20" class="mb-2 ml-2" @click="favoriteUpdate()"> {{ favorite ? 'mdi-star' : 'mdi-star-outline' }}</v-icon>
                                        <v-icon :disabled="userBlock || loading" v-if="!loadingDeal" @click="approvalSheet" size="20" class="mb-2 ml-2" :title="$t('process_sheet')">mdi-clipboard-list-outline</v-icon>
                                        <v-icon :disabled="userBlock" size="18" @click="share" v-if="isShareAvailable" class="mb-2 ml-2">mdi-share-variant-outline</v-icon>
                                        <v-icon @click="back('deal')" size="20" class="mb-2 ml-2" :title="$t('back')">mdi-arrow-left</v-icon>
                                    </h1>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>

                    <v-row :disabled="closed">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-row class="border_bottom_6" :style="'border-color: '+step_color+' !important;'">
                                        <v-col cols="12" sm="3" v-if="author && author.name">
                                            <User :item="author">
                                                <template v-slot:position>{{$t('director')}}</template>
                                            </User>
                                        </v-col>
                                        <v-col cols="12" sm="3" v-if="responsible && responsible[0] !== null && responsible[0] !== undefined  && responsible[0].name !== null">
                                            <User :item="responsible[0]" :disabled="userBlock || closed" @click="dialogAddParticipant=true">
                                                <template v-slot:position>{{$t('responsible')}}</template>
                                            </User>
                                        </v-col>

                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="company && company.name && false">
                                            <v-list subheader two-line class="background_none py-0">
                                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0 cursor_pointer" @click="showCompany(company.uuid)">
                                                        <img v-if="company.photo !== '/img/avatar.png'" :src="company.photo" :alt="company.name">
                                                        <v-icon v-else color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="company.name" class="cursor-pointer" @click="showCompany(company.uuid)"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('customer')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="created_at">
                                            <v-list class="background_none py-0">
                                                <v-list-item class="mx-0 px-0">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="$t('date_created')"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="created_at"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                        </v-col>
                                        <v-col class="pb-0 mb-0" cols="12" sm="3" v-if="deal_statusItems.length > 0 && deal_status !== null">
                                            <v-list class="background_none py-0">
                                                <v-list-item class="mx-0 px-0">
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="deal_statusItems.find(item => item.id === deal_status).name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="$t('deal_main_status')"></v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            <ValidationProvider v-if="false" ref="deal_status" rules="required" v-slot="{ errors, valid }">
                                                <v-select
                                                    v-model="deal_status"
                                                    :disabled="true"
                                                    class="my-0 py-0 font-size-10 select_border_none"
                                                    :items="deal_statusItems" :error-messages="errors"
                                                    single-line

                                                    color="primary" item-text="name" item-value="id"
                                                    return-object
                                                    @change="setStatusDeal"
                                                    :label="$t('deal_main_status')"></v-select>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col cols="12" sm="12">
                                            <div class="description_title">{{ $t('description_deal') }}</div>
                                            <div v-if="!text_deal_edit">
                                                <v-hover v-slot="{ hover }">
                                                    <div :disabled="userBlock || closed" v-if="text_deal !== ''" @click="text_deal_edit = true" class="description_text cursor_pointer">{{ text_deal }}
                                                        <v-btn v-if="hover"
                                                               :disabled="userBlock || closed"
                                                               icon
                                                               x-small
                                                               @click="text_deal_edit = true"
                                                        >
                                                            <v-icon color="primary">
                                                                mdi-pencil mdi-18px
                                                            </v-icon>
                                                        </v-btn>
                                                    </div>
                                                    <div v-else>
                                                        <span v-if="!closed" :disabled="userBlock" class="add_text" @click="text_deal_edit = true">{{ $t("add_text") }}</span>
                                                    </div>
                                                </v-hover>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" sm="12" class="ml-0 pl-2" v-if="text_deal_edit">
                                            <v-card elevation="0" class="m-0 p-0">
                                                <v-card-text class="my-0 py-0 mx-0 px-0">
                                                    <v-textarea
                                                        v-model="text_deal"
                                                        type="text"
                                                        class="input_textarea"
                                                        auto-grow
                                                        :disabled="userBlock || loading || closed"
                                                        no-details
                                                        outlined
                                                        hide-details
                                                    ></v-textarea>
                                                    <v-card-actions class="px-0 mx-0">
                                                        <v-btn
                                                            :disabled="userBlock || closed"
                                                            color="primary"
                                                            class="infinity_button"
                                                            @click="DealTextUpdate()"
                                                        >
                                                            {{ $t('save') }}
                                                        </v-btn>
                                                        <v-btn
                                                            text
                                                            class="infinity_button_text px-0"
                                                            @click="text_deal_edit = false; text_deal = ''"
                                                        >
                                                            {{ $t('cancel') }}
                                                        </v-btn>

                                                    </v-card-actions>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="dealStepItems.length > 0">
                                        <v-col cols="12" class="buttons-block">
                                            <template v-for="(item, index) in dealStepItems">
                                                <v-btn @click="checkStep(item)"
                                                       class="infinity_button"
                                                       :disabled="userBlock || loadingStepButton || !item.access || closed"
                                                       color="primary">
                                                    {{ item.name }}
                                                </v-btn>
                                            </template>
                                        </v-col>
                                        <v-col>
                                            {{deal_step_button_text}}
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="is_executor">
                                        <v-col cols="12" class="buttons-block" >
                                            <v-btn :disabled="userBlock || loading || closed" v-if="executor_deal_status === 'new'" @click="editStatusExecutor('accept')" class="infinity_button" color="primary">
                                                {{ $t('accept') }}
                                            </v-btn>
                                            <v-btn :disabled="userBlock || loading || closed"  v-if="executor_deal_status === 'new'"  @click="cancelDialogStatusExecutor('reject')" text plain class="infinity_button_text pl-1">
                                                {{ $t('reject') }}
                                            </v-btn>
                                            <v-btn :disabled="userBlock || loading || closed"  v-if="executor_deal_status === 'accept'"  @click="editStatusExecutor('done')" class="infinity_button" color="primary">
                                                {{ $t('done') }}
                                            </v-btn>
                                            <v-btn :disabled="userBlock || loading || closed"   v-if="executor_deal_status === 'accept'" @click="cancelDialogStatusExecutor('fail')" text plain class="infinity_button_text">
                                                {{ $t('fail') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1">
                        <v-col cols="12" md="4" class="pr-2">
                            <div class="tab-btn" :class="{'active': display_task}">
                                <div class="d-flex ml-2">
                                    <div class="mr-auto cursor-pointer_header menu_tab" :class="display_task ? 'active' : ''" @click="display_open('display_task')">
                                        {{ $t('tasks') }}<template v-if="totalTasks > 0">: <span class="text-secondary">{{ totalTasks }}</span></template>
                                    </div>
                                    <v-icon :disabled="userBlock || closed" @click="addTask" size="18" color="secondary" class="add_icon mr-2">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                    <v-icon :disabled="userBlock || closed" @click="linkTask" size="20" color="secondary" class="add_icon">
                                        mdi-link
                                    </v-icon>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-2">
                            <div class="tab-btn" :class="{'active': display_parent_deal}">
                                <div class="d-flex ml-2">
                                    <div class="mr-auto cursor-pointer_header menu_tab" :class="display_parent_deal ? 'active' : ''" @click="display_open('display_parent_deal')">
                                        {{ $t('deal.self') }}<template v-if="parentDealItems && parentDealItems.length > 0">: <span class="text-secondary">{{ parentDealItems.length }}</span></template>
                                    </div>
                                    <v-icon :disabled="userBlock || !under_deal || closed" @click="addParentDeal" size="18" color="secondary" class="add_icon mr-2">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                </div>
                            </div>
                        </v-col>

                        <v-col cols="12" md="4" class="pl-2" v-if="false">
                            <div class="tab-btn" :class="{'active': display_invoice}">
                                <div class="d-flex ml-2">
                                    <div class="mr-auto cursor-pointer_header menu_tab" :class="display_invoice ? 'active' : ''" @click="display_open('display_invoice')">
                                        {{ $t('payments') }}<template v-if="invoices && invoices.length > 0">: <span class="text-secondary">{{ invoices.length }}</span></template>
                                    </div>
                                    <v-icon :disabled="userBlock || closed" @click="addInvoice" size="18" color="secondary" class="add_icon mr-2">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                    <v-icon :disabled="userBlock || closed" @click="linkInvoice" size="20" color="secondary" class="add_icon">
                                        mdi-link
                                    </v-icon>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-2" v-if="can(['client_module'])">
                            <div class="tab-btn" :class="{'active': display_tracking}">
                                <div class="d-flex ml-2">
                                    <div class="mr-auto cursor-pointer_header menu_tab" :class="display_tracking ? 'active' : ''" @click="display_open('display_tracking')">
                                        {{ $t('tracking') }}<template v-if="trackings && trackings.length > 0">: <span class="text-secondary">{{ trackings.length }}</span></template>
                                    </div>
                                    <v-icon v-if="false" :disabled="userBlock || closed" @click="addOperationTracking((trackings[0] && trackings[0].uuid) ? trackings[0].uuid : null)" size="18" color="secondary" class="add_icon">
                                        mdi-link
                                    </v-icon>
                                    <v-icon v-if="!trackings[0]" :disabled="userBlock || closed" @click="addTracking" size="18" color="secondary" class="add_icon mr-2">
                                        mdi-plus-circle-outline
                                    </v-icon>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-2">
                            <div class="tab-btn" :class="{'active': display_deal}">
                                <div class="px-2 cursor-pointer_header menu_tab" :class="display_deal ? 'active' : ''" @click="display_open('display_deal')">{{ $t('accounts') }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-2">
                            <div class="tab-btn" :class="{'active': display_case}">
                                <div class="px-2 cursor-pointer_header menu_tab" :class="display_case ? 'active' : ''" @click="display_open('display_case')">{{ $t('case') }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-2" v-if="can(['client_module'])">
                            <div class="tab-btn" :class="{'active': display_chat}" >
                                <div class="d-flex ml-2">
                                    <div class="mr-auto cursor-pointer_header menu_tab" :class="display_chat ? 'active' : ''" @click="display_open('display_chat')">
                                        {{ $t('chat') }}{{ topicItems.length > 0 ? ': ' + topicItems.length : '' }}
                                    </div>
                                    <div v-if="!topicAddShowIcon">
                                        <v-icon :disabled="userBlock || closed" @click="getMyTopics" size="20" color="secondary" class="add_icon mr-2">
                                            mdi-link
                                        </v-icon>
                                        <v-icon :disabled="userBlock || closed" @click="addMyTopic" size="18" color="secondary" class="add_icon mr-2">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" class="pl-2">
                            <div class="tab-btn" :class="{'active': display_document}">
                                <div class="d-flex ml-2">
                                    <div class="mr-auto cursor-pointer_header menu_tab" :class="display_document ? 'active' : ''" @click="display_open('display_document')">
                                        {{ $t('documents') }}<template v-if="dealDocumentItems && dealDocumentItems.length > 0">: <span class="text-secondary">{{ dealDocumentItems.length }}</span></template>
                                    </div>
                                    <div class="mr-2">
                                        <v-icon :disabled="userBlock || closed" size="18" color="secondary" class="add_icon" @click="document.getElementById('add_documents').click()">
                                            mdi-plus-circle-outline
                                        </v-icon>
                                    </div>
                                </div>
                                <v-file-input
                                    hide-input prepend-icon=""
                                    v-model="add_documents"
                                    id="add_documents"
                                    multiple
                                    @change="previewDocuments"
                                    style="display: none"
                                >
                                </v-file-input>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" v-if="can(['client_module'])">
                            <div class="tab-btn" :class="{'active': display_application}">
                                <div class="px-2 cursor-pointer_header menu_tab" :class="display_application ? 'active' : ''" @click="display_open('display_application')">{{ $t('application') }}</div>
                            </div>
                        </v-col>
                        <v-col cols="12" md="4" v-if="can(['client_module'])">
                            <div class="tab-btn" :class="{'active': display_order}">
                                <div class="px-2 cursor-pointer_header menu_tab" :class="display_order ? 'active' : ''" @click="display_open('display_order')">{{ $t('order') }}</div>
                            </div>
                        </v-col>
                        <FormTab v-for="tab in tabs" :key="tab.id"  :tab="tab" @show-tab="showTab"   />

                    </v-row>
                    <FormTabField v-for="tab in tabs"  entity_type="deal" :entity_uuid="deal_uuid" :key="tab.id"  :tab="tab"  />

                    <v-row class="py-0 mt-1" v-if="display_task">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-data-table :headers="task_headers" :items="taskItems"
                                                  :loading="loadingTasks" :locale="lang"

                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer

                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass">

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table5">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.title="{ item }" class="text">
                                            <v-btn text plain :to="'/task/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link" :style="'color:'+ item.color+'!important'">
                                                {{ item.title }}
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.task_status="{ item }">
                                            <div>{{ item.task_status }}</div>
                                        </template>
                                        <template v-slot:item.deadline="{ item }">
                                            <div>{{ item.deadline ? $moment(item.deadline).format('DD.MM.YYYY HH:mm') : '' }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>

                                        <template v-slot:item.admin.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>

                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_invoice">
                        <v-col cols="12">
                            <v-card :class="invoice_error">
                                <v-card-text>
                                    <v-data-table :headers="invoice_headers" :items="invoices"

                                                  :loading="loadingInvoices" :locale="lang"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer

                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass2">

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table7">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span v-if="h.value !== 'download'">{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                                    <template v-slot:item.download="{ item }">
                                                        <div>
                                                            <v-icon color="primary" @click="downloadFile(item.request_scanned)" :title="$t('download')">mdi-cloud-upload</v-icon>
                                                        </div>
                                                    </template>
                                                    <template v-slot:item.amount="{ item }">
                                                        <div>
                                                            <v-btn text plain :to="'/invoice/' + item.id + '/edit'"  class="cursor-pointer font_weight_600 button_link"  >
                                                                {{ item.amount }}
                                                            </v-btn>
                                                            <v-icon v-if="!item.deleted" @click="deleteInvoice(item)" small>mdi-delete-outline</v-icon>
                                                        </div>
                                                    </template>
                                                    <template v-slot:item.date="{ item }">
                                                        <div>{{ $moment(item.date).format('DD.MM.YYYY') }}</div>
                                                    </template>
                                                    <template v-slot:item.number_deal="{ item }">
                                                        <div v-if="item.number_deal">{{ item.number_deal }}</div>
                                                        <div v-else>{{ $t('no') }}</div>
                                                    </template>
                                                    <template v-slot:item.invoice_status="{ item }">
                                                        <div class="cursor-pointer" @click="openDialogInvoiceStatus(item)">{{ item.invoice_status }}</div>
                                                    </template>
                                                    <template v-slot:item.uved="{ item }">
                                                        <div>{{ item.uved }}</div>
                                                    </template>
                                                    <template v-slot:item.admin.name="{ item }">
                                                        <div>
                                                            <v-list subheader two-line class="background_none py-0">
                                                                <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                                        <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                                        <v-icon v-else color="primary" size="22">
                                                                            ${{'settingsIcon'}}
                                                                        </v-icon>
                                                                    </v-list-item-avatar>
                                                                    <v-list-item-content class="py-0 title_subtitle">
                                                                        <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                                        <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                                    </v-list-item-content>
                                                                </v-list-item>
                                                            </v-list>
                                                        </div>
                                                    </template>

                                                </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_parent_deal">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-data-table :headers="parent_deal_headers" :items="parentDealItems"

                                                  :loading="loadingParentDeals" :locale="lang"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer

                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass4">

                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass Table6">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span v-if="h.value !== 'download'">{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.number="{ item }" class="text">
                                            <v-btn text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link" :style="'color:'+ item.color+'!important'">
                                                {{ item.number }}
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.name="{ item }" class="text">
                                            <v-btn text plain :to="'/deal/' + item.uuid + '/show'" class="cursor-pointer font_weight_600 button_link" :style="'color:'+ item.color+'!important'">
                                                {{ item.name }}
                                            </v-btn>
                                        </template>
                                        <template v-slot:item.deal_status="{ item }">
                                            <div>{{ item.deal_status }}</div>
                                        </template>
                                        <template v-slot:item.business_process="{ item }">
                                            <div>{{ item.business_process }}</div>
                                        </template>

                                        <template v-slot:item.created_at="{ item }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                        </template>

                                        <template v-slot:item.admin.name="{ item }">
                                            <div>
                                                <v-list subheader two-line class="background_none py-0">
                                                    <v-list-item class="px-0 py-0 my-0 min_height_auto">
                                                        <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                            <img v-if="item.admin.photo !== '/img/avatar.png'" :src="item.admin.photo" :alt="item.admin.name">
                                                            <v-icon v-else color="primary" size="22">
                                                                ${{ 'settingsIcon' }}
                                                            </v-icon>
                                                        </v-list-item-avatar>
                                                        <v-list-item-content class="py-0 title_subtitle">
                                                            <v-list-item-title v-text="item.admin.name"></v-list-item-title>
                                                            <v-list-item-subtitle v-text="formatPhoneNumber(item.admin.phone)"></v-list-item-subtitle>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list>
                                            </div>
                                        </template>


                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-show="display_case">
                        <v-col cols="12" >
                            <v-card class="box_shadow_block">
                                <v-card-text class="px-3">
                                    <calendar-case ref="calendarCase"></calendar-case>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_tracking">
                        <template  v-if="$vuetify.breakpoint.smAndDown">
                            <v-col cols="12" >
                            <ListItem
                                v-for="item in trackings"
                                :item="item"
                                :key="item.id"
                                @showDialogTracking="showDialogTracking(item)"
                                @showDetailsApplication="showDetailsApplication(item)"
                            >
                            </ListItem>
                            </v-col>
                        </template>
                        <template v-else>
                            <v-col cols="12" >
                                <v-card class="box_shadow_block" :class="tracking_error">
                                    <v-card-text class="px-3">
                                        <v-row v-if="trackings.length > 0">
                                            <v-col cols="12" class="my-0 py-0 pt-5">
                                                <v-card flat >
                                                    <v-card
                                                        elevation="0"
                                                        :hover="true"
                                                        v-for="(item, i) in trackings"
                                                        :key="'tracking_'+i"
                                                        class="mb-7 "
                                                        :class="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly) ? 'text-center' :''"

                                                        color="#FFFFFF"

                                                    >

                                                        <v-card-text class="px-0 py-0"  @click="showDetailsApplication(item)">


                                                            <v-row class="px-5 pb-2">

                                                                <v-col cols="2">
                                                                    <div class="deal_bold" @click="showDetailsApplication(item)">
                                                                        {{ item.date_operation ? $moment(item.date_operation).format('DD.MM.YYYY') : '' }}
                                                                        <v-icon small color="primary">mdi-chevron-down</v-icon>
                                                                    </div>
                                                                    <div v-if="item.deal_topic && item.deal_topic.uuid">
                                                                        <v-btn text plain :to="'/topic/' + item.deal_topic.uuid"  class="cursor-pointer tracking_link button_link">
                                                                            <v-icon color="primary" small>icon-messages</v-icon>
                                                                        </v-btn>

                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="3">
                                                                    <div class="deal_bold" @click="showDeal(item.deal_uuid)">
                                                                        <v-btn text plain :to="'/deal/' + item.deal_uuid + '/show'"  class="cursor-pointer tracking_link button_link">
                                                                            {{item.number_deal}}
                                                                        </v-btn>
                                                                    </div>

                                                                    <div class="color_grey" v-if="item.type_transport">
                                                                        {{item.type_transport}}
                                                                    </div>
                                                                    <div class="color_grey" v-if="item.loading_type && item.loading_type.name">{{item.loading_type.name}}</div>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <div class="deal_bold" v-if="item.uved_name">
                                                                        <v-btn text plain :to="'/uved/' + item.uved.uuid + '/show'"  class="cursor-pointer tracking_link button_link">
                                                                            {{item.uved_name}}
                                                                        </v-btn>
                                                                    </div>
                                                                    <div class="color_grey" v-if="item.uved_phones && item.uved_phones.phone">
                                                                        {{item.uved_phones.phone}}

                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="2">
                                                                    <div v-if="item.responsible && item.responsible.length > 0">
                                                                        <div  v-for="(responsible, i) in item.responsible.slice(0, 3)" :key="'responsible_'+i" 	>
                                                                            <v-icon small>icon-user</v-icon>
                                                                            {{ (responsible) ? responsible.name : '' }}
                                                                        </div>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="2" class="pl-8" >
                                                                    <div @click.stop="openDialogTrackingContainer(item)">
                                                                        <v-icon class="pb-2">icon-shipment</v-icon>
                                                                        <span class="deal_bold mx-2">{{ item.container_number ? item.container_number : $t('no_data') }}</span>
                                                                    </div>


                                                                </v-col>
                                                                <v-col cols="1" style="text-align: right">
                                                                    <div class="deal_bold">
                                                                        {{item.documents.length}}
                                                                        <v-icon small color="primary" class="pl-1">icon-file-text</v-icon>
                                                                    </div>
                                                                    <div class="deal_bold">
                                                                        {{item.deal_documents.length }}
                                                                        <v-icon small color="primary" class="pl-1">icon-file-text-check</v-icon>
                                                                    </div>
                                                                    <div v-if="false" class="deal_bold">
                                                                        Не подана
                                                                        <v-icon small color="primary" class="pl-1">icon-doc</v-icon>
                                                                    </div>
                                                                </v-col>


                                                            </v-row>

                                                            <v-row class="px-5 mb-2">
                                                                <v-col cols="2">
                                                                    <div v-if="!item.arrival_date_at_destination">
                                                                        <span style="color: #2b95eb">{{ $t('in_operation_for') }}:</span>
                                                                        <span class="color_grey"> {{ $t('for_day', {"day": item.day}) }}</span>
                                                                    </div>
                                                                    <div v-else>
                                                                        <span style="color: #2b95eb">{{ $t('arrived') }}: </span>
                                                                        <span class="color_grey">{{ $moment(item.arrival_date_at_destination).format('DD.MM.YYYY') }} </span>
                                                                    </div>
                                                                </v-col>
                                                                <v-col cols="3" class="color_grey">
                                                                    <div v-if="item.finish === true || item.step_stop === false">
                                                                        <span class="color_grey">{{ $t('current_station') }}:</span>
                                                                        <span class="deal_bold">
                                                                            {{ item.station ? item.station : item.departure }}
                                                                        </span>
                                                                    </div>
                                                                    <div v-else>
                                                                        <span class="color_grey">{{ $t('current_station') }}:</span>
                                                                        <span class="deal_bold cursor-pointer" @click.stop="showDialogTracking(item)">
                                                                            {{ item.station ? item.station : item.departure }}
                                                                            <v-icon small color="primary" class="ml-1">icon-shipment-add</v-icon>
                                                                        </span>
                                                                    </div>

                                                                </v-col>
                                                                <v-col cols="12" sm="7"  class="pl-5 pr-0 mx-0 mt-3" v-if="!($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)">
                                                                    <v-row>
                                                                        <v-col cols="12">
                                                                            <ul class="pod_status d-flex">
                                                                                <template v-for="(status_item, i) in item.deal_main_statuses">
                                                                                    <li :key="'status'+status_item.id" class="li1">
                                                                                        <div class="node " :class="parseInt(item.deal_main_status_sort) >= parseInt(status_item.sort) ? 'blue_point' : 'grey_point'"></div>
                                                                                        <p :class="(i+1) === item.deal_main_statuses.length ? 'li_last' : ''">
                                                                                            <span :class="parseInt(item.deal_main_status_sort, 10)  >= parseInt(status_item.sort , 10) ? 'blue_color' : ''">{{ status_item.name }}</span>
                                                                                            <span :class="(i+1) !== item.deal_main_statuses.length ? 'photo_li' : 'photo_li_last'">
                                                                                                <v-img :src="item.deal_main_status_sort >= status_item.sort  ? status_item.photo_active : status_item.photo" width="23px"></v-img>
                                                                                            </span>
                                                                                        </p>
                                                                                    </li>
                                                                                    <li :key="'status'+status_item.id + '-' + status_item.id" v-if="(i+1) !== item.deal_main_statuses.length" class="my_divider "
                                                                                        :class="item.deal_main_status_sort > status_item.sort ? 'blue_line' : 'grey_line'">
                                                                                    </li>
                                                                                </template>
                                                                            </ul>
                                                                        </v-col>
                                                                    </v-row>
                                                                </v-col>

                                                            </v-row>
                                            </v-card-text>
                                        </v-card>
                                    </v-card>
                                </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </template>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_chat">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text>
                                    <v-list two-line class="background_color_transparent">
                                        <v-list-item-group
                                            v-model="selected"
                                            class="my-0"
                                        >
                                            <v-list-item
                                                v-for="(item, i) in topicItems"
                                                :key="i"
                                                :class="'rounded-lg  ' + item.class + ' ' + ($vuetify.breakpoint.xsOnly ? 'pl-15' : 'px-3')"
                                                class="my-1 height_list_item"
                                                @click="openChat(item)"
                                            >
                                                <template v-slot:default="{ active }">
                                                    <v-app-bar
                                                        elevation="0"
                                                        absolute
                                                        class="my-5 py-0"
                                                        height="0"
                                                        v-if="!($vuetify.breakpoint.xsOnly)"
                                                    >
                                                        <v-spacer></v-spacer>
                                                        <v-icon class="icon_width " v-if="item.active">${{ 'lockOpenIcon' }}</v-icon>
                                                        <v-icon v-else class="icon_width">${{ 'lockIcon' }}</v-icon>
                                                    </v-app-bar>
                                                    <v-list-item-avatar size="58" class="border1_B3B3B3" v-if="!($vuetify.breakpoint.xsOnly)">
                                                        <img v-if="item.last_user.photo !== '/img/avatar.png'" :src="item.last_user.photo">
                                                        <v-icon v-else size="26" class="color_B3B3B3">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>

                                                    <v-list-item-content class="ml-6 topic_padding_top0">
                                                        <v-row v-if="($vuetify.breakpoint.xsOnly)" class="my-0 py-0">
                                                            <v-col cols="12" style="position: absolute;top:3px;left:-3px">
                                                                <v-avatar size="58" class="border1_B3B3B3">
                                                                    <img v-if="item.last_user.photo !== '/img/avatar.png'" :src="item.last_user.photo">
                                                                    <v-icon v-else size="26" class="color_B3B3B3">
                                                                        ${{ 'settingsIcon' }}
                                                                    </v-icon>
                                                                </v-avatar>
                                                            </v-col>
                                                            <v-col cols="12" style="position: absolute; bottom: 3px; left: 12px">
                                                                <v-icon class="icon_width" size="35" v-if="item.active">${{ 'lockOpenIcon' }}</v-icon>
                                                                <v-icon v-else size="50" class="icon_width">${{ 'lockIcon' }}</v-icon>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row class="topic_margin_top0">
                                                            <v-col cols="12" md="4" sm="4" lg="6" class="padding_top10_bottom0">
                                                                <v-list-item-title class="topic_title" v-text="item.title"></v-list-item-title>
                                                                <v-list-item-subtitle
                                                                    style="max-width: 400px"
                                                                    :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='text') ">
                                                                    <span class="font-weight-700">{{ item.last_user.my ? $t('from_you') : $t('from_name', {"name": item.last_user.name}) }}</span>
                                                                    {{ item.last_message.value }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle
                                                                    style="max-width: 400px"
                                                                    :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='file') ">
                                                                    <span class="font-weight-700">{{ item.last_user.my ? $t('from_you') : $t('from_name', {"name": item.last_user.name}) }}</span>
                                                                    {{ item.last_message.file_name }}
                                                                </v-list-item-subtitle>
                                                                <v-list-item-subtitle
                                                                    style="max-width: 400px"
                                                                    :class="item.bot ? 'topic_text_bot' : 'topic_text'"
                                                                    v-if="(item.last_message && item.last_message.type && item.last_message.type==='image') "
                                                                >
                                                                    <span class="font-weight-700">{{ item.last_user.my ? $t('from_you') : $t('from_name', {"name": item.last_user.name}) }}
                                                                        <v-img max-width="100" :src="item.last_message.value ? item.last_message.value : userAvatar"></v-img>
                                                                    </span>
                                                                </v-list-item-subtitle>
                                                            </v-col>
                                                            <v-col cols="12" md="4" sm="4" lg="3" class="padding_top10_bottom0">
                                                                <v-list-item-title class="topic_text">
                                                                    {{ $t('last_message') }}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle class="topic_title">
                                                                    {{ item.last_date }}
                                                                </v-list-item-subtitle>
                                                            </v-col>
                                                            <v-col cols="12" md="4" sm="4" lg="3" class="padding_top10_bottom0">
                                                                <v-list-item-title class="topic_text">
                                                                    {{ $t('application_status') }}
                                                                </v-list-item-title>
                                                                <v-list-item-subtitle class="topic_title">
                                                                    {{ item.bot ? $t('system_message') : (item.active ? $t('open_order') : $t('closed_order')) }}
                                                                </v-list-item-subtitle>
                                                            </v-col>
                                                        </v-row>
                                                    </v-list-item-content>
                                                </template>
                                            </v-list-item>
                                        </v-list-item-group>
                                    </v-list>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_deal">
                        <v-col cols="12">
                            <v-card :class="deal_error">
                                <v-card-text>
                                    <v-data-table :headers="headers" :items="dealOrderItems" :loading="loadingDealOrders" :locale="lang"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer
                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowClass3"
                                    >
                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass TableDeal">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.name="{ item, index }">
                                            <v-hover :disabled="userBlock" v-slot="{ hover }" open-delay="100">
                                                <div>
                                                    <v-btn :disabled="userBlock" v-show="hover"
                                                           icon
                                                           x-small
                                                           @click="deleteDealOrder(item)"
                                                    >
                                                        <v-icon class="mr-2">mdi-trash-can-outline mdi-18px</v-icon>
                                                    </v-btn>

                                                    <template v-if="item.nomenclature">
                                                        <RouterLink target="_blank" :to="{name: 'nomenclature.edit', 'params': { uuid: item.nomenclature.uuid }}">
                                                            {{ item.nomenclature.name }}
                                                        </RouterLink>
                                                    </template>
                                                    <template v-else-if="item.good">
                                                        {{ item.good }}
                                                    </template>
                                                    <template v-else>
                                                        {{ item.name }}
                                                    </template>
                                                </div>
                                            </v-hover>
                                        </template>

                                        <template v-slot:item.count="{ item, index }">
                                            <div style="width: 80px" v-if="item.show_count">
                                                <v-text-field
                                                    v-model="item.count"
                                                    type="number"
                                                    min="1"
                                                    max="9999999999"
                                                    maxlength="8"

                                                    append-icon="mdi-check"
                                                    @click:append-outer="inputClose(item, 'show_count')"
                                                    @click:append="inputClose(item, 'show_count')"
                                                    class="text-center justify-center remove-spin"
                                                    :disabled="loading" dense
                                                    @keypress="isNumber($event,10, 0)"
                                                    @blur="inputClose(item, 'show_count')"

                                                >

                                                </v-text-field>
                                            </div>
                                            <div v-if="!item.show_count" @click="inputOpen(item,'show_count')">
                                                {{ item.count ? (item.count > 0 ? item.count : item.count = 1) : '--' }}
                                            </div>
                                        </template>

                                        <template v-slot:item.price="{ item, index }">
                                            <div style="width: 120px">
                                                <v-text-field
                                                    v-if="item.show_price"
                                                    v-model="item.price"
                                                    type="number"
                                                    min="1"
                                                    max="9999999999"
                                                    maxlength="8"

                                                    append-icon="mdi-check"
                                                    @click:append-outer="inputClose(item, 'show_price')"
                                                    @click:append="inputClose(item, 'show_price')"
                                                    class="text-center justify-center remove-spin"
                                                    :disabled="loading" dense
                                                    @keypress="isNumber($event,10, 0)"
                                                    @blur="inputClose(item, 'show_price')"
                                                >

                                                </v-text-field>
                                            </div>
                                            <div v-if="!item.show_price" @click="inputOpen(item,'show_price')">
                                                <v-menu
                                                    offset-y
                                                    nudge-left="15"
                                                    nudge-top="0"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn
                                                            v-bind="attrs"
                                                            v-on="on"
                                                            class="mx-0 px-0 py-0 my-0 "
                                                            style="min-width: auto !important;line-height: unset !important; height: auto !important;"
                                                            plain
                                                        >
                                                            <span class="currency_table color_primary">{{ item.currency }}</span>
                                                        </v-btn>
                                                    </template>
                                                    <v-card elevation="0">
                                                        <v-card-subtitle
                                                            class="cursor-pointer py-0 my-3"
                                                            v-for="(currency, index) in currencyItems"
                                                            :key="currency.id"
                                                            @click="setCurrency(item,currency)">
                                                            <span class="currency_table color_primary">{{ currency.character_code }}</span> {{ item.price }}
                                                        </v-card-subtitle>
                                                    </v-card>
                                                </v-menu>
                                                {{ item.price ? item.price : item.price = 0 }}
                                            </div>
                                        </template>

                                        <template v-slot:item.discount="{ item, index }">
                                            <div style="width: 120px" v-if="item.show_discount">
                                                <v-text-field
                                                    v-model="item.discount"
                                                    type="number"
                                                    min="1"
                                                    max="9999999999"
                                                    maxlength="8"

                                                    append-icon="mdi-check"
                                                    @click:append-outer="inputClose(item, 'show_discount')"
                                                    @click:append="inputClose(item, 'show_discount')"
                                                    class="text-center justify-center remove-spin"
                                                    :disabled="loading" dense
                                                    @keypress="isNumber($event,10, 0)"
                                                    @blur="inputClose(item, 'show_discount')"

                                                >

                                                </v-text-field>
                                            </div>
                                            <div v-if="!item.show_discount" @click="inputOpen(item,'show_discount')">
                                                {{ item.discount ? item.discount : item.discount = 0 }} {{ item.currency }}
                                            </div>
                                        </template>

                                        <template v-slot:item.extra_charge="{ item, index }">
                                            <div style="width: 120px" v-if="item.show_extra_charge">
                                                <v-text-field
                                                    v-model="item.extra_charge"
                                                    type="number"
                                                    min="1"
                                                    max="9999999999"
                                                    maxlength="8"

                                                    append-icon="mdi-check"
                                                    @click:append-outer="inputClose(item, 'show_extra_charge')"
                                                    @click:append="inputClose(item, 'show_extra_charge')"
                                                    class="text-center justify-center remove-spin"
                                                    :disabled="loading" dense
                                                    @keypress="isNumber($event,10, 0)"
                                                    @blur="inputClose(item, 'show_extra_charge')"
                                                >
                                                </v-text-field>
                                            </div>
                                            <div v-if="!item.show_extra_charge" @click="inputOpen(item,'show_extra_charge')">
                                                {{ item.extra_charge ? item.extra_charge : item.extra_charge = 0 }} {{ item.currency }}
                                            </div>
                                        </template>

                                        <template v-slot:item.total="{ item }">
                                            <div v-if="item.total === item.total_usd">
                                                {{ item.total }} {{ item.currency }}
                                            </div>
                                            <div v-if="item.total !== item.total_usd">
                                                {{ item.total }} {{ item.currency }}
                                                <div class="mt-1"><span class="currency_table">{{ item.total_usd }} $</span></div>
                                            </div>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                                <v-card-text>
                                    <ValidationProvider ref="deal_type" rules="" v-slot="{ errors, valid }" class="d-flex w-100">
                                        <v-row>
                                            <v-col>
                                                <v-autocomplete
                                                    v-model="deal_type" :items="dealTypeItems"
                                                    :error-messages="errors" :error="!valid"
                                                    :search-input.sync="dealTypeSearching"
                                                    item-text="name"
                                                    return-object
                                                    :loading="loadingDealTypes"
                                                    :no-data-text="dealTypeSearching ? $t('nothing_found_by',{'search': dealTypeSearching}) : $t('nothing_found_text')"
                                                    :label="$t('deal_type')"
                                                    @click:clear="dealTypeItems=[]"
                                                    @click="clearDealTypes"
                                                    @change="addDealOrder"
                                                    single-line
                                                    :placeholder="$t('deal_type')"
                                                    hide-details

                                                    autocomplete="off"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    :disabled="userBlock || loading"
                                                    :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable
                                                >
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.unit"></span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.unit"></span>
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                                <v-dialog
                                                    v-model="nomenclatureSelectPopupVisibility"
                                                    transition="dialog-bottom-transition"
                                                    max-width="600"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <Btn text small v-bind="attrs" v-on="on">{{ $t('nomenclature.add') }}</Btn>
                                                    </template>
                                                    <template v-slot:default="dialog">
                                                        <v-card>
                                                            <v-card-text>
                                                                <NomenclatureSelection
                                                                    v-model="nomenclature"
                                                                    :label="$t('nomenclature.self')"
                                                                ></NomenclatureSelection>
                                                            </v-card-text>
                                                            <v-card-actions class="justify-end">
                                                                <Btn
                                                                    text
                                                                    @click="nomenclatureSelectPopupVisibility = false"
                                                                >{{ $t('close') }}</Btn>
                                                                <Btn
                                                                    @click="onSelectNomenclatureBtnClick"
                                                                >{{ $t('select') }}</Btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </template>
                                                </v-dialog>

                                                <v-dialog
                                                    v-model="goodSelectPopupVisibility"
                                                    transition="dialog-bottom-transition"
                                                    max-width="600"
                                                >
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <Btn text small v-bind="attrs" v-on="on">{{ $t('good.add_my') }}</Btn>
                                                    </template>
                                                    <template v-slot:default="dialog">
                                                        <v-card>
                                                            <v-card-text>
                                                                <TextInput
                                                                    id="good_name"
                                                                    v-model="good_name"
                                                                    :label="$t('columns.good.name')"
                                                                ></TextInput>
                                                            </v-card-text>
                                                            <v-card-actions class="justify-end">
                                                                <Btn
                                                                    text
                                                                    @click="goodSelectPopupVisibility = false"
                                                                >{{ $t('close') }}</Btn>
                                                                <Btn
                                                                    @click="onGoodAddBtnClick"
                                                                >{{ $t('OK') }}</Btn>
                                                            </v-card-actions>
                                                        </v-card>
                                                    </template>
                                                </v-dialog>
                                            </v-col>
                                        </v-row>
                                    </ValidationProvider>
                                    <v-row class="mt-0">
                                        <v-col cols="12" class="py-0">
                                            <div class="d-flex mb-6">
                                                <div class="pa-2 mr-auto"></div>

                                                <div class="pa-2 text-right sum_total_title">
                                                    <div>{{ $t('total_amount_without_vat') }}</div>
                                                    <div>{{ $t('total_amount_vat') }}</div>
                                                    <div style="font-weight: bold !important;color: #333333 !important;">{{ $t('to_be_paid') }}</div>
                                                    <div>{{ $t('total_extra_charge') }}</div>
                                                </div>

                                                <div class="pa-2 text-right sum_total">
                                                    <div>{{ deal_order_no_nds_total }} $</div>
                                                    <div>{{ deal_order_nds_total }} $</div>
                                                    <div style="font-weight: bold !important;">{{ deal_order_total }} $</div>
                                                    <div>{{ deal_order_extra_charge_total }} $</div>

                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-text  v-if="can(['administrator']) && can(['client_module'])">
                                    <div class="buttons-block">
                                        <Btn @click="activeDeal()">{{!deal_active ? $t('activate_deal_for_client') : $t('close')}}</Btn>
                                        <Btn v-if="business_process.is_buy_process" :to="{name: 'order_to_buy.edit', params: { uuid: 'create' }, query: { deal_uuid: id }}" text>{{ $t('order_to_buy.add') }}</Btn>
                                        <Btn v-if="business_process.is_sale_process" :to="{name: 'buyer_order.edit', params: { uuid: 'create' }, query: { deal_uuid: id }}" text>{{ $t('buyer_order.add') }}</Btn>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_document">
                        <v-col cols="12" >
                            <v-card :class="document_error">
                                <v-card-text>
                                    <v-data-table :headers="headerDocument" :items="dealDocumentItems" :loading="loading" :locale="lang"
                                                  :loading-text="$t('loading_please_wait')"
                                                  hide-default-footer
                                                  :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                  class="elevation-0"
                                                  :item-class="rowDeal"
                                                  item-key="expense_uuid"
                                                  :items-per-page="-1"
                                    >
                                        <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                            <thead>
                                            <tr class="rowHeadClass TableTaskFile">
                                                <th v-for="h in headers" :key="h.value" class="table_header">
                                                    <span>{{ h.text }}</span>
                                                </th>
                                            </tr>
                                            </thead>
                                        </template>

                                        <template v-slot:item.file="{ item, index }">
                                            <v-hover :disabled="userBlock" v-slot="{ hover }" open-delay="100">
                                                <div>
                                                    <v-btn v-show="hover"
                                                           v-if="item.author"
                                                           :disabled="closed"
                                                           icon
                                                           x-small
                                                           @click="deleteDealDocument(item)"
                                                    >
                                                        <v-icon class="mr-2">mdi-trash-can-outline mdi-18px</v-icon>
                                                    </v-btn>
                                                    <ContextMenuFile :file="item.file" @add-document-form ="addDocumentForm"  @open-file="openFileContext"></ContextMenuFile>
                                                </div>
                                            </v-hover>
                                        </template>
                                        <template v-slot:item.document_type.name="{ item, index }">
                                            <div> {{ item.document_type.name }}</div>
                                        </template>
                                        <template v-slot:item.admin.name="{ item, index }">
                                            <div> {{ item.admin.name }}</div>
                                        </template>
                                        <template v-slot:item.created_at="{ item, index }">
                                            <div>{{ $moment(item.created_at).format('DD.MM.YYYY HH:mm') }}</div>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_application">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="px-5 pb-2">
                                    <v-row>
                                        <v-col cols="12">
                                            {{ $t('fill_in_fields_to_form_commercial_offer') }}
                                            <v-btn icon :disabled="loading" @click="translationDealKp()" :title="$t('translate')">
                                                <v-icon>mdi-google-translate</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="loading" @click="downloadDealKp()" :title="$t('download')">
                                                <v-icon>mdi-download-outline</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="loading" @click="showPriceDialog('deal_kp')" :title="$t('edit_price')">
                                                <v-icon>mdi-tooltip-edit</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="loading" @click="showPriceHistoryDialog('deal_kp')" :title="$t('history_of_change')">
                                                <v-icon>mdi-clipboard-text-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card :class="application_error" :disabled="is_moderator ? false : !application_form">
                                <v-card-text class="px-6">
                                    <v-row class="form">
                                        <v-col cols="12">
                                            <ValidationProvider ref="shipper" rules="required|min:1|max:65535" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('shipper')}}</div>
                                                <v-text-field v-model="shipper"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('shipper')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="departure" rules="required|min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('from_city')}}</div>
                                                <v-autocomplete v-model="departure" :items="departureItems"
                                                                :error="!valid" :error-messages="errors"
                                                                :search-input.sync="departureSearching"
                                                                item-text="geocoder_name" item-value="id"
                                                                :loading="loadingDepartures"
                                                                @click="clearDepartures"
                                                                :no-data-text="departureSearching ? $t('nothing_found_by',{'search': departureSearching}) : $t('nothing_found_departures')"
                                                                :label="$t('from_city')" @click:clear="departureItems=[]"
                                                                return-object  autocomplete="off"
                                                                append-outer-icon="mdi-plus"
                                                                @click:append-outer="dialogFormStation=true;add_type_station='departure';ymapInit()"
                                                                outlined
                                                                solo
                                                                flat
                                                                hide-details
                                                                dense
                                                                :disabled="loading"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="destination" rules="required|min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('to_city')}}</div>
                                                <v-autocomplete v-model="destination" :items="destinationItems"
                                                                :error="!valid" :error-messages="errors"
                                                                :search-input.sync="destinationSearching"
                                                                item-text="geocoder_name" item-value="id"
                                                                :loading="loadingDestination"
                                                                @click="clearDestination"
                                                                :no-data-text="destinationSearching ? $t('nothing_found_by',{'search': destinationSearching}) : $t('nothing_found_destination')"
                                                                :label="$t('to_city')" @click:clear="destinationItems=[]"
                                                                return-object  autocomplete="off"
                                                                append-outer-icon="mdi-plus"
                                                                @click:append-outer="dialogFormStation=true;add_type_station='destination'"
                                                                outlined
                                                                solo
                                                                flat
                                                                hide-details
                                                                dense
                                                                :disabled="loading"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="6">
                                            <ValidationProvider ref="transit_station" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('transit_station')}}</div>
                                                <v-text-field v-model="transit_station"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('transit_station')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="6">
                                            <ValidationProvider ref="from_city" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('svh_address')}}</div>
                                                <v-text-field v-model="from_city"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('svh_address')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" v-if="false">
                                            <ValidationProvider ref="to_city" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('to_city')}}</div>
                                                <v-text-field v-model="to_city"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('to_city')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <ValidationProvider ref="terms_delivery" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('terms_delivery')}}</div>
                                                <v-text-field v-model="terms_delivery" type="text"
                                                              :error="!valid"
                                                              :label="$t('terms_delivery_text')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              @input="checkTermsDelivery"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <ValidationProvider ref="delivery_time" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('delivery_time_in_days')}}</div>
                                                <v-text-field v-model="delivery_time"
                                                              :error="!valid"
                                                              :label="$t('delivery_time_in_days')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="12" v-if="show_place_argo_pickup">
                                            <ValidationProvider ref="place_argo_pickup" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('place_argo_pickup')}}</div>
                                                <v-text-field v-model="place_argo_pickup" type="text"
                                                              :error="!valid"
                                                              :label="$t('place_argo_pickup')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12">
                                            <ValidationProvider ref="name_cargo" rules="required|min:1|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('name_cargo')}}</div>
                                                <v-textarea v-model="name_cargo" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('name_cargo')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <ValidationProvider ref="hs_code" rules="min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('hs_code')}}</div>
                                                <v-text-field v-model="hs_code"
                                                              :error="!valid"
                                                              :label="$t('hs_code')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6">
                                            <ValidationProvider ref="stackable_cargo" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('stackable_cargo')}}</div>
                                                <v-select v-model="stackable_cargo" :items="yesNoItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('stackable_cargo')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <ValidationProvider ref="insurance" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('insurance')}}</div>
                                                <v-select v-model="insurance" :items="yesNoItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('insurance')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <ValidationProvider ref="dangerous_cargo" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('dangerous_cargo')}}</div>
                                                <v-select v-model="dangerous_cargo" :items="yesNoItems"
                                                          item-text="name" item-value="id"
                                                          :label="$t('dangerous_cargo')"
                                                          :error="!valid"
                                                          :error-messages="errors"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          hide-details
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <div class="input_label">{{$t('loading_type')}}</div>
                                            <ValidationProvider ref="loading_type_form" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <v-select v-model="loading_type_form"
                                                          :items="loadingTypeItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('loading_type')"
                                                          hide-details
                                                          return-object
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          @change="updateAmount()"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <div class="input_label">{{$t('type_transport')}}</div>
                                            <ValidationProvider ref="type_transport_form" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <v-select v-model="type_transport_form"
                                                          :items="typeTransportItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('type_transport')"
                                                          hide-details
                                                          return-object
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="6" sm="6" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="weight_form" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('weight_kg')}}</div>
                                                <v-text-field v-model="weight_form"
                                                              :error="!valid"
                                                              :label="$t('weight_kg')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="6" sm="6" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="volume_form" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('volume')}}</div>
                                                <v-text-field v-model="volume_form"
                                                              :error="!valid"
                                                              :label="$t('volume')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>




                                        <v-col v-if="dangerous_cargo > 0" cols="12">
                                            <ValidationProvider ref="special_text" rules="required|min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('special_text')}}</div>
                                                <v-textarea v-model="special_text" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('special_label')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4">
                                            <ValidationProvider ref="cost_of_services" rules="max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('cost_of_services')}}</div>
                                                <v-text-field v-model="cost_of_services"
                                                              :error="!valid"
                                                              :label="$t('cost_of_services')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="true"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="price" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('price2')}}</div>
                                                <v-text-field v-model="price"
                                                              :error="!valid"
                                                              :label="$t('price2')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                >
                                                    <template v-slot:prepend-inner>
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon v-bind="attrs" v-on="on">
                                                                    {{ selectedCurrency.character_code }}
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item
                                                                    class="py-0 my-0"
                                                                    style="min-height: 30px"
                                                                    v-for="(currency, index) in currencyItems"
                                                                    :key="index"
                                                                    @click="selectCurrency(currency)"
                                                                >
                                                                    <v-list-item-title>{{ currency.character_code }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </template>

                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <ValidationProvider ref="price_container" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('price_container')}}</div>
                                                <v-text-field v-model="price_container"
                                                              :error="!valid"
                                                              :label="$t('price_container')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                >
                                                    <template v-slot:prepend-inner>
                                                        <v-menu offset-y>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn icon v-bind="attrs" v-on="on">
                                                                    {{ selectedCurrency.character_code }}
                                                                </v-btn>
                                                            </template>
                                                            <v-list>
                                                                <v-list-item
                                                                    class="py-0 my-0"
                                                                    style="min-height: 30px"
                                                                    v-for="(currency, index) in currencyItems"
                                                                    :key="index"
                                                                    @click="selectCurrency(currency)"
                                                                >
                                                                    <v-list-item-title>{{ currency.character_code }}</v-list-item-title>
                                                                </v-list-item>
                                                            </v-list>
                                                        </v-menu>
                                                    </template>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <ValidationProvider ref="containers" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('containers')}}</div>
                                                <v-text-field v-model="containers"
                                                              :error="!valid"
                                                              :label="$t('containers')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <div class="input_label">{{$t('type_container')}}</div>
                                            <ValidationProvider ref="type_container_form" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <v-select v-model="type_container_form"
                                                          :items="typeContainerItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('type_container')"
                                                          hide-details
                                                          return-object
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>





                                        <v-col cols="12" sm="4">
                                            <ValidationProvider ref="additional_expenses_amount" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('additional_expenses_amount')}}</div>
                                                <v-text-field v-model="additional_expenses_amount"
                                                              :error="!valid"
                                                              :label="$t('additional_expenses_amount')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="addExpensesAmount"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" v-if="parseInt(additional_expenses_amount) > 0">
                                            <ValidationProvider ref="additional_expenses_text" rules="required|min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('additional_expenses_text')}}</div>
                                                <v-textarea v-model="additional_expenses_text" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('additional_expenses_text')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12">
                                            <ValidationProvider ref="note" rules="min:1|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('note')}}</div>
                                                <v-textarea v-model="note" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('note')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                                <v-card-text class="px-6 pb-10">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn
                                                class="infinity_button"
                                                color="primary"
                                                block
                                                @click="saveDealKp"
                                                :disabled="invalid || loading || closed"
                                                :loading="loading"

                                            >
                                                {{ $t('save') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row class="py-0 mt-1" v-if="display_order">
                        <v-col cols="12">
                            <v-card>
                                <v-card-text class="px-5 pb-2">
                                    <v-row>
                                        <v-col cols="12">
                                            {{ $t('application_international_cargo') }}
                                            <v-btn icon :disabled="loading" @click="translationApplicationForm()" :title="$t('translate')">
                                                <v-icon>mdi-google-translate</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="loading" @click="downloadApplicationForm()" :title="$t('download')">
                                                <v-icon>mdi-download-outline</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="loading" @click="showPriceDialog('application_form')" :title="$t('edit_price')">
                                                <v-icon>mdi-tooltip-edit</v-icon>
                                            </v-btn>
                                            <v-btn icon :disabled="loading" @click="showPriceHistoryDialog('application_form')" :title="$t('history_of_change')">
                                                <v-icon>mdi-clipboard-text-outline</v-icon>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                            <v-card :class="order_error" :disabled="is_moderator ? false : !order_form" >
                                <v-card-text class="px-6">
                                    <v-row class="form">

                                        <v-col cols="12">
                                            <ValidationProvider ref="consignee" rules="required|min:1|max:65535" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('consignee')}}</div>
                                                <v-text-field v-model="consignee"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('consignee')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <ValidationProvider ref="place_loading" rules="required|min:1|max:65535" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('place_loading')}}</div>
                                                <v-text-field v-model="place_loading"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('place_loading')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" v-if="false">
                                            <ValidationProvider ref="unloading" rules="required|min:1|max:65535" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('unloading')}}</div>
                                                <v-text-field v-model="unloading"
                                                              :error="!valid"
                                                              hide-details
                                                              :label="$t('unloading')"
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <div class="input_label">{{$t('type_export')}}</div>
                                            <ValidationProvider ref="type_export" rules="required" v-slot="{ errors, valid }">
                                                <v-select v-model="type_export" :disabled="loading" :items="type_exportItems"
                                                          autofocus
                                                          :error="!valid" :error-messages="errors"
                                                          item-text="name" item-value="id"
                                                          :label="$t('type_export')"
                                                          return-object
                                                          hide-details
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                           <v-col cols="12" sm="6">
                                            <div class="input_label">{{$t('loading_type')}}</div>
                                            <ValidationProvider ref="loading_type_form" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <v-select v-model="loading_type_form"
                                                          :items="loadingTypeItems"
                                                          :error="!valid"
                                                          item-text="name" item-value="id"
                                                          :label="$t('loading_type')"
                                                          hide-details
                                                          return-object
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          @change="updateAmount()"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="cost_of_product" rules="float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('cost_of_product')}}</div>
                                                <v-text-field v-model="cost_of_product"
                                                              :error="!valid"
                                                              :label="$t('cost_of_product')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="type_packaging" rules="min:1|max:255" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('type_packaging')}}</div>
                                                <v-text-field v-model="type_packaging"
                                                              :error="!valid"
                                                              :label="$t('type_packaging')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">

                                            <ValidationProvider ref="number_seats" rules="required|min:1|max:255" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('number_seats')}}</div>
                                                <v-text-field v-model="number_seats"
                                                              :error="!valid"
                                                              :label="$t('number_seats')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense

                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="weight_form" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('weight_kg')}}</div>
                                                <v-text-field v-model="weight_form"
                                                              :error="!valid"
                                                              :label="$t('weight_kg')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="volume_form" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('volume')}}</div>
                                                <v-text-field v-model="volume_form"
                                                              :error="!valid"
                                                              :label="$t('volume')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="12">
                                            <ValidationProvider ref="order_number" rules="min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('order_number')}}</div>
                                                <v-text-field v-model="order_number"
                                                              :error="!valid"
                                                              :label="$t('order_number')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12">
                                            <ValidationProvider ref="factory_contact_details" rules="min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('factory_contact_details')}}</div>
                                                <v-textarea v-model="factory_contact_details" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('factory_contact_details')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12">
                                            <ValidationProvider ref="special_requirements_factory" rules="min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('special_requirements_factory')}}</div>
                                                <v-textarea v-model="special_requirements_factory" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('special_requirements_factory')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12">
                                            <div class="input_label">{{$t('supplier')}}</div>
                                            <ValidationProvider ref="supplier_form" rules="required"
                                                                v-slot="{ errors, valid }">
                                                <v-select v-model="supplier_form" :items="supplierItems"
                                                          :error="!valid" :error-messages="errors"
                                                          item-text="name" item-value="id"
                                                          return-object
                                                          :label="$t('supplier')"
                                                          hide-details
                                                          @click:clear="supplier=null"
                                                          autocomplete="off"
                                                          append-outer-icon="mdi-plus"
                                                          @click:append-outer="supplierDialogForm=true"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :disabled="loading"
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="6">
                                            <div class="input_label">{{$t('payment_type')}}</div>
                                            <ValidationProvider ref="payment_type_from" rules="required" v-slot="{ errors, valid }">
                                                <v-select v-model="payment_type_from" :disabled="loading" :items="payment_typeItems"
                                                          autofocus
                                                          :error="!valid" :error-messages="errors"
                                                          item-text="name" item-value="id"
                                                          :label="$t('payment_type')"
                                                          return-object
                                                          hide-details
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="6">
                                            <div class="input_label">{{$t('payer')}}</div>
                                            <ValidationProvider ref="payer" rules="required" v-slot="{ errors, valid }">
                                                <v-select v-model="payer" :disabled="loading" :items="payerItems"
                                                          autofocus
                                                          :error="!valid" :error-messages="errors"
                                                          item-text="name" item-value="id"
                                                          :label="$t('payer')"
                                                          return-object
                                                          hide-details
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          style="display: block"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                ></v-select>
                                            </ValidationProvider>
                                        </v-col>



                                        <v-col cols="12" sm="6" v-if="false">
                                            <ValidationProvider ref="cost_taking_cargo" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('cost_taking_cargo')}}</div>
                                                <v-text-field v-model="cost_taking_cargo"
                                                              :error="!valid"
                                                              :label="$t('cost_taking_cargo')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="6" v-if="false">
                                            <ValidationProvider ref="cost_export_clearance" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('cost_export_clearance')}}</div>
                                                <v-text-field v-model="cost_export_clearance"
                                                              :error="!valid"
                                                              :label="$t('cost_export_clearance')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>




                                        <v-col cols="12" sm="4">
                                            <ValidationProvider ref="cost_of_services" rules="max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('cost_transportation')}}</div>
                                                <v-text-field v-model="cost_of_services"
                                                              :error="!valid"
                                                              :label="$t('cost_transportation')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="true"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="price" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('price2')}}</div>
                                                <v-text-field v-model="price"
                                                              :error="!valid"
                                                              :label="$t('price2')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <ValidationProvider ref="price_container" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('price_container')}}</div>
                                                <v-text-field v-model="price_container"
                                                              :error="!valid"
                                                              :label="$t('price_container')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <ValidationProvider ref="containers" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('containers')}}</div>
                                                <v-text-field v-model="containers"
                                                              :error="!valid"
                                                              :label="$t('containers')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" :sm="loading_type_form && loading_type_form.id && loading_type_form.id === 2 ? 12 : 4">
                                            <ValidationProvider ref="additional_expenses_amount" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('additional_expenses_amount')}}</div>
                                                <v-text-field v-model="additional_expenses_amount"
                                                              :error="!valid"
                                                              :label="$t('additional_expenses_amount')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="addExpensesAmount"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" v-if="parseInt(additional_expenses_amount) > 0">
                                            <ValidationProvider ref="additional_expenses_text" rules="required|min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('additional_expenses_text')}}</div>
                                                <v-textarea v-model="additional_expenses_text" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('additional_expenses_text')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12">
                                            <ValidationProvider ref="note" rules="min:1|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('note')}}</div>
                                                <v-textarea v-model="note" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('note')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>



                                    </v-row>
                                </v-card-text>
                                <v-card-text class="px-6 pb-10">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-btn
                                                class="infinity_button"
                                                color="primary"
                                                block
                                                @click="saveApplicationForm"
                                                :disabled="invalid || loading || closed"
                                                :loading="loading"

                                            >
                                                {{ $t('save') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>

                    <v-row class="py-0 mt-1">
                        <v-col cols="12">
                            <Messages socket-name="DealChat" :entity_uuid="id" entity="deal" />
                        </v-col>
                    </v-row>

                    <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                    <v-file-input hide-input prepend-icon=""
                                  v-model="files"
                                  id="file"
                                  multiple
                                  @change="previewFiles"
                    >
                    </v-file-input>

                    <v-dialog
                        v-model="accompanying_documents_dialog"
                        scrollable
                        persistent
                        transition="dialog-bottom-transition"
                        max-width="500px"
                    >
                        <v-card>
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('send_files', {'count': accompanying_documents_preview.length}) }}</span>
                                </div>
                                <div>
                                    <v-icon @click="accompanying_documents_dialog = false;accompanying_documents_preview=[];accompanying_documents=[]">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text style="height: 300px;" class="pa-0">
                                <v-list subheader>
                                    <v-list-item v-for="document in accompanying_documents_preview" :key="document.id">
                                        <v-list-item-icon>
                                            <v-img
                                                max-width="90"
                                                :alt="`${document.name}`"
                                                :src="document.url"
                                                max-height="60"
                                            ></v-img>
                                            <v-icon
                                                x-large
                                                v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                            </v-icon>
                                        </v-list-item-icon>

                                        <v-list-item-content>
                                            <v-list-item-title v-text="document.name"></v-list-item-title>
                                            <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        </v-list-item-content>

                                        <v-list-item-icon class="d-flex align-self-center mt-2">
                                            <v-btn
                                                icon
                                                @click="deleteAccompanyingDocuments(document)"
                                            >
                                                <v-icon>mdi-delete-outline</v-icon>
                                            </v-btn>

                                        </v-list-item-icon>
                                    </v-list-item>
                                </v-list>


                            </v-card-text>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn color="primary" text @click="saveDealFile">
                                    {{ $t('save') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="dialogInvoiceStatus"
                    >
                        <v-card>
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('invoice_editing') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="closeInvoiceStatus()">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text class="pa-0">
                                <v-col class="py-0 pt-5" cols="12" sm="12">
                                    <ValidationProvider ref="invoice_status_edit" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="invoice_status_edit" :disabled="loading"
                                                  :items="invoiceStatusEditItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="name" item-value="id"
                                                  :label="$t('invoice_status')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  hide-details
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="paymentConfirmation()">
                                    {{ $t('edit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="dialogAddTopic"
                    >
                        <v-card>
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('topic_creation') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="dialogAddTopic = false" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text class="pa-0">
                                <v-col class="py-0 pt-5" cols="12" sm="12">
                                    <div class="input_label">{{ $t('topic') }}</div>

                                    <ValidationProvider ref="my_topic" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="my_topic" :disabled="loading"
                                                  :items="myTopicsItems" :error-messages="errors"
                                                  :error="!valid"
                                                  item-text="title" item-value="uuid"
                                                  :label="$t('topic')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  hide-details
                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                        ></v-select>
                                    </ValidationProvider>
                                </v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="addDealToTopic()">
                                    {{ $t('add') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="dialogLinkTask"
                    >
                        <v-card>
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('link_task') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="dialogLinkTask = false" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text class="pa-0">
                                <v-col class="py-0 pt-5" cols="12" sm="12">
                                    <ValidationProvider ref="task" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{ $t('tasks') }}</div>
                                        <v-combobox v-model="task" :items="taskAddItems"
                                                    :error="!valid"
                                                    :search-input.sync="taskSearching" hide-selected
                                                    item-text="title" item-value="id" :loading="loadingTasks"
                                                    :label="$t('tasks')" @click:clear="taskItems=[]"
                                                    @click="clearTasks" @input="clearEmptyTaskItems(task)"
                                                    multiple return-object autocomplete="off"
                                                    hide-details
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search_list">
                                                        <span class="ml-2 hidden-is-empty" v-text="item.title"></span>
                                                    </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <v-chip v-if="item === Object(item)"
                                                        class="input_chip"
                                                        v-bind="attrs" :input-value="selected"
                                                        @click:close="parent.selectItem(item)"
                                                        :color="!item.deleted ? 'green  lighten-5' : 'red  lighten-5'"
                                                        :title="!item.deleted ? $t('active') : $t('no_active')"
                                                        link close>
                                                    <span v-html="item.title"></span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ taskSearching ? $t('nothing_found_by', {'search': taskSearching}) : $t('nothing_found_name') }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </ValidationProvider>

                                </v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="addDealToTask()">
                                    {{ $t('add') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="dialogLinkInvoice"
                    >
                        <v-card>
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('link_invoice') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="dialogLinkInvoice = false" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text class="pa-0">
                                <v-col class="py-0 pt-5" cols="12" sm="12">
                                    <ValidationProvider ref="task" rules="min:1" v-slot="{ errors, valid }">
                                        <div class="input_label">{{ $t('invoice') }}</div>
                                        <v-combobox v-model="invoice" :items="invoiceAddItems"
                                                    :error="!valid"
                                                    :search-input.sync="invoiceSearching" hide-selected
                                                    item-text="title" item-value="id" :loading="loadingTasks"
                                                    :label="$t('invoice')" @click:clear="invoiceAddItems=[]"
                                                    @click="clearInvoices" @input="clearEmptyInvoiceItems(invoice)"
                                                    multiple return-object autocomplete="off"
                                                    hide-details
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    outlined
                                                    solo
                                                    flat
                                                    dense
                                                    color="primary"
                                                    class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                    clearable>
                                            <template v-slot:item="{ attrs, item, parent, selected }">
                                                    <span class="search_list">
                                                        <span v-html="item.amount + ' ' + item.invoice_status"></span>
                                                        <span class="ml-2 hidden-is-empty" v-text="item.number"></span>
                                                    </span>
                                            </template>
                                            <template v-slot:selection="{ attrs, item, parent, selected }">
                                                <v-chip v-if="item === Object(item)"
                                                        class="input_chip"
                                                        v-bind="attrs" :input-value="selected"
                                                        @click:close="parent.selectItem(item)"
                                                        :color="!item.deleted ? 'green  lighten-5' : 'red  lighten-5'"
                                                        link close>
                                                    <span v-html="item.amount + ' ' + item.invoice_status"></span>
                                                </v-chip>
                                            </template>
                                            <template v-slot:no-data>
                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            {{ invoiceSearching ? $t('nothing_found_by', {'search': invoiceSearching}) : $t('nothing_found_name') }}
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                        </v-combobox>
                                    </ValidationProvider>

                                </v-col>
                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="addDealToInvoice">
                                    {{ $t('add') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        v-model="dialogDetailTracking"
                        :width="!$vuetify.breakpoint.xsOnly ? 700 : '100%'"
                        overlay-color="#edebeb"

                        transition="dialog-bottom-transition"

                        :fullscreen="$vuetify.breakpoint.xsOnly"
                        :hide-overlay="$vuetify.breakpoint.xsOnly"
                        :scrollable="$vuetify.breakpoint.xsOnly"

                    >
                        <v-card class="px-5 py-5">
                            <v-toolbar  elevation="0" style="background-color: #FFFFFF">
                                <div class="ma-auto ml-1">
                                    <span>{{ $t('tracking_data') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="close_info()">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-title class="distance_0">
                                <v-row>
                                    <v-col cols="12" class="page-layout__main">
                                        <div class="tabs-nav">
                                            <div class="tabs-nav__inner">
                                                <div
                                                    v-for="tab in data_info"
                                                    :key="tab.type"
                                                    class="tabs-nav__item cursor_pointer"
                                                    :class="{ active: tab_type === tab.type }"
                                                >
                                                    <button  @click="show_info(tab.type)">
                                                        <v-icon class="tabs-nav__item-icon" small>{{ tab.icon }}</v-icon>
                                                        {{ tab.name }}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col cols="12" v-if="tab_type==='data'">
                                        <v-row>
                                            <v-col cols="12" class="mb-1 pb-0"><b>{{ $t('application_number') }}:</b> {{ detail_tracking.number ? detail_tracking.number : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('container_number') }}:</b> {{ detail_tracking.container_number ? detail_tracking.container_number : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('departures') }}:</b> {{ detail_tracking.departure ? detail_tracking.departure : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('destination') }}:</b> {{ detail_tracking.destination ? detail_tracking.destination : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('delivery_status') }}:</b> {{detail_tracking.sub_status_name ? detail_tracking.sub_status_name : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('type_container') }}:</b> {{ detail_tracking.type_container ? detail_tracking.type_container : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('type_transportation') }}:</b> {{ detail_tracking.type_transport ? detail_tracking.type_transport : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('download_type') }}:</b>{{ (detail_tracking.loading_type && detail_tracking.loading_type.name) ? detail_tracking.loading_type.code + ' ' + detail_tracking.loading_type.name : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('application_date') }}:</b> {{ detail_tracking.created_at ? $moment(detail_tracking.created_at).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('platform_number') }}:</b> {{ detail_tracking.platform_number ? detail_tracking.platform_number : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('platform_number_knr') }}:</b> {{ detail_tracking.platform_number_knr ? detail_tracking.platform_number_knr : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('date_operation') }}:</b> {{ detail_tracking.date_operation ? $moment(detail_tracking.date_operation).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('date_of_arrival_at_the_border') }}:</b>{{ detail_tracking.date_of_arrival_at_border ? $moment(detail_tracking.date_of_arrival_at_border).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('arrival_date_at_destination') }}:</b>{{ detail_tracking.arrival_date_at_destination ? $moment(detail_tracking.arrival_date_at_destination).format('YYYY-MM-DD HH:mm') : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-0 py-0"><b>{{ $t('distance_to_destination') }}:</b> {{detail_tracking.distance_to_destination ? detail_tracking.distance_to_destination : $t('no_data') }}</v-col>
                                            <v-col cols="12" class="my-1 py-0"><b>{{ $t('delivered') }}:</b> {{ detail_tracking.paid == true ? $t('yes') : $t('no') }}</v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="12" v-if="tab_type==='tracking'">

                                        <v-row>
                                            <v-col cols="12" md="12" class="mb-1 pb-0">
                                                <div class="roadmap_wrapper">

                                                    <ul class="roadmap">


                                                        <li class="li1_2" v-if="tracking_geo.from">
                                                            <div class="node_2 blue_point_2"></div>
                                                            <p>
                                                                <span class="li_last_bold">{{ tracking_geo.from.name }}</span>
                                                                <br/>
                                                                <span class="grey_date_2">{{ tracking_geo.from.date_operation }}</span>
                                                            </p>
                                                        </li>


                                                        <template v-for="(item, t) in tracking_geo.tracking" >
                                                            <li v-if="tracking_geo.to.name !== item.name" class="my_divider_2 blue_line_2" :key="'geo2_'+t"></li>
                                                            <li v-if="tracking_geo.to.name !== item.name" class="li1_2" :key="'geo21_'+t">
                                                                <div class="node_2 blue_point_2"></div>
                                                                <p class="li_last_center_2">
                                                                    <span class="li_last_bold">{{item.name}}</span>
                                                                    <br/>
                                                                    <span class="grey_date_2">{{ item.date_operation }}</span>
                                                                </p>
                                                            </li>
                                                        </template>

                                                        <li v-if="tracking_geo.to" :class="(tracking_geo.tracking && tracking_geo.tracking.length > 0 ? 'my_divider_2' : 'my_divider_3') + ' ' + (!detail_tracking.arrival_date_at_destination ? 'grey_line_2' : 'blue_line_2')"></li>
                                                        <li v-if="tracking_geo.to" class="li1_2">
                                                            <div class="node_2" :class="!detail_tracking.arrival_date_at_destination ? 'grey_point_2' : 'blue_point_2'"></div>
                                                            <p class="li_last_finish_2">
                                                                <span class="li_last_bold">{{ tracking_geo.to.name }}</span>
                                                                <br/>
                                                                <span class="grey_date_2">{{ tracking_geo.to.date_operation }}</span>
                                                            </p>
                                                        </li>

                                                    </ul>


                                                    <ul v-if="false" class="roadmap">
                                                        <!-- Станция 1 -->
                                                        <li class="li1_2">
                                                            <div class="node_2 blue_point_2"></div>
                                                            <p>
                                                                <span class="li_last_bold">Almaty 1</span>
                                                                <br/>
                                                                <span class="grey_date_2">2024-10-10</span>
                                                            </p>
                                                        </li>

                                                        <!-- Линия между станциями -->
                                                        <li class="my_divider_3 grey_line_2"></li>
                                                        <!-- Станция 4 -->
                                                        <li class="li1_2">
                                                            <div class="node_2 grey_point_2"></div>
                                                            <p class="li_last_finish_2">
                                                                <span class="li_last_bold">Altyn Kol</span>
                                                                <br/>
                                                                <span class="grey_date_2">2024-10-10</span>
                                                            </p>
                                                        </li>
                                                    </ul>
                                                </div>


                                            </v-col>
                                            <v-col cols="12" md="12" style="min-height: 370px" class="rounded-lg">
                                                <div >
                                                    <yandex-map class="py-0 my-0 py-0" v-if="coords && showMap" ref="map"
                                                                :coords="coords"
                                                                :controls="[]"
                                                                :show-all-markers="showAllMarkers"
                                                                :zoom="zoom"
                                                                @map-was-initialized="initHandler"
                                                                style="background-color: #ffffff">
                                                    </yandex-map>
                                                </div>
                                            </v-col>
                                        </v-row>

                                    </v-col>
                                    <v-col cols="12" v-if="tab_type==='event'" style="min-height: 390px">
                                        <v-row>
                                            <v-col cols="12" class="mb-1 pb-0">

                                                <template v-for="(item, t) in trackingLogItems">
                                                    <v-row class="py-2">
                                                        <v-col cols="9" sm="9" color="primary" class="my-0 py-0" >
                                                            <v-icon small color="primary" class="mr-2" v-if="!$vuetify.breakpoint.xsOnly">icon-check-circle-1</v-icon>
                                                            {{item.message}}
                                                        </v-col>
                                                        <v-col cols="3" sm="3" class="grey_date_2 text-right my-0 py-0">
                                                            {{item.created_at}}
                                                        </v-col>
                                                    </v-row>
                                                </template>

                                            </v-col>

                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="700"
                        v-model="dialogAddParticipants"
                        v-if="dialogAddParticipants"
                    >
                        <ValidationObserver ref="observerAddParticipants" v-slot="{ invalid, validated, passes, validate }">
                            <v-card>
                                <v-toolbar class="dialog_toolbar" elevation="0">
                                    <div class="ma-auto ml-0">
                                        <span>{{ $t('edit_participants') }}</span>
                                    </div>
                                    <div>
                                        <v-icon @click="dialogAddParticipants = false" :title="$t('close')">mdi-close</v-icon>
                                    </div>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <v-col class="py-0 pt-5" cols="12">
                                            <ValidationProvider ref="responsibleAdd" rules="required|min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="responsibleAdd"
                                                    id="responsible"
                                                    :label="$t('responsible')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="auditorsAdd" rules="min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="auditorsAdd"
                                                    id="auditor"
                                                    :label="$t('observers')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>

                                        </v-col>
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="executorsAdd" rules="min:1" v-slot="{ errors, valid }">
                                                <UserSelection
                                                    multiple
                                                    v-model="executorsAdd"
                                                    id="executor"
                                                    :label="$t('co-executors')"
                                                    :error="!valid"
                                                    :valid="valid"
                                                    :error-messages="errors"
                                                    :disabled="loading"
                                                    clearable
                                                />
                                            </ValidationProvider>

                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="uved" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('uved.self') }}</div>
                                                <v-autocomplete v-model="uved" :items="uvedItems"
                                                                :error="!valid"
                                                                :search-input.sync="uvedSearching"
                                                                item-text="name" item-value="id"
                                                                return-object
                                                                :loading="loadingUveds"
                                                                :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                                :label="$t('uved.self')"
                                                                @click:clear="uvedSearching=null;uved=null;uvedItems=[];"
                                                                @click="clearUveds"
                                                                @click:append="redirectToUved(uved)"
                                                                @change="set_uved = uved"
                                                                autocomplete="off"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingUveds"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                        <span class="search-list">
                                            <span v-html="item.name"></span>
                                            <span class="ml-2 hidden-is-empty"
                                                  v-text="item.bin_iin"></span>
                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                            <span class="search-list">
                                                <span v-html="item.name"></span>
                                                <span class="ml-2 hidden-is-empty"
                                                      v-text="item.bin_iin"></span>
                                            </span>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>

                                </v-card-text>
                                <v-card-actions class="pt-5">
                                    <v-spacer></v-spacer>
                                    <v-btn text color="primary" :disabled="invalid || loading" :loading="loading" @click="editParticipants()">
                                        {{ $t('edit') }}
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </ValidationObserver>
                    </v-dialog>

                    <v-dialog
                        v-model="dialog_image_view"
                        scrollable
                        fullscreen
                        v-if="!!image_view.comment"
                    >
                        <v-card>
                            <v-card-title class="px-2 mx-0">
                                <span class="pl-5 text-truncate">{{ image_view.comment.file_name }}</span>
                                <v-spacer></v-spacer>
                                <v-tooltip>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on"
                                               @click="downloadFileMessage(image_view.comment.value, image_view.comment.file_name)"
                                               icon>
                                            <v-icon>mdi-arrow-collapse-up mdi-flip-v</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('download') }}</span>
                                </v-tooltip>
                                <v-tooltip>
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on"
                                               @click="ImageViewClose()"
                                               icon>
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('close') }}</span>
                                </v-tooltip>
                            </v-card-title>

                            <v-card-text style="height: 300px;">

                                <v-container fluid>
                                    <v-row dense>
                                        <v-col>
                                            <v-card>
                                                <v-img
                                                    :alt="`${image_view.comment.file_name}`"
                                                    :src="image_view.comment.value"


                                                >

                                                </v-img>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>


                            </v-card-text>


                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="700"
                        v-model="dialogAddParticipant"
                        v-if="dialogAddParticipant"
                    >
                        <v-card>
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('edit_participants') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="dialogAddParticipant = false" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text>
                                <v-row>
                                    <v-col class="py-0 pt-5" cols="12">
                                        <ValidationProvider ref="responsible" rules="required|min:1" v-slot="{ errors, valid }">
                                            <div class="input_label">{{ $t('responsible') }}</div>
                                            <v-combobox v-model="responsible" :items="responsibleItems"
                                                        :error="!valid"
                                                        :search-input.sync="responsibleSearching" hide-selected
                                                        item-text="name" item-value="id" :loading="loadingResponsibles"
                                                        :label="$t('responsible')" @click:clear="responsibleItems=[]"
                                                        @click="clearResponsibles" @input="clearEmptyResponsibleItems(responsible)"
                                                        multiple return-object autocomplete="off"
                                                        hide-details
                                                        :error-messages="errors"
                                                        :disabled="loading"
                                                        outlined
                                                        solo
                                                        flat
                                                        dense
                                                        color="primary"
                                                        class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                        clearable>
                                                <template v-slot:item="{ attrs, item, parent, selected }">
                                                            <span class="search_list">
                                                                <span v-html="item.name"></span>
                                                                <span class="ml-2 hidden-is-empty" v-text="item.phone"></span>
                                                            </span>
                                                </template>
                                                <template v-slot:selection="{ attrs, item, parent, selected }">
                                                    <v-chip v-if="item === Object(item)"
                                                            class="input_chip"
                                                            v-bind="attrs" :input-value="selected"
                                                            @click:close="parent.selectItem(item)"
                                                            :color="item.active ? 'green lighten-5' : 'red  lighten-5'"
                                                            :title="item.active ? $t('active') : $t('no_active')"
                                                            link close>
                                                        <span v-html="item.name"></span>
                                                    </v-chip>
                                                </template>
                                                <template v-slot:no-data>
                                                    <v-list-item>
                                                        <v-list-item-content>
                                                            <v-list-item-title>
                                                                {{ responsibleSearching ? $t('nothing_found_by', {'search': responsibleSearching}) : $t('nothing_found_name') }}
                                                            </v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </template>
                                            </v-combobox>
                                        </ValidationProvider>
                                    </v-col>

                                </v-row>

                            </v-card-text>
                            <v-card-actions class="pt-5">
                                <v-spacer></v-spacer>
                                <v-btn text color="primary" @click="editParticipant()">
                                    {{ $t('edit') }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        v-model="document_dialog"
                        scrollable
                        persistent
                        transition="dialog-bottom-transition"
                        max-width="500px"


                    >
                        <v-card>
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                                <v-toolbar class="dialog_toolbar" elevation="0">
                                    <div class="ma-auto ml-0">
                                        <span>{{ $t('send_files', {'count': documents_preview.length}) }}</span>
                                    </div>
                                    <div>
                                        <v-icon @click="document_dialog = false;documents_preview=[];add_documents=[];document_type=null;">mdi-close</v-icon>
                                    </div>
                                </v-toolbar>
                                <v-card-text style="height: 300px;" class="pa-0">
                                    <v-list subheader>
                                        <v-list-item v-for="document in documents_preview" :key="document.id">
                                            <v-list-item-icon>
                                                <v-img
                                                    max-width="90"
                                                    :alt="`${document.name}`"
                                                    :src="document.url"
                                                    max-height="60"
                                                ></v-img>
                                                <v-icon
                                                    x-large
                                                    v-if="!mimeTypeImage(document.type)">mdi-file-document-outline mdi-48px
                                                </v-icon>
                                            </v-list-item-icon>

                                            <v-list-item-content>
                                                <v-list-item-title v-text="document.name"></v-list-item-title>
                                                <v-list-item-subtitle v-text="(document.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                            </v-list-item-content>

                                            <v-list-item-icon class="d-flex align-self-center mt-2">
                                                <v-btn
                                                    icon
                                                    @click="deleteDocument(document)"
                                                >
                                                    <v-icon>mdi-delete-outline</v-icon>
                                                </v-btn>

                                            </v-list-item-icon>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-row>
                                                <v-col cols="12">
                                                    <ValidationProvider ref="document_type" rules="required|min:1"
                                                                        v-slot="{ errors, valid }">
                                                        <v-select v-model="document_type" :disabled="loading" :items="documentTypeItems"
                                                                  :error="!valid" :error-messages="errors"
                                                                  item-text="name" item-value="id"
                                                                  return-object
                                                                  :label="$t('document_type')"
                                                                  hide-details
                                                                  @click:clear="document_type=null"
                                                                  autocomplete="off"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable
                                                        ></v-select>


                                                    </ValidationProvider>
                                                </v-col>
                                            </v-row>
                                        </v-list-item>
                                    </v-list>

                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" text @click="saveDocumentFile"
                                           :disabled="invalid || loading" :loading="loading">
                                        {{ $t('save') }}
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="700"
                        v-model="dialogAccompanyStep"
                        v-if="dialogAccompanyStep"
                    >
                        <v-card>
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                                <v-toolbar class="dialog_toolbar" elevation="0">
                                    <div class="ma-auto ml-0">
                                        <span>{{ $t('accompany_comment') }}</span>
                                    </div>
                                    <div>
                                        <v-icon @click="dialogAccompanyStep = false" :title="$t('close')">mdi-close</v-icon>
                                    </div>
                                </v-toolbar>
                                <v-card-text>
                                    <v-row>
                                        <v-col class="py-0 pt-5" cols="12">
                                            <ValidationProvider ref="step_text" rules="required|min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('accompany_comment')}}</div>
                                                <v-textarea v-model="step_text" type="text"
                                                            :disabled="loading" :label="$t('accompany_comment')" rows="5"
                                                            :error-messages="errors"
                                                            :error="!valid"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>



                                    </v-row>

                                </v-card-text>
                                <v-card-actions class="pt-5">
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary"  class="infinity_button" @click="editStep(step_uuid)" :disabled="invalid || loading" :loading="loading">
                                        {{ button_text_step }}
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-dialog>



                    <v-dialog
                        persistent
                        max-width="960px"
                        transition="dialog-bottom-transition"
                        v-model="supplierDialogForm"
                    >

                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-card flat>
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="d-flex mt-5">
                                                <v-col cols="10" class="ma-auto">
                                                    <span class="title_menu">{{ heading }}</span>
                                                </v-col>
                                                <v-col cols="2" class="d-flex justify-end">
                                                    <v-icon @click="supplierDialogForm = false" :title="$t('close')" color="primary">mdi-close</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-5">
                                        <v-col class="py-0" cols="12">
                                            <ValidationProvider ref="supplier_text" rules="required|min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('supplier_name') }}</div>
                                                <v-text-field v-model="supplier_text" type="text"
                                                              :error="!valid" :error-messages="errors"
                                                              :disabled="loading"
                                                              :label="$t('supplier_name')"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="px-4 py-7">
                                    <v-btn @click="saveSupplier" :disabled="invalid || loading" :loading="loading"
                                           :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                        {{ $t('save') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        class="ma-1 button_cancel"
                                        plain
                                        @click="supplierDialogForm = false"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                       :active="loading" class="mx-2"></v-progress-linear>

                                </v-card-actions>
                            </v-card>
                        </ValidationObserver>

                    </v-dialog>

                    <v-dialog
                        persistent
                        max-width="1200px"
                        transition="dialog-bottom-transition"
                        v-model="dialogFormStation"
                    >
                        <v-form @submit.prevent="saveStation">
                            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                                <v-card flat >
                                    <v-card-text class="py-0">
                                        <v-row>
                                            <v-col cols="12">
                                                <v-row class="d-flex mt-5">
                                                    <v-col cols="10" class="ma-auto">
                                                        <span class="title_menu">{{ heading }}</span>
                                                    </v-col>
                                                    <v-col cols="2" class="d-flex justify-end">
                                                        <v-icon @click="closeDialogStation" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                                    </v-col>
                                                </v-row>
                                            </v-col>
                                        </v-row>
                                        <v-row class="mt-2">
                                            <v-col class="py-0" cols="12" v-show="false">
                                                <v-tabs v-model="tab" background-color="transparent"
                                                        :right="$vuetify.breakpoint.mdAndUp"
                                                        :grow="$vuetify.breakpoint.smAndDown"
                                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                                    </v-tab>
                                                </v-tabs>
                                            </v-col>
                                        </v-row>

                                        <v-row class="mt-5">
                                            <v-col class="py-0" cols="12" sm="4">
                                                <ValidationProvider ref="country" rules="required"
                                                                    v-slot="{ errors, valid }">
                                                    <v-select v-model="country" :error="!valid" :error-messages="errors"
                                                              :disabled="loading" :items="countryItems"
                                                              item-text="name" item-value="id"
                                                              :label="$t('country')"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                    </v-select>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col class="py-0" cols="12" sm="2">
                                                <ValidationProvider ref="code" rules="min:1|max:30"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="code" type="text" :error="!valid" :error-messages="errors"
                                                                  :disabled="loading"
                                                                  :label="$t('code')"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable>>
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                            <v-col class="py-0" cols="12" sm="6">
                                                <ValidationProvider ref="station_name" rules="required|min:1|max:255"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="station_name" type="text" :error="!valid" :error-messages="errors"
                                                                  :disabled="loading"
                                                                  :label="$t('station_name')"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable>>
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </v-col>
                                        </v-row>
                                        <v-row style="background-color: #F5F5F5" class="pa-2">
                                            <v-col cols="12"> {{ $t('station_center') }}</v-col>
                                            <v-col cols="12">
                                                <yandex-map v-if="coords && showMap" ref="map"
                                                            :coords="coords"
                                                            :show-all-markers="showAllMarkers"
                                                            :zoom="zoom"
                                                            @map-was-initialized="initHandler2"
                                                            style="height: 300px"
                                                            @click="onClickMap">
                                                    <ymap-marker ref="mapMarker"
                                                                 marker-id="123"
                                                                 marker-type="placemark"
                                                                 :coords="coords"
                                                                 @dragend="ondDagendMap"/>
                                                </yandex-map>
                                            </v-col>
                                            <v-col cols="12" v-if="(coords[0] === 51 && coords[1] === 71)"
                                                   class="mt-2" style="color: red">
                                                {{ $t('coordinates_of_point_not_set') }}
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                    <v-card-actions class="px-4 py-7">
                                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                               :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                            {{ $t('save') }}
                                        </v-btn>
                                        <v-btn
                                            :disabled="loading"
                                            class="ma-1 button_cancel"
                                            plain
                                            @click="closeDialogStation()"
                                        >
                                            {{$t('cancel')}}
                                        </v-btn>
                                        <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                           :active="loading" class="mx-2"></v-progress-linear>

                                    </v-card-actions>

                                </v-card>
                            </ValidationObserver>
                        </v-form>
                    </v-dialog>

                    <v-dialog
                        persistent
                        max-width="960px"
                        transition="dialog-bottom-transition"
                        v-model="dialogEditPrice"
                    >

                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-card flat>
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="d-flex mt-5">
                                                <v-col cols="10" class="ma-auto">
                                                    <span class="title_menu">{{ $t('edit_price') }}</span>
                                                </v-col>
                                                <v-col cols="2" class="d-flex justify-end">
                                                    <v-icon @click="closePriceDialog()" :title="$t('close')" color="primary">mdi-close</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-5">
                                        <v-col cols="12" sm="4">
                                            <ValidationProvider ref="cost_of_services" rules="max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('cost_of_services')}}</div>
                                                <v-text-field v-model="cost_of_services"
                                                              :error="!valid"
                                                              :label="$t('cost_of_services')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="true"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 1">
                                            <ValidationProvider ref="price" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('price2')}}</div>
                                                <v-text-field v-model="price"
                                                              :error="!valid"
                                                              :label="$t('price2')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <ValidationProvider ref="price_container" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('price_container')}}</div>
                                                <v-text-field v-model="price_container"
                                                              :error="!valid"
                                                              :label="$t('price_container')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col cols="12" sm="4" v-if="loading_type_form && loading_type_form.id && loading_type_form.id === 2">
                                            <ValidationProvider ref="containers" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('containers')}}</div>
                                                <v-text-field v-model="containers"
                                                              :error="!valid"
                                                              :label="$t('containers')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="costServiceUpdate()"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>


                                        <v-col cols="12" :sm="loading_type_form && loading_type_form.id && loading_type_form.id === 1 ? 4 : 12">
                                            <ValidationProvider ref="additional_expenses_amount" rules="required|float_text|max:11" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('additional_expenses_amount')}}</div>
                                                <v-text-field v-model="additional_expenses_amount"
                                                              :error="!valid"
                                                              :label="$t('additional_expenses_amount')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable
                                                              @input="addExpensesAmount"
                                                ></v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col cols="12" v-if="parseInt(additional_expenses_amount) > 0">
                                            <ValidationProvider ref="additional_expenses_text" rules="required|min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('additional_expenses_text')}}</div>
                                                <v-textarea v-model="additional_expenses_text" type="text"
                                                            :error="!valid" :error-messages="errors"
                                                            :disabled="loading" :label="$t('additional_expenses_text')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>

                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="px-4 py-7">
                                    <v-btn @click="saveEditPrice()" :disabled="invalid || loading" :loading="loading"
                                           :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                        {{ $t('save') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        class="ma-1 button_cancel"
                                        plain
                                        @click="closePriceDialog()"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                       :active="loading" class="mx-2"></v-progress-linear>

                                </v-card-actions>
                            </v-card>
                        </ValidationObserver>

                    </v-dialog>

                    <v-dialog
                        persistent
                        max-width="1200"
                        transition="dialog-bottom-transition"
                        v-model="dialogHistoryPrice"
                    >

                        <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                            <v-card flat>
                                <v-card-text class="py-0">
                                    <v-row>
                                        <v-col cols="12">
                                            <v-row class="d-flex mt-5">
                                                <v-col cols="10" class="ma-auto">
                                                    <span class="title_menu">{{ $t('history_of_change') }}</span>
                                                </v-col>
                                                <v-col cols="2" class="d-flex justify-end">
                                                    <v-icon @click="dialogHistoryPrice=false" :title="$t('close')" color="primary">mdi-close</v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                    <v-row class="mt-5">

                                        <v-col cols="12">

                                                <v-data-table :headers="headerHistoryPrice" :items="historyPriceItems"
                                                              :loading="loadingTasks" :locale="lang"
                                                              :loading-text="$t('loading_please_wait')"
                                                              hide-default-footer
                                                              :hide-default-header="(!$vuetify.breakpoint.xsOnly)"
                                                              class="elevation-0"
                                                             >

                                                    <template v-slot:header="{ props: { headers } }" v-if="(!$vuetify.breakpoint.xsOnly)">
                                                        <thead>
                                                        <tr >
                                                            <th v-for="h in headers" :key="h.value" class="table_header">
                                                                <span>{{ h.text }}</span>
                                                            </th>
                                                        </tr>
                                                        </thead>
                                                    </template>
                                                    <template v-slot:item.cost_of_services="{ item }">
                                                        <div style="white-space: nowrap !important; ">
                                                            {{ item.cost_of_services }}
                                                            <v-icon dark small :color="getContainerColor(item.cost_of_services, item.become_cost_of_services)">{{getContainerIcon(item.cost_of_services, item.become_cost_of_services)}}</v-icon>
                                                            {{ item.become_cost_of_services}}
                                                        </div>
                                                    </template>
                                                    <template v-slot:item.containers="{ item }">
                                                        <div style="white-space: nowrap !important; ">
                                                            {{ item.containers }}
                                                            <v-icon dark small :color="getContainerColor(item.containers, item.become_containers)">{{getContainerIcon(item.containers, item.become_containers)}}</v-icon>
                                                            {{ item.become_containers}}
                                                        </div>
                                                    </template>
                                                    <template v-slot:item.price_container="{ item }">
                                                        <div style="white-space: nowrap !important; ">
                                                            {{ item.price_container }}
                                                            <v-icon dark small :color="getContainerColor(item.price_container, item.become_price_container)">{{getContainerIcon(item.price_container, item.become_price_container)}}</v-icon>
                                                            {{ item.become_price_container}}
                                                        </div>
                                                    </template>
                                                    <template v-slot:item.additional_expenses_amount="{ item }">
                                                        <div style="white-space: nowrap !important; ">
                                                            {{ item.additional_expenses_amount }}
                                                            <v-icon dark small :color="getContainerColor(item.additional_expenses_amount, item.become_additional_expenses_amount)">{{getContainerIcon(item.additional_expenses_amount, item.become_additional_expenses_amount)}}</v-icon>
                                                            {{ item.become_additional_expenses_amount}}
                                                        </div>
                                                    </template>

                                                    <template v-slot:item.created_at="{ item }">
                                                        <div>{{ $moment(item.created_at).format('DD.MM.YYYY') }}</div>
                                                    </template>

                                                </v-data-table>

                                        </v-col>

                                    </v-row>
                                </v-card-text>
                                <v-card-actions class="px-4 py-7">
                                    <v-btn @click="dialogHistoryPrice=false"  :loading="loading"
                                           :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                        {{ $t('OK') }}
                                    </v-btn>
                                    <v-btn
                                        :disabled="loading"
                                        class="ma-1 button_cancel"
                                        plain
                                        @click="dialogHistoryPrice=false"
                                    >
                                        {{ $t('cancel') }}
                                    </v-btn>
                                    <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                                    <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                                       :active="loading" class="mx-2"></v-progress-linear>

                                </v-card-actions>
                            </v-card>
                        </ValidationObserver>

                    </v-dialog>

                    <v-dialog
                        transition="dialog-bottom-transition"
                        max-width="600"
                        v-model="displayCancelStatusExecutor"
                        class="dialog_body"
                    >
                        <v-card>
                            <ValidationObserver ref="observer2" v-slot="{ invalid, validated, passes, validate }">
                                <v-toolbar class="dialog_toolbar" elevation="0">
                                    <div class="ma-auto ml-0">
                                        <span>{{ $t('reason') }}</span>
                                    </div>
                                    <div>
                                        <v-icon @click="closeDialogStatusExecutor()" :title="$t('close')">mdi-close</v-icon>
                                    </div>
                                </v-toolbar>
                                <v-card-text>
                                    <v-col cols="12">
                                        <ValidationProvider ref="unlock_text" rules="required|min:10|max:62000"
                                                            v-slot="{ errors, valid }">
                                            <v-textarea
                                                :label="$t('write_reason')"
                                                :error="!valid"
                                                v-model="status_executor_text"
                                                :error-messages="errors"
                                                :disabled="loading"
                                                rows="8"
                                                color="primary"
                                                auto-grow
                                                clearable outlined

                                                full-width
                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea"
                                            >
                                            </v-textarea>
                                        </ValidationProvider>
                                    </v-col>
                                </v-card-text>
                                <v-card-actions class="justify-end pt-0">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        :disabled="invalid || loading" :loading="loading"
                                        text

                                        @click="cancelStatusExecutor()"
                                        color="primary" class="infinity_button"
                                    >{{  $t(status_executor) }}
                                    </v-btn>

                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>
                    </v-dialog>

                    <v-dialog
                        v-model="dialogTracking"
                        class="dialog_body"
                        :width="!$vuetify.breakpoint.xsOnly ? 600 : '100%'"
                        overlay-color="#edebeb"
                        transition="dialog-bottom-transition"

                        :fullscreen="$vuetify.breakpoint.xsOnly"
                        :hide-overlay="$vuetify.breakpoint.xsOnly"
                        :scrollable="$vuetify.breakpoint.xsOnly"
                    >

                        <v-card>
                            <ValidationObserver ref="observer3" v-slot="{ invalid, validated, passes, validate }">
                            <v-toolbar class="dialog_toolbar" elevation="0">
                                <div class="ma-auto ml-0">
                                    <span>{{ $t('tracking') }}</span>
                                </div>
                                <div>
                                    <v-icon @click="closeDialogTracking()" :title="$t('close')">mdi-close</v-icon>
                                </div>
                            </v-toolbar>
                            <v-card-text class="py-5">
                                <v-row >
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="departure" rules="min:1" v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('station')}}</div>
                                            <v-autocomplete v-model="station" :items="stationItems"
                                                            :error="!valid" :error-messages="errors"
                                                            :search-input.sync="stationSearching"
                                                            item-text="geocoder_name" item-value="id"
                                                            :loading="loadingDepartures"
                                                            @click="clearStation"
                                                            :no-data-text="stationSearching ? $t('nothing_found_by',{'search': stationSearching}) : $t('nothing_found_departures')"
                                                            :label="$t('station')" @click:clear="stationItems=[]"
                                                            append-outer-icon="mdi-plus"
                                                            @click:append-outer="openAddStation()"
                                                            return-object  autocomplete="off"
                                                            outlined
                                                            solo
                                                            flat
                                                            hide-details
                                                            dense
                                                            :disabled="loadingStation"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable>
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="distance" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('distance')}}</div>
                                            <v-text-field v-model="distance" type="text"
                                                          :error="!valid"
                                                          :label="$t('distance')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="type_container" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('type_container')}}</div>
                                            <v-autocomplete v-model="type_container" :items="typeContainerItems"
                                                            item-text="name" item-value="id"
                                                            :label="$t('type_container')"
                                                            :error="!valid"
                                                            hide-details
                                                            :error-messages="errors"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loadingTypeContainer"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="container_number" rules="min:2|max:255"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('container_number')}}</div>
                                            <v-text-field v-model="container_number" type="text"
                                                          :error="!valid"
                                                          :label="$t('container_number')"
                                                          hide-details
                                                          :error-messages="errors"
                                                          :disabled="loading"
                                                          outlined
                                                          solo
                                                          flat
                                                          dense
                                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                          color="primary"
                                                          class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                          clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="status" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('status')}}</div>
                                            <v-autocomplete v-model="status" :items="statusItems"
                                                            item-text="name" item-value="id"
                                                            :label="$t('status')"
                                                            :error="!valid"
                                                            hide-details
                                                            :error-messages="errors"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable
                                                            @change="getSubStatusList()"
                                                            @click:clear="status=null;sub_status=null;subStatusItems=[];"
                                            ></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <ValidationProvider ref="sub_status" rules="min:1"
                                                            v-slot="{ errors, valid }">
                                            <div class="input_label">{{$t('sub_status')}}</div>
                                            <v-autocomplete v-model="sub_status" :items="subStatusItems"
                                                            item-text="name" item-value="id"
                                                            :label="$t('sub_status')"
                                                            :error="!valid"
                                                            hide-details
                                                            :error-messages="errors"
                                                            outlined
                                                            solo
                                                            flat
                                                            dense
                                                            :disabled="loading"
                                                            :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                            color="primary"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                            clearable
                                            ></v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="menuDateSending" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="date_sending" name="date_sending" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <div class="input_label">{{$t('date_sending')}}</div>

                                                    <v-text-field v-model="date_sending" :error-messages="errors"
                                                                  :label="$t('date_sending')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_sending = null"
                                                                  outlined
                                                                  hide-details
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_sending"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="menuDateSending = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuPr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <div class="input_label">{{$t('arrival_date_at_destination')}}</div>
                                                <ValidationProvider ref="date_pr" name="date__pr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="date_pr" :error-messages="errors"
                                                                  :label="$t('arrival_date_at_destination')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_pr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  hide-details
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_pr"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuPr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuGr" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <ValidationProvider ref="date_gr" name="date_gr" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <div class="input_label">{{$t('date_of_arrival_at_the_border')}}</div>

                                                    <v-text-field v-model="date_gr" :error-messages="errors"
                                                                  :label="$t('date_of_arrival_at_the_border')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_gr = null"
                                                                  outlined
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  hide-details
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_gr"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuGr = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col class="py-0" cols="12" sm="6">
                                        <v-menu v-model="dateMenuBorder" :close-on-content-click="false" :nudge-right="40"
                                                transition="scale-transition" offset-y min-width="290px">
                                            <template v-slot:activator="{ on }">
                                                <div class="input_label">{{$t('date_of_exit_from_border')}}</div>

                                                <ValidationProvider ref="date_border" name="date_from" rules="date_format"
                                                                    v-slot="{ errors, valid }">
                                                    <v-text-field v-model="date_border" :error-messages="errors"
                                                                  :label="$t('date_of_exit_from_border')"
                                                                  readonly v-on="on"
                                                                  @click:clear="date_border = null"
                                                                  outlined
                                                                  hide-details
                                                                  solo
                                                                  flat
                                                                  dense
                                                                  :disabled="loading"
                                                                  :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                  color="primary"
                                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                  clearable></v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <v-date-picker ref="picker" v-model="date_border"
                                                           :min="$moment().format('YYYY-MM-DD')"
                                                           :locale="lang" @change="dateMenuBorder = false"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                                <v-row v-if="$vuetify.breakpoint.xsOnly">
                                    <v-col cols="12" class="pt-8 text-right">
                                        <v-btn
                                            block
                                            color="primary"
                                            @click="saveTracking()"
                                        >{{ $t('save') }}</v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                                <v-spacer></v-spacer>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="saveTracking()"
                                >{{ $t('save') }}</v-btn>
                            </v-card-actions>
                            </ValidationObserver>
                        </v-card>

                    </v-dialog>

                    <v-dialog
                        v-model="dialogTrackingContainer"
                        :width="!$vuetify.breakpoint.xsOnly ? 600 : '100%'"
                        overlay-color="#edebeb"

                        transition="dialog-bottom-transition"

                        :fullscreen="$vuetify.breakpoint.xsOnly"
                        :hide-overlay="$vuetify.breakpoint.xsOnly"
                        :scrollable="$vuetify.breakpoint.xsOnly"

                    >
                        <v-card>
                            <ValidationObserver ref="observerDialogTracking"
                                                v-slot="{ invalid, validated, passes, validate }">

                                <v-toolbar class="dialog_toolbar" elevation="0">
                                    <div class="ma-auto ml-0">
                                        <span>{{ $t('tracking') }}</span>
                                    </div>
                                    <div>
                                        <v-icon @click="closeDialogTrackingContainer()" :title="$t('close')">mdi-close</v-icon>
                                    </div>
                                </v-toolbar>
                                <v-card-title class="distance_0"></v-card-title>
                                <v-card-text class="py-5">
                                    <v-row>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="type_container" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('type_container') }}</div>
                                                <v-autocomplete v-model="type_container" :items="typeContainerItems"
                                                                item-text="name" item-value="id"
                                                                :label="$t('type_container')"
                                                                :error="!valid"
                                                                hide-details
                                                                :error-messages="errors"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :disabled="loadingTypeContainer"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="container_number" rules="min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{ $t('container_number') }}</div>
                                                <v-text-field v-model="container_number" type="text"
                                                              :error="!valid"
                                                              :label="$t('container_number')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="$vuetify.breakpoint.xsOnly">
                                        <v-col cols="12" class="pt-8 text-right">
                                            <v-btn
                                                block
                                                color="primary"
                                                @click="saveTrackingContainer()"
                                            >{{ $t('save') }}
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-text>
                                <v-card-actions v-if="!$vuetify.breakpoint.xsOnly">
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        text
                                        color="primary"
                                        @click="saveTrackingContainer()"
                                    >{{ $t('save') }}
                                    </v-btn>
                                </v-card-actions>
                            </ValidationObserver>
                        </v-card>

                    </v-dialog>

                    <OpenFile :dialogOpenFile.sync="dialogOpenFile" :file="fileOpen"/>

                    <DocumentFormDialog
                        :dialogForm="dialogForm"
                        :document_flow_urls_form="document_flow_urls_form"
                        :closeForm = "true"
                        @dialog-form-close = "dialogFormClose"
                    ></DocumentFormDialog>

                    <v-overlay :value="dragover" z-index="10" color="#244ba8">
                        <v-row>
                            <v-col style="height: 400px;width:500px;"
                                   class="grey darken-1"
                                   @drop.prevent="dropFile"
                            >
                                <v-col style="height: 380px;width:490px; border: dashed 4px #fff;">

                                    <v-card
                                        height="350"
                                        class="pa-2 grey darken-1 align-self-center"
                                        elevation="0"

                                    >
                                        <v-col sm="12" class="text-center mt-10">
                                            <v-icon
                                                left style="font-size: 150px"

                                                class="text-center">
                                                mdi-file-upload-outline
                                            </v-icon>
                                        </v-col>
                                        <v-col sm="12" class="text-center font-weight-bold h5">{{ $t('drop_files_here_to_send_them') }}</v-col>


                                    </v-card>


                                </v-col>
                            </v-col>
                        </v-row>


                    </v-overlay>
                </template>

                <template v-slot:side>
                    <v-col cols="12" class="pa-0">
                        <FormRight v-for="form in forms" :key="form.sort" entity_type="deal" :entity_uuid="deal_uuid" :form="form" />
                    </v-col>
                    <v-card :style="forms.length > 0 ? 'margin-top: 15px' : ''" v-if="can(['client_module'])">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('additional_information') }}</div>
                        </v-card-title>
                        <v-card-text>
                            <v-row v-if="false">
                                <v-col cols="12" sm="6" class="client_title">{{ $t('form_of_payment') }}</v-col>
                                <v-col cols="12" md="6" class="client_text" :class="show_payment_type ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('payment_type')" v-if="!show_payment_type">
                                        <span v-if="payment_type && payment_type.name">{{ payment_type.name }}</span>
                                        <span v-else class="client_no_text">{{ $t('select_value') }}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="payment_type" rules="required" v-slot="{ errors, valid }">
                                            <v-select v-model="payment_type" :disabled="loading" :items="payment_typeItems"
                                                      autofocus
                                                      @change="changeField('payment_type')"
                                                      @focusout="changeField('payment_type')"
                                                      :error="!valid" :error-messages="errors"
                                                      item-text="name" item-value="id"
                                                      return-object
                                                      hide-details
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                            ></v-select>
                                        </ValidationProvider>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="client_title">{{ $t('cost_of_services') }}</v-col>
                                <v-col cols="12" md="6" class="client_text">
                                    <div>
                                        <span>{{ deal_kp_cost_of_services }} {{deal_currency.character_code}}</span>
                                    </div>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="client_title">{{ $t('cost_transportation') }}</v-col>
                                <v-col cols="12" md="6" class="client_text">
                                    <div>
                                        <span>{{ application_form_cost_of_services }} {{deal_currency.character_code}}</span>
                                    </div>
                                </v-col>

                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="client_title">{{ $t('extra_charge') }}</v-col>
                                <v-col cols="12" md="6" class="client_text">
                                    <div>
                                        <span>{{ extra_charge }} {{deal_currency.character_code}}</span>
                                    </div>
                                </v-col>

                            </v-row>

                            <v-row>
                                <v-col cols="12" sm="6" class="client_title" :class="error_margin">{{ $t('margin') }}</v-col>
                                <v-col cols="12" md="6" class="client_text" :class="show_margin ? 'pt_3 pb-0': ''">
                                    <div @click="showInput('margin2')" v-if="!show_margin">
                                        <span v-if="margin && margin !== 'null'">{{ margin }} {{deal_currency.character_code}}</span>
                                        <span v-else class="client_no_text" :class="error_margin">{{ $t('enter_text') }}</span>
                                    </div>
                                    <div v-else>
                                        <ValidationProvider ref="bin_inn" rules="min:1|max:255"
                                                            v-slot="{ errors, valid }">

                                            <v-text-field
                                                autofocus
                                                @focusout="saveField('margin')"
                                                v-model="margin" type="text" :error-messages="errors"
                                                :disabled="loading"
                                                color="primary"
                                                hide-details
                                                solo
                                                flat
                                                dense
                                                class="ma-0 pa-0 my-0 py-0 input_text client_input"
                                                clearable
                                            ></v-text-field>
                                        </ValidationProvider>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row v-if="false">
                                <v-col cols="12" sm="6" class="client_title">{{ $t('expenses') }}</v-col>
                                <v-col cols="12" md="6" class="client_text">
                                    <div>
                                        <span :class="expenses === 0 ? 'client_no_text' : ''">{{ expenses }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" sm="6" class="client_title">{{ $t('status') }}</v-col>
                                <v-col cols="12" md="6" class="client_text">
                                    <div>
                                        <span>{{ deal_step_name }}</span>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card style="margin-top: 15px">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('participants') }}</div>
                            <v-icon
                                :disabled="userBlock || closed"
                                size="18"
                                color="secondary"
                                class="add_icon"
                                @click="dialogAddParticipants=true"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" class="sub_participants" v-if="author && author.name">{{ $t('author') }}</v-col>
                                <v-col cols="12" class="py-1" v-if="author && author.name">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="author.photo !== '/img/avatar.png'" :src="author.photo" :alt="author.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="author.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="formatPhoneNumber(author.phone)"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>

                                <template v-if="responsible && responsible[0] !== null && responsible[0] !== undefined  && responsible[0].name !== null">
                                    <v-col cols="12" class="sub_participants" >{{ $t('responsible') }}</v-col>
                                    <v-col cols="12" class="py-1" v-for="responsible in responsible" :key="'responsible-' + responsible.id">
                                        <v-list class="my-0 py-0">
                                            <template>
                                                <v-list-item class="px-0">
                                                    <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                        <img v-if="responsible.photo !== '/img/avatar.png'" :src="responsible.photo" :alt="responsible.name">
                                                        <v-icon v-else color="primary" size="22">
                                                            ${{ 'settingsIcon' }}
                                                        </v-icon>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content class="py-0 title_subtitle">
                                                        <v-list-item-title v-text="responsible.name"></v-list-item-title>
                                                        <v-list-item-subtitle v-text="formatPhoneNumber(responsible.phone)"></v-list-item-subtitle>
                                                    </v-list-item-content>

                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-col>
                                </template>

                                <v-col cols="12" class="sub_participants" v-if="executors.length > 0">{{ $t('co-executors') }}</v-col>
                                <v-col cols="12" class="py-1" v-for="item in executors" :key="'executors-' + item.id">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-if="item.status_text === 'accept'" v-text="$t('in_operation_for')"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else-if="item.status_text === 'reject'" v-text="$t('rejected_text', {'reason': item.text})" :title="item.text"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else-if="item.status_text === 'done'" v-text="$t('done')"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else-if="item.status_text === 'fail'" v-text="$t('fail') + ' ['+item.text+']'" :title="item.text"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else v-text="$t('decision_not_adopted')"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="sub_participants" v-if="auditors.length > 0">{{ $t('observers') }}</v-col>
                                <v-col cols="12" class="py-1" v-for="item in auditors" :key="'auditor-' + item.id">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>

                                <v-col cols="12" class="sub_participants" v-if="moderators.length > 0">{{ $t('auditors') }}</v-col>
                                <v-col cols="12" class="py-1" v-for="item in moderators" :key="'moderator-' + item.id">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="item.photo !== '/img/avatar.png'" :src="item.photo" :alt="item.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>
                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title v-text="item.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-text="formatPhoneNumber(item.phone)"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>

                                <v-col v-if="uved_show && uved_show.id">
                                    <v-list class="my-0 py-0">
                                        <template>
                                            <div class="my-0 sub_participants">{{ $t('uved.self') }}</div>
                                            <v-list-item class="px-0">
                                                <v-list-item-avatar size="36" class="border_blue my-0 py-0">
                                                    <img v-if="uved_show.photo !== '/img/avatar.png'" :src="uved_show.photo" :alt="uved_show.name">
                                                    <v-icon v-else color="primary" size="22">
                                                        ${{ 'settingsIcon' }}
                                                    </v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="py-0 title_subtitle">
                                                    <v-list-item-title class="cursor-pointer">
                                                        <v-btn text plain :to="'/uved/' + uved_show.uuid + '/show'" class="button_link">
                                                            {{ uved_show.name }}
                                                        </v-btn>
                                                    </v-list-item-title>
                                                    <v-list-item-subtitle v-if="uved_show.phone !== null" v-text="formatPhoneNumber(uved_show.phone.phone)"></v-list-item-subtitle>
                                                    <v-list-item-subtitle v-else v-text="uved_show.bin_inn"></v-list-item-subtitle>
                                                </v-list-item-content>

                                            </v-list-item>
                                        </template>
                                    </v-list>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </v-card>

                    <v-card style="margin-top: 15px">
                        <v-card-title>
                            <div class="participants mr-auto">{{ $t('files') }} {{ accompanying_document_urls.length > 0 ? ': ' + accompanying_document_urls.length : '' }}</div>
                            <v-icon
                                :disabled="userBlock || closed"
                                size="18"
                                color="secondary"
                                class="add_icon"
                                @click="document.getElementById('accompanying_documents').click()"
                            >
                                mdi-plus-circle-outline
                            </v-icon>
                        </v-card-title>
                        <v-card-text class="mt-0 pt-0" v-if="accompanying_document_urls.length > 0">
                            <v-list>
                                <v-list-item outlined class="file_item" v-for="file in accompanying_document_urls" :key="file.id + '_' + Date.now()">
                                    <v-list-item-icon>
                                        <v-img
                                            @click="openFile(file)" @contextmenu.prevent="downloadFile(file)"
                                            max-width="45"
                                            :alt="`${file.name}`"
                                            :src="file.url"
                                            max-height="30"
                                        ></v-img>
                                        <v-icon
                                            @click="openFile(file)" @contextmenu.prevent="downloadFile(file)"
                                            x-large
                                            size="10"
                                            color="primary"
                                            v-if="!mimeTypeImage(file.type)">mdi-file-document-outline
                                        </v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-content class="file_text">
                                        <v-list-item-title>
                                            <ContextMenuFile :file="file" @add-document-form ="addDocumentForm"  @open-file="openFileContext"></ContextMenuFile>
                                        </v-list-item-title>
                                        <v-list-item-subtitle v-text="(file.size / 1000).toFixed(1) + ' KB'"></v-list-item-subtitle>
                                        <v-list-item-subtitle v-text="file.author.name"></v-list-item-subtitle>
                                    </v-list-item-content>
                                    <v-list-item-icon class="d-flex align-self-center mt-3 px-0 mx-0">
                                        <v-icon color="primary" :title="$t('download')" @click="downloadFile(file)">mdi-cloud-upload</v-icon>
                                        <v-icon color="primary" :disabled="userBlock || closed" v-if="file.uuid" :title="$t('delete')" @click="deleteFileServer(file)">mdi-delete-outline</v-icon>
                                        <v-icon color="primary" :disabled="userBlock || closed" v-if="!file.uuid" :title="$t('delete')" @click="deleteAccompanyingDocumentFile(file)">mdi-delete-outline</v-icon>
                                    </v-list-item-icon>
                                </v-list-item>
                            </v-list>
                        </v-card-text>
                    </v-card>

                    <!-- accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                    <v-file-input
                        hide-input prepend-icon=""
                        :label="$t('accompanying_documents')"
                        v-model="accompanying_documents"
                        id="accompanying_documents"
                        multiple
                        @change="accompanyingDocumentsPreviewFiles"
                    >
                    </v-file-input>

                    <!--accept=".pdf,image/*,.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"-->
                    <v-file-input hide-input prepend-icon=""
                                  v-model="files"
                                  id="file"
                                  multiple
                                  @change="previewFiles"
                    >
                    </v-file-input>
                </template>
            </PageLayout>
        </ValidationObserver>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapActions, mapGetters} from "vuex";
import debounce from "lodash/debounce";
import CalendarCase from "../components/CalendarCase.vue";
import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'
import PageLayout from '@/components/Leentech/PageLayout.vue';
import FormRight from '@/components/Eav/FormRight.vue';
import FormTab from '@/components/Eav/FormTab.vue';
import FormTabField from '@/components/Eav/FormTabField.vue';
import UserSelection from "@/components/Form/UserSelection.vue";
import OpenFile from '@/components/OpenFile.vue';
import User from "@/components/User.vue";
import Messages from "@/components/Messages.vue";
import ShareView from "@/plugins/mixins/ShareView"
import BackView from "@/plugins/mixins/BackView"
import ContextMenuFile from "@/components/ContextMenuFile.vue";
import DocumentFormDialog from '@/components/DocumentFormDialog.vue';
import ListItem from "@/components/Tracking/ListItem.vue";
import NomenclatureSelection from "@/components/Warehouse/NomenclatureSelection.vue";
import TextInput from "@/components/Form/TextInput.vue";
import Btn from "@/components/Form/Btn.vue";

export default {
    name: 'DealShow',
    components: {
        NomenclatureSelection,
        Messages,
        User,
        UserSelection,
        ValidationProvider,
        ValidationObserver,
        CalendarCase,
        yandexMap,
        ymapMarker,
        PageLayout,
        FormRight,
        FormTab,
        FormTabField,
        OpenFile,
        ContextMenuFile,
        DocumentFormDialog,
        ListItem,
        TextInput,
        Btn
    },

    inject: ['forceRerender'],

    mixins: [
        ShareView,
        BackView
    ],

    props: {
        id: {
            type: String,
            default: null
        },
    },

    data() {
        return {
            closed: false,
            dialogForm: false,
            document_flow_urls_form: [],
            forms: [],
            tabs: [],
            edit_business_process: null,
            heading: null,
            uved_show: {},
            set_uved: {},
            author: {},
            progress: 0,
            loading: false,
            tab: 0,
            number: null,
            deal_statusItems: [],
            deal_status: null,
            deal_status_sort: 1,
            company: null,
            companyItems: [],
            companySearching: null,
            loadingCompanies: false,
            deal_type: null,
            dealTypeItems: [],
            dealTypeSearching: null,
            loadingDealTypes: false,
            text_deal: "",
            text_deal_edit: false,
            text: null,
            responsibleAdd: null,
            responsible: null,
            responsibleItems: [],
            responsibleSearching: null,
            loadingResponsibles: false,
            auditorsAdd: [],
            auditors: [],
            executorsAdd: [],
            executors: [],
            moderators: [],
            is_moderator: false,
            auditor: null,
            auditorItems: [],
            auditorSearching: null,
            loadingAuditors: false,
            dragover: false,
            document,
            documents: [],
            files: [],
            document_urls: [],
            dialog_file: false,
            file_urls: [],
            deal_uuid: null,
            deal_id: null,
            trackings: [],
            invoices: [],
            request: [],
            answer: [],
            headers: [
                {
                    text: this.$t('deal_type'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('quantity_short'),
                    align: "left",
                    sortable: true,
                    value: "count"
                },
                {
                    text: this.$t('price_sum'),
                    align: "left",
                    sortable: true,
                    value: "price"
                },

                {
                    text: this.$t('extra_charge'),
                    align: "left",
                    sortable: true,
                    value: "extra_charge"
                },
                {
                    text: this.$t('discount'),
                    align: "left",
                    sortable: true,
                    value: "discount"
                },
                {
                    text: this.$t('cost'),
                    align: "right",
                    sortable: true,
                    value: "total",
                    width: 150,
                }
            ],
            loadingDealOrders: false,
            dealOrderItems: [],
            currency: null,
            currencyItems: [],
            loadingCurrency: false,
            blur_stop: 0,
            deal_order_total: 0,
            deal_order_nds_total: 0,
            deal_order_no_nds_total: 0,
            deal_order_extra_charge_total: 0,
            chatMessages: [],
            chatMessagesDate: [],
            toolbar_reply: false,
            deal_active: false,
            reply_msg: {},
            reply_name: null,
            reply_uuid: null,
            message_menu: [
                {title: this.$t('delete'), click: "delete"},
                {title: this.$t('reply'), click: "reply"},
            ],
            created_at: null,
            accompanying_documents: [],
            accompanying_document_urls: [],
            accompanying_documents_preview: [],
            accompanying_documents_dialog: false,
            task_headers: [
                {
                    text: this.$t('task_name'),
                    align: "left",
                    sortable: true,
                    value: "title"
                },
                {
                    text: this.$t('task_status'),
                    sortable: false,
                    value: "task_status"
                },
                {
                    text: this.$t('deadline'),
                    sortable: true,
                    value: "deadline"
                },
                {
                    text: this.$t('date'),
                    sortable: true,
                    value: "created_at"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: false,
                    value: "admin.name"
                }
            ],
            taskItems: [],
            task: null,
            taskAddItems: [],
            taskSearching: null,
            totalTasks: 0,
            display_deal: true,
            display_task: false,
            display_invoice: false,
            display_parent_deal: false,
            display_case: false,
            display_tracking: false,
            display_chat: false,
            display_document: false,
            display_order: false,
            display_application: false,
            invoice_id: null,
            invoice_headers: [
                {
                    text: this.$t('amount'),
                    align: "left",
                    sortable: true,
                    value: "download"
                },
                {
                    text: this.$t('amount'),
                    align: "left",
                    sortable: true,
                    value: "amount"
                },
                {
                    text: this.$t('number_deal'),
                    align: "left",
                    sortable: true,
                    value: "number_deal"
                },
                {
                    text: this.$t('invoice_status'),
                    align: "left",
                    sortable: true,
                    value: "invoice_status"
                },
                {
                    text: this.$t('date'),
                    align: "left",
                    sortable: true,
                    value: "date"
                },
                {
                    text: this.$t('uved.self'),
                    align: "left",
                    sortable: true,
                    value: "uved"
                },
                {
                    text: this.$t('manager'),
                    align: "left",
                    sortable: true,
                    value: "admin.name"
                }
            ],
            invoice: null,
            invoiceAddItems: [],
            invoiceSearching: null,
            loadingInvoice: false,
            invoice_status_edit: null,
            invoiceStatusEditItems: [],
            dialogInvoiceStatus:false,
            status: null,
            statusItems: [],
            loadingStatus: false,
            sub_status: null,
            sub_statusItems: [],
            loadingSubStatus: false,
            tab_type: "data",
            data_info: [
                {
                    "type": "data",
                    "name" : this.$t('data'),
                    "class": "mr-2",
                    "color": "primary",
                    "icon": 'icon-doc',
                },
                {
                    "type": "tracking",
                    "name": this.$t('tracking'),
                    "class": "border_0",
                    "icon": 'icon-binoculars',
                    "color": "color_B3B3B3"
                },
                {
                    "type": "event",
                    "name": this.$t('event'),
                    "class": "border_0",
                    "icon": 'icon-check-circle-1',
                    "color": "color_B3B3B3"
                }
            ],
            info_type: "data",
            tracking_geo: [],
            referencePoints: [],
            showAllMarkers: true,
            mapMarker: null,
            zoom: 12,
            ymaps: null,
            coordinates: [],
            showMap: false,
            coordinatesPolygon: [],
            dialogDetailTracking: false,
            detail_tracking: {},
            selected: [],
            topicItems: [],
            loadingDealStatuses: false,
            loadingMessages: false,
            loadingTracking: false,
            loadingInvoices: false,
            loadingTasks: false,
            loadingDeal: false,
            loadingMyTopics: false,
            progressDeal: 0,
            dialogAddTopic: false,
            my_topic: null,
            myTopicsItems: [],
            topicAddShowIcon: true,
            dialogLinkTask: false,
            dialogLinkInvoice: false,
            dialogAddParticipants: false,
            dialogAddParticipant: false,
            uved: null,
            uvedItems: [],
            uvedSearching: null,
            loadingUveds: false,
            favorite: false,
            payment_type: null,
            payment_typeItems: [],
            show_payment_type: false,
            dialog_image_view:false,
            image_view: {},
            showSuggestions: false,
            suggestions: [],
            menuX: 0,
            menuY: 0,
            inputAfterAt: "",
            activeIndex: 0,
            heightSuggestions: 150,
            error_margin: '',
            margin: 0,
            deal_kp_cost_of_services: 0,
            application_form_cost_of_services: 0,
            expenses: 0,
            show_margin: false,
            type_expense: null,
            typeExpenseItems: [],
            headerDocument: [
                {
                    text: this.$t('file_name'),
                    align: "left",
                    sortable: true,
                    value: "file"
                },
                {
                    text: this.$t('document_type'),
                    align: "left",
                    sortable: true,
                    value: "document_type.name"
                },
                {
                    text: this.$t('author'),
                    align: "left",
                    sortable: true,
                    value: "admin.name"
                },
                {
                    text: this.$t('date_created'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },

            ],
            dealDocumentItems: [],
            document_type: null,
            documentTypeItems: [],
            add_documents: [],
            document_dialog: false,
            documents_preview: [],
            step_color: null,
            deal_step_id: null,
            deal_step_name: null,
            deal_step_button_text: '',
            loadingDealStep: false,
            dealStepItems: [],
            loadingStepButton: false,
            business_process: null,
            dialogAccompanyStep: false,
            step_uuid: null,
            step_text: null,
            document_error: '',
            deal_error: '',
            tracking_error: '',
            order_error: '',
            application_error: '',
            invoice_error: '',
            application_form: false,
            order_form: false,
            button_text_step: 'OK',
            dateDispatchDialog: false,
            date_dispatch: null,
            type_container: null,
            typeContainerItems: [],
            type_transport: null,
            typeTransportItems: [],
            supplier: null,
            supplierItems: [],
            loadingBet: false,
            supplier_text: null,
            supplierDialogForm: false,
            shipper: null,
            from_city: null,
            to_city: null,
            terms_delivery: null,
            show_place_argo_pickup: false,
            place_argo_pickup: null,
            loading_type_form: null,
            loadingTypeItems: [],
            stackable_cargo: null,
            insurance: null,
            dangerous_cargo: null,
            special_text: null,
            cost_of_services: null,
            containers: null,
            price_container: null,
            price: null,
            transit_station: null,
            additional_expenses_amount: null,
            additional_expenses_text: null,
            margin_form: null,
            delivery_time: null,
            deal_kp_uuid: null,
            cost_of_product: null,
            factory_contact_details: null,
            special_requirements_factory: null,
            consignee: null,
            place_loading: null,
            unloading: null,
            supplier_form: null,
            type_transport_form: null,
            payment_type_from: null,
            type_export: null,
            type_exportItems: [],
            type_container_form: null,
            payer: null,
            payerItems: [],
            number_seats: null,
            type_packaging: null,
            name_cargo: null,
            hs_code: null,
            weight_form: null,
            volume_form: null,
            order_number: null,
            cost_taking_cargo: null,
            cost_export_clearance: null,
            cost_cargo_transportation: null,
            other: null,
            note: null,
            application_form_uuid: null,
            yesNoItems: [
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],

            distance: null,
            departure: null,
            departureItems:[],
            departureSearching: null,
            loadingDepartures: false,
            destination: null,
            destinationItems:[],
            destinationSearching: null,
            loadingDestination: false,
            country: null,
            countryItems: [],
            dialogFormStation: false,
            code: null,
            station_name: null,
            exist_translations: {},
            all_translations: true,
            add_type_station: null,
            dialogOpenFile: false,
            fileOpen: {},
            dialogEditPrice: false,
            log_cost_of_services: null,
            log_price: null,
            log_price_container: null,
            log_containers: null,
            log_additional_expenses_amount: null,
            log_additional_expenses_text: null,
            type_price_edit: null,
            edit_price_uuid: null,
            extra_charge: null,
            dialogHistoryPrice: false,
            headerHistoryPrice: [
                {
                    text: this.$t('who_changed_price'),
                    align: "left",
                    sortable: true,
                    value: "admin.name"
                },
                {
                    text: this.$t('cost_of_services'),
                    align: "left",
                    sortable: true,
                    value: "cost_of_services"
                },
                {
                    text: this.$t('containers'),
                    align: "left",
                    sortable: true,
                    value: "containers"
                },
                {
                    text: this.$t('price_container'),
                    align: "left",
                    sortable: true,
                    value: "price_container"
                },
                {
                    text: this.$t('additional_expenses_amount'),
                    align: "left",
                    sortable: true,
                    value: "additional_expenses_amount"
                },
                {
                    text: this.$t('additional_expenses_text'),
                    align: "left",
                    sortable: true,
                    value: "additional_expenses_text"
                },
                {
                    text: this.$t('date_of_change'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },

            ],
            historyPriceItems: [],
            parent_deal_headers: [
                {
                    text: this.$t('columns.deal.number'),
                    align: "left",
                    sortable: true,
                    value: "number"
                },
                {
                    text: this.$t('uved.self'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('business_process'),
                    align: "left",
                    sortable: true,
                    value: "business_process.name"
                },
                {
                    text: this.$t('deal_main_status'),
                    align: "left",
                    sortable: true,
                    value: "deal_status"
                },
                {
                    text: this.$t('columns.deal.admin_id'),
                    align: "left",
                    sortable: true,
                    value: "admin.name"
                },
                {
                    text: this.$t('columns.deal.created_at'),
                    align: "left",
                    sortable: true,
                    value: "created_at"
                },
            ],
            parentDealItems: [],
            loadingParentDeals: false,
            under_deal: false,
            is_executor: false,
            executor_deal_status: null,
            executor_text: null,
            executor_date_approval: null,
            displayCancelStatusExecutor: false,
            status_executor: null,
            status_executor_text: null,
            trackingLogItems:[],
            dialogTracking: false,



            filterShow: false,
            detailsItems:[],
            dateFrom: null,
            dateMenuFrom: false,
            dateTo: null,
            dateMenuTo: false,
            dateFromGr: null,
            dateMenuFromGr: false,
            dateToGr: null,
            dateMenuToGr: false,
            dateFromPr: null,
            dateMenuFromPr: false,
            dateToPr: null,
            dateMenuToPr: false,
            application_number: null,
            loadingDelivered: false,
            delivered: null,
            stationSearching: null,
            loadingStation: false,
            loadingTypeContainer: null,
            loadingStatuses: false,
            tracking_uuid: null,
            container_number: null,
            platform_number: null,
            platform_number_knr: null,
            date_sending: null,
            date_border: null,
            date_gr: null,
            date_pr: null,
            station: null,
            stationItems:[],
            loading_type: null,
            subStatusItems: [],
            menuDateSending: false,
            dateMenuPr: false,
            dateMenuGr: false,
            dateMenuBorder: false,
            deal_currency: {},
            selectedCurrency: {},
            currencies: [],
            dialogTrackingContainer: false,

            nomenclatureSelectPopupVisibility: false,
            goodSelectPopupVisibility: false,
            nomenclature: null,
            good_name: null,

        }
    },
    computed: {
        ...mapGetters(['userBlock', 'language', 'listLanguages', 'lang', 'timezone']),
        coords: {
            get: function () {
                return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
            },
            set: function (val) {
                this.coordinates = (val && val.length === 2) ? val : [51, 71]
            }
        },
        mapSettings() {
            this.$yandex.lang = 'ru_RU'
            return this.$yandex
        },
        languages() {
            return this.listLanguages
        },
        filteredSuggestions() {
            // Фильтрация предложений на основе текста после символа "@"
            let suggestionItems = this.suggestions.filter((suggestion) =>
                suggestion.toLowerCase().includes(this.inputAfterAt.toLowerCase())
            );
            if (suggestionItems.length >= 3) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 150;
                this.menuY = textareaRect.top - 5;
            } else if (suggestionItems.length === 2) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 100;
                this.menuY = textareaRect.top - 4.5;
            } else if (suggestionItems.length === 1) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 50;
                this.menuY = textareaRect.top - 4.9;
            } else if (suggestionItems.length === 0) {
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.heightSuggestions = 0;
                this.menuY = textareaRect.top - 5.1;
            }
            return suggestionItems;
        },
    },
    async mounted() {
        await this.init();
        const observer = new MutationObserver(() => {
            if (this.$refs.calendarCase) {
                this.getCalendarCase();
                observer.disconnect(); // Останавливаем наблюдение после обнаружения элемента
            }
        });
        // Начинаем наблюдать за изменениями в DOM в корневом элементе компонента
        observer.observe(this.$el, { childList: true, subtree: true });
    },
    watch: {
        id: {
            handler: function (val) {
                this.init();
            },
            immediate: true
        },

        companySearching: debounce(function (val) {
            if (val && !this.company) {
                this.getCompanies(val)
            }
        }, 500),
        taskSearching: debounce(function (val) {
            if (val) {
                this.getAddTasks(val)
            }
        }, 500),
        invoiceSearching: debounce(function (val) {
            if (val) {
                this.getAddInvoices(val)
            }
        }, 500),
        responsibleSearching: debounce(function (val) {
            if (val) {
                this.getResponsibles(val)
            }
        }, 500),
        auditorSearching: debounce(function (val) {
            if (val) {
                this.getAuditors(val)
            }
        }, 500),
        dealTypeSearching: debounce(function (val) {
            if (val) {
                this.getDealTypes(val)
            }
        }, 500),
        uvedSearching: debounce(function (val) {
            if (val && !this.uved) {
                this.getUveds(val)
            }
        }, 500),
        departureSearching: debounce(function (val) {
            if (val && !this.departure) {
                this.getDepartures(val)
            }
        }, 500),
        destinationSearching: debounce(function (val) {
            if (val && !this.destination) {
                this.getDestination(val)
            }
        }, 500),
        stationSearching: debounce(function (val) {
            if (val && !this.station) {
                this.getStations(val)
            }
        }, 500),
    },
    methods: {
        ...mapActions(['readEntityNotification', 'fetchNotificationGroups', 'fetchNotifications']),

        async init() {
            await this.checkCreate()
            await this.getTaps()
            await this.getGroups()
            await this.getDealStepButton()
            await this.getDealStatuses()
            await this.getCurrencies()
            await this.getDealOrders()

            await this.getStatus()
            //await this.getSubStatus()
            await this.getTracking()
            await this.getInvoices()
            await this.getInvoiceStatuses()

            await this.getTasks()
            await this.getTopic()
            await this.getPaymentTypes()
            if (this.$refs.calendarCase) {
                this.getCalendarCase();
            }
            await this.getParentDeals();
            await this.getAdmins();

            await this.getDealDocuments();

            if(this.$route.params.id) {
                this.readEntityNotification({entity: 'deal', uuid: this.$route.params.id})
                    .then(() => {
                        this.fetchNotifications()
                            .then(() => {
                                this.fetchNotificationGroups()
                            });
                    })
            }

            await this.getCountries()
        },

        selectCurrency(currency) {
            this.selectedCurrency = currency;
        },

        getContainerIcon(containers, becomeContainers) {
            const containerValue = parseInt(containers);
            const becomeContainerValue = parseInt(becomeContainers);

            return containerValue === becomeContainerValue ? 'mdi-arrow-split-vertical' : 'mdi-arrow-right-thin';
        },

        getContainerColor(containers, becomeContainers) {
            const containerValue = parseInt(containers);
            const becomeContainerValue = parseInt(becomeContainers);

            if(this.application_form_uuid) {
                if (containerValue === becomeContainerValue) {
                    return 'black';
                }
                return containerValue >= becomeContainerValue ? 'green' : 'red';
            }
            else if(this.deal_kp_uuid){
                if (containerValue === becomeContainerValue) {
                    return 'black';
                }
                return containerValue <= becomeContainerValue ? 'green' : 'red';
            }
            else {
                return 'black'
            }
        },

        async openDialogTrackingContainer(item) {
            await this.getTypeContainer()
            await this.getTrackingEdit(item.uuid)
            this.dialogTrackingContainer = true;
        },
        closeDialogTrackingContainer() {
            this.dialogTrackingContainer = false;
            this.tracking_uuid = null;
            this.container_number = null;
            this.platform_number = null;
            this.platform_number_knr = null;
            this.date_sending = null;
            this.date_border = null;
            this.date_gr = null;
            this.date_pr = null;
            this.distance = null;
            this.station = null;
            this.stationItems = [];
            this.type_container = null;
            this.loading_type = null;
            this.type_transport = null;
            this.status =  null;
            this.sub_status =  null;
            this.subStatusItems = []
        },
        async saveTrackingContainer() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.container_number) {
                formData.append('container_number', this.container_number)
            }

            if (this.type_container) {
                if (this.type_container.id) {
                    formData.append('type_container', this.type_container.id)
                } else {
                    formData.append('type_container', this.type_container)
                }
            }



            if (this.tracking_uuid) {
                await this.$http
                    .put(`admin/tracking_container/${this.tracking_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('tracking_has_been_updated'))
                        this.closeDialogTrackingContainer();
                        this.getTracking();

                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('tracking_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async showPriceHistoryDialog(type){
            this.dialogHistoryPrice = true;
            this.loading = true
            let params = {}

            let show_price = '';
            if(this.application_form_uuid){
                show_price = this.application_form_uuid;
            }
            else if(this.deal_kp_uuid){
                show_price = this.deal_kp_uuid;
            }

            await this.$http
                .get(`admin/${type}/${show_price}/price`, {
                    params: params,
                })
                .then(res => {
                    this.historyPriceItems = res.body.data
                })
                .catch(err => {
                    this.historyPriceItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        closePriceDialog(){
            this.dialogEditPrice = false;
            this.cost_of_services = this.log_cost_of_services;
            this.price = this.log_price;
            this.price_container = this.log_price_container;
            this.containers = this.log_containers;
            this.additional_expenses_amount = this.log_additional_expenses_amount;
            this.additional_expenses_text = this.log_additional_expenses_text;
            this.type_price_edit = null;
            this.edit_price_uuid = null;
        },
        showPriceDialog(type){
            this.dialogEditPrice = true;
            this.log_cost_of_services = this.cost_of_services;
            this.log_price = this.price;
            this.log_price_container = this.price_container;
            this.log_containers = this.containers;
            this.log_additional_expenses_amount = this.additional_expenses_amount;
            this.log_additional_expenses_text = this.additional_expenses_text;
            this.type_price_edit = type;
            if(this.application_form_uuid){
                this.edit_price_uuid = this.application_form_uuid;
            }
            else if(this.deal_kp_uuid){
                this.edit_price_uuid = this.deal_kp_uuid;
            }
        },
        async saveEditPrice(){

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.additional_expenses_amount) {
                formData.append('additional_expenses_amount', this.additional_expenses_amount)
            }
            if (this.additional_expenses_text) {
                formData.append('additional_expenses_text', this.additional_expenses_text)
            }
            if (this.cost_of_services) {
                formData.append('cost_of_services', this.cost_of_services)
            }
            if (this.containers) {
                formData.append('containers', this.containers)
            }
            if (this.price_container) {
                formData.append('price_container', this.price_container)
            }
            if (this.edit_price_uuid) {
                // Save
                await this.$http
                    .put(`admin/${this.type_price_edit}/${this.edit_price_uuid}/edit_price`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.margin = res.body.data.margin;
                        this.extra_charge = res.body.data.extra_charge;
                        this.deal_kp_cost_of_services = res.body.data.deal_kp_cost_of_services;
                        this.application_form_cost_of_services = res.body.data.application_form_cost_of_services;

                        this.dialogEditPrice = false;
                        this.type_price_edit = null;
                        this.edit_price_uuid = null;
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        costServiceUpdate(){
            if(parseFloat(this.price) > 0) {
                if(parseFloat(this.additional_expenses_amount) > 0){
                    this.cost_of_services = parseFloat(this.price) + parseFloat(this.additional_expenses_amount);
                }
                else {
                    this.cost_of_services = parseFloat(this.price);
                }
            }
            if(parseInt(this.containers) > 0 &&  parseFloat(this.price_container) > 0) {
                this.cost_of_services = parseFloat(this.price_container) * parseInt(this.containers)
                if(parseFloat(this.additional_expenses_amount) > 0){
                    this.cost_of_services = parseFloat(this.cost_of_services) + parseFloat(this.additional_expenses_amount);
                }
            }

        },
        updateAmount(){
           this.cost_of_services = null;
           this.price_container = null;
           this.containers = null;
           this.cost_of_services = null;
           this.price = null;
           this.weight_form = null;
           this.volume_form = null;
           this.type_container_form = null;
           this.number_seats = null;
        },
        addExpensesAmount() {

            if(parseFloat(this.price) > 0) {
                if(parseFloat(this.additional_expenses_amount) > 0){
                    this.cost_of_services = parseFloat(this.price) + parseFloat(this.additional_expenses_amount);
                }
                else {
                    this.cost_of_services = parseFloat(this.price);
                }
            }


            if(parseFloat(this.containers) > 0) {
                this.cost_of_services = parseFloat(this.price_container) * parseInt(this.containers)
                if(parseFloat(this.additional_expenses_amount) > 0){
                    this.cost_of_services = parseFloat(this.cost_of_services) + parseFloat(this.additional_expenses_amount);
                }
            }

        },


        showTab(item){
            this.display_open('close_all')
            this.tabs.forEach(tab => {
                if (tab.name === item.name && tab.tab_id === item.tab_id) {
                    tab.show = true;
                } else {
                    tab.show = false;
                }
            });
        },
        async getTaps(){

            this.loading = true;
            let params = {};

            params.type_form = 'tabs';
            params.entity_type = 'deal';
            params.entity_uuid = this.$route.params.id;
            params.sub_entity_type = 'business_process';
            params.sub_entity_id = this.edit_business_process;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.tabs = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_tabs'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getGroups(){

            this.loading = true;
            let params = {};

            params.type_form = 'groups';
            params.entity_type = 'deal';
            params.entity_uuid = this.$route.params.id;
            params.sub_entity_type = 'business_process';
            params.sub_entity_id = this.edit_business_process;

            await this.$http
                .get(`admin/dynamic_form`, {
                    params: params,
                })
                .then(res => {
                    if (res?.body?.data?.[0]?.id) {
                        this.forms = res.body.data;
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_list_groups'))
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async translation(text, comment_uuid = '') {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            formData.append('text', text)
            if (comment_uuid !== '') {
                formData.append('comment_uuid', comment_uuid)
            }
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }

            // Save
            await this.$http
                .post(`admin/translate_text`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    if (res.body.data.comment) {
                        let message_date = this.chatMessagesDate[this.$moment(res.body.data.comment.created_at).format('YYYY-MM-DD')];
                        let uuidToFind = res.body.data.comment.uuid;
                        let foundObject = message_date.find(obj => obj.uuid === uuidToFind);
                        if (typeof foundObject != "undefined") {
                            foundObject.comment = res.body.data.comment.comment;
                            foundObject.uuid = res.body.data.comment.uuid;
                        }
                    }

                    this.$toastr.success(this.$t('translated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('translation_error'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        getTranslation(translations) {
            if (!Array.isArray(translations) || translations.length === 0) {
                translations = [];
                return null;
            }
            let translationObject = translations.find(obj => obj && Object.prototype.hasOwnProperty.call(obj, this.language));
            return translationObject ? translationObject[this.language] : null;
        },
        async checkCreate() {
            if (this.$route.name === "deal.create") {
                this.heading = this.$t('deal_creation')
            } else {
                this.heading = this.$t('deal_editing')
                if (this.$route.params.id) {
                    await this.getDeal()
                }
            }
        },

        scrollCard() {
            this.$nextTick(() => {
                // Прокрутка до нижней части после обновления списка
                if (this.$refs.scrollContainer !== undefined) {
                    const container = this.$refs.scrollContainer.$el;
                    const firstUnread = this.$refs.scrollContainer.$el.querySelector(".chat-message--new");

                    if (firstUnread) {
                        const containerRect = container.getBoundingClientRect();
                        const elementRect = firstUnread.getBoundingClientRect();
                        const offset = elementRect.top - containerRect.top + container.scrollTop;

                        container.scrollTo({
                            top: offset,
                            behavior: "smooth",
                        });
                    } else {
                        container.scrollTop = container.scrollHeight;
                    }
                }
            });
        },

        rowClass() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table5' : 'rowMobiClass';
        },
        rowClass2() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table7' : 'rowMobiClass';
        },
        rowClass4() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass Table6' : 'rowMobiClass';
        },
        rowClass3() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass TableDeal' : 'rowMobiClass';
        },
        rowDealGoods() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass TableTaskBetGoods' : 'rowMobiClass';
        },
        rowDeal() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass TableTaskFile' : 'rowMobiClass';
        },
        rowDTableExpenses() {
            return (!this.$vuetify.breakpoint.xsOnly) ? 'rowClass TableExpenses' : 'rowMobiClass';
        },
        async display_open(type) {
            if(type !== 'close_all'){
                this.tabs.forEach(tab => {
                    tab.show = false;
                });
            }
            switch (type) {
                case 'display_task':
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_chat = false;
                    this.display_case = false;
                    this.display_tracking = false;
                    this.display_deal = false;
                    this.display_document = false;
                    this.display_order = false;
                    this.display_application = false;
                    this.display_task = (this.display_task) ? false : true;
                    break;
                case 'close_all':
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_chat = false;
                    this.display_case = false;
                    this.display_tracking = false;
                    this.display_deal = false;
                    this.display_document = false;
                    this.display_order = false;
                    this.display_application = false;
                    this.display_task = false;
                    break;
                case 'display_invoice':
                    this.display_invoice = (this.display_invoice) ? false : true;
                    this.display_parent_deal = false;
                    this.display_task = false;
                    this.display_case = false;
                    this.display_deal = false;
                    this.display_chat = false;
                    this.display_tracking = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_order = false;
                    break;
                case 'display_case':
                    this.display_case = (this.display_case) ? false : true;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_deal = false;
                    this.display_chat = false;
                    this.display_tracking = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_order = false;
                    break;
                case 'display_tracking':
                    this.display_tracking = (this.display_tracking) ? false : true;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_deal = false;
                    this.display_chat = false;
                    this.display_case = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_order = false;
                    break;
                case 'display_deal':
                    this.display_deal = (this.display_deal) ? false : true;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_chat = false;
                    this.display_tracking = false;
                    this.display_case = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_order = false;
                    break;
                case 'display_chat':
                    this.display_chat = (this.display_chat) ? false : true;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_deal = false;
                    this.display_tracking = false;
                    this.display_case = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_order = false;
                    break;

                case 'display_document':
                    this.display_chat = false;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_deal = false;
                    this.display_tracking = false;
                    this.display_case = false;
                    this.display_application = false;
                    this.display_document = (this.display_document) ? false : true;
                    this.display_order = false;
                    if (this.documentTypeItems.length === 0) {
                        await this.getDocumentTypes();
                    }
                    await this.getDealDocuments();
                    break;
                case 'display_application':
                    this.display_chat = false;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_deal = false;
                    this.display_tracking = false;
                    this.display_case = false;
                    this.display_document = false;
                    this.display_order = false;
                    this.display_application = (this.display_application) ? false : true;
                    if (this.loadingTypeItems.length === 0) {
                        await this.getLoadingType();
                    }
                    if (this.typeTransportItems.length === 0) {
                        await  this.getTypeTransport();
                    }
                    if (this.typeContainerItems.length === 0) {
                        await this.getTypeContainer();
                    }
                    if (this.countryItems.length === 0) {
                        await this.getCountries()
                    }
                    this.ymapInit()
                    await this.getDealKp();
                    break;
                case 'display_order':
                    this.display_chat = false;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_parent_deal = false;
                    this.display_deal = false;
                    this.display_tracking = false;
                    this.display_case = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_order = (this.display_order) ? false : true;
                    if (this.supplierItems.length === 0) {
                        await this.getSupplier();
                    }
                    if (this.typeTransportItems.length === 0) {
                        await  this.getTypeTransport();
                    }
                    if (this.typeContainerItems.length === 0) {
                        await this.getTypeContainer();
                    }
                    if (this.type_exportItems.length === 0) {
                        await  this.getTypeExports();
                    }
                    if (this.payerItems.length === 0) {
                        await this.getPayers();
                    }
                    if (this.payment_typeItems.length === 0) {
                        await this.getPaymentTypes();
                    }
                    if (this.loadingTypeItems.length === 0) {
                        await this.getLoadingType();
                    }
                    await this.getApplicationForm();
                    break;
                case 'display_parent_deal':
                    this.display_chat = false;
                    this.display_task = false;
                    this.display_invoice = false;
                    this.display_order = false;
                    this.display_deal = false;
                    this.display_tracking = false;
                    this.display_case = false;
                    this.display_document = false;
                    this.display_application = false;
                    this.display_parent_deal = (this.display_parent_deal) ? false : true;
                    if (this.parentDealItems.length === 0) {
                        await this.getParentDeals();
                    }
                    break;
            }

        },

        async handleInput() {

            const lastChar = this.text.slice(-1);
            if (lastChar === "@") {
                this.showSuggestions = true;
                const textareaRect = this.$refs.textarea.$el.getBoundingClientRect();
                this.menuX = textareaRect.left;
                // this.menuY = textareaRect.bottom - 220;
                this.activeIndex = 0;

            } else if (lastChar === " ") {
                // Если введен пробел, закрываем меню
                this.showSuggestions = false;
                // Сбрасываем текст после "@" при введении пробела
                this.inputAfterAt = "";
                this.activeIndex = 0;
            } else if (this.showSuggestions) {
                // Если меню открыто и введенный символ не пробел, обновляем текст после "@"
                const atIndex = this.text.lastIndexOf("@");
                this.inputAfterAt = this.text.substring(atIndex + 1);
            }
        },
        selectSuggestion(suggestion) {
            // Заменяем текст в textarea выбранным предложением
            if (this.inputAfterAt.length > 0) {
                this.text = this.text.slice(0, -this.inputAfterAt.length) + suggestion + " ";
            } else {
                this.text = this.text + suggestion + " "
            }
            this.showSuggestions = false;
            // Сбрасываем текст после "@" после выбора предложения
            this.inputAfterAt = "";
            this.activeIndex = 0;
            this.$nextTick(() => {
                this.$refs.textarea.focus();
            });
        },
        handleArrowDown() {
            if (this.activeIndex >= 0 && this.activeIndex < this.filteredSuggestions.length) {
                this.$refs.listItem[this.activeIndex].$el.focus();
                this.activeIndex += 1;
            }

        },
        handleArrowUp() {
            if (this.activeIndex !== 0) {
                this.activeIndex -= 1;
                this.$refs.listItem[this.activeIndex].$el.focus();
            }
        },
        async getAdmins() {
            this.loading = true
            let params = {}
            params.filter = 'login'
            await this.$http
                .get("admin/admin", {
                    params: params,
                })
                .then(res => {
                    this.suggestions = res.body.data
                })
                .catch(err => {
                    this.suggestions = []
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async getPaymentTypes() {
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get("admin/payment_type", {
                    params: params,
                })
                .then(res => {
                    this.payment_typeItems = res.body.data
                })
                .catch(err => {
                    this.payment_typeItems = []
                })
                .finally(end => {
                    this.loading = false
                })
        },
        showInput(field) {
            if (this.userBlock === true || this.closed === true) {
                return;
            }
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
                if (field === 'margin') {
                    this.margin = this.margin.replace(/\s/g, '')
                    this.margin = (this.margin !== "") ? this.margin : 0;
                }

            }
        },
        saveField(field) {
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
            }
            this.saveDealField(field, this.$data[field]);
        },
        changeField(field) {
            const show = 'show_' + field;
            if (this.$data[show]) {
                this.$data[show] = false
            } else {
                this.$data[show] = true;
            }
            if (this.$data[field] && this.$data[field].id) {
                this.saveDealField(field, this.$data[field].id);
            } else {
                this.saveDealField(field, this.$data[field]);
            }
        },
        async saveDealField(field, value) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append(field, value)

            if (this.$route.params.id) {
                await this.$http
                    .put(`admin/deal/${this.$route.params.id}/field`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }

        },

        async favoriteUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }
            formData.append('type', 'deal')

            await this.$http
                .put(`admin/favorite/${this.$route.params.id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('favorite_has_been_updated'))
                    if (this.favorite) {
                        this.favorite = false;
                    } else {
                        this.favorite = true
                    }
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('favorite_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        ImageViewClose() {
            this.dialog_image_view = false;
            this.image_view = {};
        },
        ImageView(msg) {
            this.dialog_image_view = true;
            this.image_view = msg;
        },
        downloadFileMessage(url, file_name) {
            fetch(url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = file_name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },

        editInvoice(item) {
            this.$router.push({
                name: 'invoice.edit',
                params: {
                    id: item.id
                }
            })
        },
        async deleteInvoice(item) {
            if (confirm(this.$t('delete_invoice'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/invoice/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('invoice_has_been_deleted'))
                        this.getInvoices()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('invoice_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        closeInvoiceStatus() {
            this.dialogInvoiceStatus = false
            this.invoice_status_edit = null
            this.invoice_id = null
        },
        openDialogInvoiceStatus(item) {
            this.invoice_id = item.id
            this.invoice_status_edit = item.invoice_status_id
            this.dialogInvoiceStatus = true
        },
        async getInvoiceStatuses() {
            let params = {};
            params.filter = 'all';
            await this.$http
                .get(`admin/invoice_status`, {
                    params: params,
                })
                .then(res => {
                    this.invoice_statusItems = res.body.data
                    this.invoiceStatusEditItems = res.body.data
                    this.system_filters = res.body.data
                })
                .catch(err => {
                    this.invoice_statusItems = [];
                    this.invoiceStatusEditItems = [];
                    this.$toastr.error(this.$t('failed_to_get_list_invoice_statuses'))
                });
        },
        async paymentConfirmation() {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.invoice_id) {
                formData.append('invoice_id', this.invoice_id)
            }
            if (this.invoice_status_edit) {
                formData.append('invoice_status_edit', this.invoice_status_edit)
            }


            await this.$http
                .put(`admin/invoice/${this.invoice_id}/status`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('invoice_has_been_updated'))
                    this.closeInvoiceStatus();
                    this.getInvoices();
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    if (prop === 'answer_scanned' || prop === 'request_scanned') {
                                        this.$toastr.error(err.body.errors[prop][0])
                                    }
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },

        clearUveds() {
            if (!this.uved) {
                this.uvedItems = []
            }
        },
        redirectToUved(val) {
            if (val) {
                this.$router.push({
                    name: 'uved.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getUveds(str) {
            if (str) {
                this.loadingUveds = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.uved = str
                }
                await this.$http
                    .get("admin/uved", {
                        params: params,
                    })
                    .then(res => {
                        this.uvedItems = res.body.data
                    })
                    .catch(err => {
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                        this.$nextTick(() => {
                            if (this.$refs.uved) {
                                this.$refs.uved.$el.querySelector('input').focus();
                            }
                        });
                    })
            }
        },

        async editParticipants() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }

            if (this.responsibleAdd && this.responsibleAdd.length > 0) {
                for (let i in this.responsibleAdd) {
                    if (this.responsibleAdd[i].id !== undefined && this.responsibleAdd[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsibleAdd[i].id)
                    }
                }
            }
            if (this.auditorsAdd && this.auditorsAdd.length > 0) {
                for (let i in this.auditorsAdd) {
                    if (this.auditorsAdd[i].id !== undefined && this.auditorsAdd[i].id > 0) {
                        formData.append(`auditors[${i}]`, this.auditorsAdd[i].id)
                    }
                }
            }

            if (this.executorsAdd && this.executorsAdd.length > 0) {
                for (let i in this.executorsAdd) {
                    if (this.executorsAdd[i].id !== undefined && this.executorsAdd[i].id > 0) {
                        formData.append(`executors[${i}]`, this.executorsAdd[i].id)
                    }
                }
            }

            if (this.uved) {
                if (this.uved.id) {
                    formData.append('uved', this.uved.id)
                } else {
                    formData.append('uved', this.uved)
                }
            }


            // Add
            await this.$http
                .put('admin/deal_participant', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.dialogAddParticipants = false;
                    this.uved_show = this.set_uved;
                    this.responsible = this.responsibleAdd;
                    this.auditors = this.auditorsAdd;
                    this.executors = this.executorsAdd;
                    this.$toastr.success(this.$t('deal_has_been_updated'))

                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async editParticipant() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }

            if (this.responsibleAdd && this.responsibleAdd.length > 0) {
                for (let i in this.responsibleAdd) {
                    if (this.responsibleAdd[i].id !== undefined && this.responsibleAdd[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsibleAdd[i].id)
                    }
                }
            }

            // Add
            await this.$http
                .put('admin/participant_deal', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.dialogAddParticipant = false;
                    this.responsible = this.responsibleAdd;
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

        async getStatus() {
            var _this = this;
            this.loadingSataus = true;

            let params = {};
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/status`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {

                    this.statusItems = res.body.data

                })
                .catch(err => {
                    this.statusItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingSataus = false
                })
        },
        async getSubStatus() {
            var _this = this;
            this.loadingSubSataus = true;

            let params = {};
            if (this.language) {
                params.language = this.language
            }


            await this.$http
                .get(`admin/sub_status`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {

                    this.sub_statusItems = res.body.data

                })
                .catch(err => {
                    this.sub_statusItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_statuses'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingSubSataus = false
                })
        },
        show_info(type) {
            this.tab_type = type;
            if (type === 'tracking') {
                this.getTrackingDeal()
            }
            else if (type === 'event') {
                this.getTrackingLog()
            }

        },
        close_info() {
            this.detail_tracking = {};
            this.dialogDetailTracking = false;
        },
        async getTrackingLog() {
            var _this = this;
            this.loading = true;

            let params = {};

            await this.$http
                .get("admin/tracking_log/" + this.detail_tracking.uuid, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.trackingLogItems = res.body.data;
                })
                .catch(err => {
                    this.trackingLogItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_tracking_logs'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async getTrackingDeal() {
            var _this = this;
            this.loading = true;

            let params = {};


            await this.$http
                .get("admin/tracking/deal/" + this.deal_uuid, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.referencePoints = []
                    this.tracking_geo = res.body.data.tracking[0];
                    this.coordinates = this.tracking_geo.from.point

                    this.referencePoints.push(this.tracking_geo.from.point);
                    if (this.tracking_geo && this.tracking_geo.tracking) {
                        for (var i = 0; i < this.tracking_geo.tracking.length; i++) {
                            this.referencePoints.push(this.tracking_geo.tracking[i].point)
                        }
                    }


                    this.ymapInit()
                })
                .catch(err => {
                    this.tracking_geo = []
                    this.$toastr.error(this.$t('failed_to_get_list_tracking'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async ymapInit() {
            var _this = this
            if (window.ymaps) {
                this.showMap = false
                if (this.$refs.map && this.$refs.map.myMap) {
                    this.$refs.map.myMap.destroy()
                }
                document.getElementById("vue-yandex-maps").remove()
                delete window.ymaps
            }
            await loadYmap({...this.mapSettings, debug: true})
            if (window.ymaps) {
                window.ymaps.ready(function () {
                    _this.showMap = true
                })
            }
        },
        initHandler(e) {
            var _this = this;
            if (this.coords && this.coords.length !== 2 && window.ymaps) {
                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: true,
                    autoReverseGeocode: true
                })
                location.then(
                    function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }
            e.geoObjects.options.set('draggable', true)
            _this.myMap = e;
            var multiRoute = new window.ymaps.multiRouter.MultiRoute({
                // Точки маршрута. Точки могут быть заданы как координатами, так и адресом.
                referencePoints: this.referencePoints,
            }, {
                // Внешний вид путевых точек.
                wayPointStartIconColor: "#FFFFFF",
                wayPointStartIconFillColor: "#B3B3B3",
                // Внешний вид линии активного маршрута.
                routeActiveStrokeWidth: 4,
                routeActiveStrokeStyle: 'solid',
                routeActiveStrokeColor: "#007EC9",
                // Внешний вид линий альтернативных маршрутов.
                routeStrokeStyle: 'dot',
                routeStrokeWidth: 3,
                // Автоматически устанавливать границы карты так,
                // чтобы маршрут был виден целиком.
                boundsAutoApply: true
            });


            _this.myMap.geoObjects.add(multiRoute);
            //e.geoObjects.add(multiRoute);

            var fullscreenControl = new window.ymaps.control.FullscreenControl();
            _this.myMap.controls.add(fullscreenControl);
            //  e.controls.add('FullscreenControl');
        },

        async getTopic() {
            var _this = this;
            this.loading = true;
            let params = {};
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal/${this.deal_uuid}/topic`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.topicItems = res.body.data
                    if (this.topicItems.length <= 0) {
                        this.topicAddShowIcon = false;
                    }
                })
                .catch(err => {
                    this.topicItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_topics'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        openChat(item) {
            this.$router.push({
                name: 'topic.message',
                params: {
                    id: item.uuid
                }
            })
        },

        clearInvoices() {
            if (!this.invoice) {
                this.invoiceAddItems = []
            }
        },
        clearEmptyInvoiceItems(items) {
            if (items && items.length > 0) {
                this.invoice = items.filter(item => typeof item === 'object')
            }
        },
        async getAddInvoices(str) {
            if (str) {
                this.loadingInvoice = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/invoice", {
                        params: params,
                    })
                    .then(res => {
                        this.invoiceAddItems = res.body.data
                    })
                    .catch(err => {
                        this.invoiceAddItems = []
                    })
                    .finally(end => {
                        this.loadingInvoice = false
                    })
            }
        },
        async addDealToInvoice() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.invoice && this.invoice.length > 0) {
                for (let i in this.invoice) {
                    if (this.invoice[i].id !== undefined && this.invoice[i].id > 0) {
                        formData.append(`invoices[${i}]`, this.invoice[i].id)
                    }
                }
            }

            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }
            // Add
            await this.$http
                .post('admin/deal_invoice', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('invoice_has_been_updated'))
                    this.dialogLinkInvoice = false;
                    this.getInvoices();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },


        clearTasks() {
            if (!this.task) {
                this.taskAddItems = []
            }
        },
        clearEmptyTaskItems(items) {
            if (items && items.length > 0) {
                this.task = items.filter(item => typeof item === 'object')
            }
        },
        async getAddTasks(str) {
            if (str) {
                this.loadingTasks = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.task = str
                }
                await this.$http
                    .get("admin/task", {
                        params: params,
                    })
                    .then(res => {
                        this.taskAddItems = res.body.data
                    })
                    .catch(err => {
                        this.taskAddItems = []
                    })
                    .finally(end => {
                        this.loadingTasks = false
                    })
            }
        },
        async addDealToTask() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.task && this.task.length > 0) {
                for (let i in this.task) {
                    if (this.task[i].id !== undefined && this.task[i].id > 0) {
                        formData.append(`tasks[${i}]`, this.task[i].id)
                    }
                }
            }

            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }
            // Add
            await this.$http
                .post('admin/deal_task', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('task_has_been_updated'))
                    this.dialogLinkTask = false;
                    this.getTasks();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('task_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async getTasks() {
            this.loadingTasks = true;
            let params = {};
            params.sortBy = 'created_at';
            params.sortDir = 'desc';
            params.task_status = -2

            await this.$http
                .get(`admin/deal_tasks/${this.deal_uuid}`, {
                    params: params,
                })
                .then(res => {
                    this.taskItems = res.body.data
                    this.totalTasks = res.body.data.length
                })
                .catch(err => {
                    this.taskItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_tasks'))
                })
                .finally(end => {
                    this.loadingTasks = false
                })
        },
        async getParentDeals() {
            this.loadingParentDeals = true;
            let params = {};
            params.sortBy = 'created_at';
            params.sortDir = 'desc';

            await this.$http
                .get(`admin/patent_deals/${this.deal_uuid}`, {
                    params: params,
                })
                .then(res => {
                    this.parentDealItems = res.body.data
                })
                .catch(err => {
                    this.parentDealItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_deals'))
                })
                .finally(end => {
                    this.loadingParentDeals = false
                })
        },
        goToUved(uved_show) {
            this.$router.push({
                name: 'uved.show',
                params: {
                    id: uved_show.uuid
                }
            })
        },
        showTask(item) {
            this.$router.push({
                name: 'task.show',
                params: {
                    id: item.uuid
                },
            })
            this.forceRerender()
        },
        addTask() {
            this.$router.push({
                name: 'task.create.deal',
                params: {
                    deal_uuid: this.$route.params.id
                }
            })
        },
        linkTask() {
            this.dialogLinkTask = true;
        },
        async addParentDeal() {
            if (confirm(this.$t('add_subprocess'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .put(`admin/parent_deal/${this.$route.params.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_added'))
                        this.getParentDeals()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        linkParentDeal() {
            //this.dialogLinkParentDeal = true;
        },
        addInvoice() {
            this.$router.push({
                name: 'invoice.create.deal',
                params: {
                    deal_uuid: this.$route.params.id
                }
            })
        },
        linkInvoice() {
            this.dialogLinkInvoice = true;
        },
        async getMyTopics() {
            this.loadingMyTopics = true
            let params = {}
            params.filter = 'all'

            await this.$http
                .get("admin/my_topic/no_deal", {
                    params: params,
                })
                .then(res => {
                    this.myTopicsItems = res.body.data
                    this.dialogAddTopic = true;
                })
                .catch(err => {
                    this.myTopicsItems = []
                })
                .finally(end => {
                    this.loadingMyTopics = false
                })
        },
        async addDealToTopic() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.my_topic) {
                formData.append(`topics_uuid[${0}]`, this.my_topic)
            }

            if (this.deal_id) {
                formData.append('deal', this.deal_id)
            }
            // Add
            await this.$http
                .post('admin/my_topic/deal', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                    this.dialogAddTopic = false;
                    this.getTopic();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


            },
        async addMyTopic() {
            if (confirm(this.$t('do_you_really_want_to_create_chat_for_deal'))) {
                var _this = this;
                this.progress = 0
                this.loading = true
                var formData = new FormData()


                if (this.deal_id) {
                    formData.append('deal', this.deal_id)
                }
                // Add
                await this.$http
                    .post('admin/my_topic/add_deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.dialogAddTopic = false;
                        this.getTopic();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }

            },
        async activeDeal() {

            var _this = this

            this.loading = true;
            let params = {};
            params.active = !this.deal_active;
          //  params.active = 1
            let deal_uuid = this.$route.params.id;
            await this.$http
                .put(`admin/deal/active/${deal_uuid}`, params)
                .then(res => {
                    this.deal_active = params.active;
                    if (params.active) {
                        this.$toastr.success(this.$t('deal_is_open'))
                    } else {
                        this.$toastr.success(this.$t('deal_is_closed'))
                    }

                })
                .catch(err => {
                    if (params.active) {
                        this.$toastr.error(this.$t('deal_was_not_open'))
                    } else {
                        this.$toastr.error(this.$t('deal_was_not_closed'))
                    }
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })

                .finally(end => {
                    this.loading = false
                })
        },

        addTracking() {
            this.$router.push({
                name: 'tracking.create.deal',
                params: {
                    deal_uuid: this.$route.params.id
                }
            })
        },
        addOperationTracking(uuid) {
            this.$router.push({
                name: 'tracking_operation.create',
                params: {
                   uuid: uuid
                }
            })
        },

        getCalendarCase() {
            let id_array = 0;
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                id_array = 1
            } else {
                id_array = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1
            }
            this.$refs.calendarCase.getCaseAdmin('deal', id_array);
        },
        handleClick(click, msg, date) {
            switch (click) {
                case 'delete':
                    this.deleteMessages(msg, date)
                    break;
                case 'reply' :
                    this.replyOpen(msg)
                    break;
            }
        },
        replyOpen(msg) {
            this.reply_uuid = msg.uuid
            this.reply_msg = msg.comment;
            this.reply_name = msg.name;
            this.toolbar_reply = true
        },
        replyClose() {
            this.scrollCard()
            this.reply_uuid = null
            this.reply_name = null
            this.toolbar_reply = false
            this.reply_msg = {}
        },
        setCurrency(item, currency) {

            item.currency = currency.character_code
            item.currency_id = currency.id

            item.exchange_rate = currency.exchange_rate

            if (typeof item.price === 'string' || item.price instanceof String) {
                item.price = item.price.replace(/\s/g, '')
                item.price = (item.price !== "") ? item.price : 0;
            }
            let price = item.price
            item.price = new Intl.NumberFormat('ru-RU').format(price)


            if (typeof item.total === 'string' || item.total instanceof String) {
                item.total = item.total.replace(/\s/g, '')
                item.total = (item.total !== "") ? item.total : 0;
            }
            item.total_usd = parseFloat(item.total) / parseFloat(currency.exchange_rate)
            item.total_usd = new Intl.NumberFormat('ru-RU').format(parseFloat(item.total_usd))
            item.total = new Intl.NumberFormat('ru-RU').format(item.total)

            item.show = false
            this.recalculate()
            this.saveDealOrder(item)
        },
        isNumber: function (evt, max, min) {
            evt = (evt) ? evt : window.event;
            let charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                if ((String(evt.target.value).length + 1) > max) {
                    evt.preventDefault();
                } else if (parseInt(evt.target.value) < min) {
                    evt.preventDefault();
                }
                return true;
            }
        },
        minNumber(evt) {
            if (parseInt(evt.target.value) < 1) {
                evt.target.value = 1
            }
        },
        setTotal(item) {

            let total = 0;
            let count = parseInt(item.count);

            if (typeof item.price === 'string' || item.price instanceof String) {
                item.price = item.price.replace(/\s/g, '')
                item.price = (item.price !== "") ? item.price : 0;
            }
            let price = parseFloat(item.price);
            if (typeof item.discount === 'string' || item.discount instanceof String) {
                item.discount = (item.discount !== "") ? item.discount : 0;
                item.discount = (item.discount !== "") ? item.discount : 0;
            }
            let discount = parseFloat(item.discount);

            if (typeof item.extra_charge === 'string' || item.extra_charge instanceof String) {
                item.extra_charge = (item.extra_charge !== "") ? item.extra_charge : 0;
                item.extra_charge = (item.extra_charge !== "") ? item.extra_charge : 0;
            }
            let extra_charge = parseFloat(item.extra_charge);
            if (count > 0) {
                total = count * (parseFloat(price) - parseFloat(discount) + parseFloat(extra_charge));
                item.total = new Intl.NumberFormat('ru-RU').format(total)
                item.total_usd = total / item.exchange_rate
                item.total_usd = new Intl.NumberFormat('ru-RU').format(item.total_usd)
            } else {
                item.total = 0;
                item.total_usd = 0;
            }

            let currency = this.currencyItems.filter(obj => {
                return obj.id === item.currency_id
            })
            this.setCurrency(item, currency[0])


        },
        recalculate() {
            let total_umount = 0;
            let total_umount_nds = 0;
            let total_umount_extra_charge = 0;
            this.dealOrderItems.forEach(function callback(item2) {
                if (typeof item2.total_usd === 'string' || item2.total_usd instanceof String) {
                    item2.total_usd = item2.total_usd.replace(/\s/g, '')
                    item2.total_usd = (item2.total_usd !== "") ? item2.total_usd : 0;
                }
                total_umount = parseFloat(total_umount) + parseFloat(item2.total_usd);

                if(item2.nds > 0){
                    total_umount_nds = parseFloat(total_umount_nds) + (parseFloat(item2.total_usd)  * (parseFloat(item2.nds) / 100));
                }

                if (typeof item2.total_usd === 'string' || item2.total_usd instanceof String) {
                    item2.total_usd = item2.total_usd.replace(/\s/g, '')
                }
                item2.total_usd = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.total_usd))

                if (typeof item2.total === 'string' || item2.total instanceof String) {
                    item2.total = item2.total.replace(/\s/g, '')
                }
                item2.total = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.total))

                if (typeof item2.price === 'string' || item2.price instanceof String) {
                    item2.price = item2.price.replace(/\s/g, '')
                }
                item2.price = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.price))

                if (typeof item2.discount === 'string' || item2.discount instanceof String) {
                    item2.discount = item2.discount.replace(/\s/g, '')
                }
                item2.discount = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.discount))

                if (typeof item2.extra_charge === 'string' || item2.extra_charge instanceof String) {
                    item2.extra_charge = item2.extra_charge.replace(/\s/g, '')
                }
                item2.extra_charge = new Intl.NumberFormat('ru-RU').format(parseFloat(item2.extra_charge))

                let count = parseInt(item2.count);
                total_umount_extra_charge = parseFloat(total_umount_extra_charge) + (parseFloat(item2.extra_charge) * count)  ;




            });

           // this.deal_order_nds_total = total_umount * (12 / 100);
            this.deal_order_nds_total = total_umount_nds;
            this.deal_order_no_nds_total = total_umount - this.deal_order_nds_total;
            this.deal_order_extra_charge_total = total_umount_extra_charge;

            this.deal_order_total = new Intl.NumberFormat('ru-RU').format(total_umount)
            this.deal_order_no_nds_total = new Intl.NumberFormat('ru-RU').format(parseFloat(this.deal_order_no_nds_total))
            this.deal_order_nds_total = new Intl.NumberFormat('ru-RU').format(parseFloat(this.deal_order_nds_total))

        },
        clearCompanies() {
            if (!this.company) {
                this.companyItems = []
            }
        },
        redirectToCompany(val) {
            if (val) {
                this.$router.push({
                    name: 'company.profile',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getCompanies(str) {
            if (str) {
                this.loadingCompanies = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.company = str
                }
                await this.$http
                    .get("admin/company", {
                        params: params,
                    })
                    .then(res => {
                        this.companyItems = res.body.data
                    })
                    .catch(err => {
                        this.companyItems = []
                    })
                    .finally(end => {
                        this.loadingCompanies = false
                    })
            }
        },

        async getDealStepButton() {

            this.loadingStepButton = true
            let params = {}
            params.deal_uuid = this.$route.params.id
            await this.$http
                .get("admin/deal_step_list", {
                    params: params,
                })
                .then(res => {
                    this.dealStepItems = res.body.data
                    this.step_color = res.body.current_step.color
                    this.deal_step_name = res.body.current_step.name
                    this.deal_step_button_text = res.body.current_step.text
                    this.dealStepItems.forEach((item) => {
                        if(this.application_form !== true && item.access === true){
                            this.application_form = item.required_tabs.includes('application');
                        }
                        if(this.order_form !== true && item.access  === true) {
                            this.order_form = item.required_tabs.includes('order');
                        }
                    });
                })
                .catch(err => {
                    this.dealStepItems = []
                })
                .finally(end => {
                    this.loadingStepButton = false
                })

        },
        async editStep(step_uuid) {


            var _this = this;
            this.progress = 0
            this.loadingStepButton = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.step_text) {
                formData.append('step_text', this.step_text)
            }
            formData.append('deal_uuid', this.$route.params.id)
            formData.append('step_uuid', step_uuid)

            if(this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            }
            else{
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }
            await this.$http
                .put(`admin/update_deal_step`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                    this.checkStepClose();
                    this.dealStepItems = res.body.data
                    this.step_color = res.body.current_step.color
                    this.under_deal = res.body.current_step.under_deal
                    this.deal_step_name = res.body.current_step.name
                    this.deal_step_button_text = res.body.current_step.text
                    if(res.body.current_step.deal_status!==null){
                        this.deal_status = res.body.current_step.deal_status
                    }
                    this.error_margin = '';
                    this.step_text = null;
                    this.dealStepItems.forEach((item) => {
                        if(this.application_form !== true && item.access  === true){
                            this.application_form  = item.required_tabs.includes('application');
                        }
                        if(this.order_form !== true && item.access  === true) {
                            this.order_form = item.required_tabs.includes('order');
                        }
                    });
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                    if (err && err.body && err.body.type_error) {

                    let type_error = err.body.type_error;
                    if (type_error === 'display_deal') {
                        if(this.display_deal === false){
                            this.display_open(type_error)
                        }
                        this.deal_error = 'border_error'
                    }
                    else if (type_error === 'display_tracking') {
                        if(this.display_tracking === false){
                            this.display_open(type_error)
                        }
                        this.tracking_error = 'border_error'
                    }
                    else if (type_error === 'display_order') {
                        if(this.display_order === false){
                            this.display_open(type_error)
                        }
                        this.order_error = 'border_error'
                    }
                    else if (type_error === 'display_application') {
                        if(this.display_application === false){
                            this.display_open(type_error)
                        }
                        this.application_error = 'border_error'
                    }
                    else if (type_error === 'display_invoice') {
                        if(this.display_invoice === false){
                            this.display_open(type_error)
                        }
                        this.invoice_error = 'border_error'
                    }
                    else if (type_error === 'display_document' && this.display_document === false) {
                        this.display_open(type_error)
                    } else if (type_error === 'required_filled') {
                        this.error_margin = 'text_red';
                    }

                    if (type_error === 'display_document'){
                        this.document_error = 'border_error'
                    }

                }
            })
            .finally(end => {
                this.checkStepClose();
                this.progress = 0
                this.loadingStepButton = false
            })


        },
        checkStep(item){
            this.document_error = '';
            this.deal_error = '';
            this.tracking_error = '';
            this.order_error = '';
            this.application_error = '';
            this.invoice_error = '';
            if(item.accompany_comment === 1){
                this.dialogAccompanyStep = true
                this.step_uuid = item.uuid
                this.button_text_step = item.name
            }
            else {
                this.editStep(item.uuid)
            }

        },
        checkStepClose(){
            this.dialogAccompanyStep = false
            this.step_uuid = null;
        },

        async getDealTypes(str) {
            if (str) {
                this.loadingDealTypes = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.deal_type = str
                }
                params.business_process = (this.business_process && this.business_process.id) ? this.business_process.id : null
                await this.$http
                    .get("admin/deal_type", {
                        params: params,
                    })
                    .then(res => {
                        this.dealTypeItems = res.body.data
                    })
                    .catch(err => {
                        this.dealTypeItems = []
                    })
                    .finally(end => {
                        this.loadingDealTypes = false
                    })
            }
        },
        clearDealTypes() {
            if (!this.deal_type) {
                this.dealTypeItems = []
            }
        },
        inputClose(item, field) {
            item[field] = false;
            this.setTotal(item);
        },
        inputOpen(item, field) {
            if (this.userBlock === true) {
                return;
            }
            if (typeof item.price === 'string' || item.price instanceof String) {
                item.price = item.price.replace(/\s/g, '')
            }
            if (typeof item.discount === 'string' || item.discount instanceof String) {
                item.discount = item.discount.replace(/\s/g, '')
            }
            if (typeof item.extra_charge === 'string' || item.extra_charge instanceof String) {
                item.extra_charge = item.extra_charge.replace(/\s/g, '')
            }
            item[field] = true
        },

        inputClosExpense(item, field) {
            if (field === 'show_price') {
                item.price = new Intl.NumberFormat('ru-RU').format(parseFloat(item.price))
            }
            if (field === 'show_type_expense') {
                item.type_expense = this.type_expense
            }
            item[field] = false;

            if (field === 'show_type_expense' || field === 'show_price') {
                this.saveExpenses(item)
            }


        },
        inputOpenExpense(item, field) {
            if (typeof item.price === 'string' || item.price instanceof String) {
                item.price = item.price.replace(/\s/g, '')
            }
            if (field === 'show_type_expense') {
                this.type_expense = item.type_expense
            }
            item[field] = true
        },

        addDealOrder(item) {
            if (item) {
                this.saveDealOrder(item);
                this.deal_type = null;
                this.dealTypeItems = [];
            }
        },

        async setStatusDeal(item) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()

            formData.append('deal_status_id', item.id);

            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }
            // Add
            await this.$http
                .put('admin/set/deal/status', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_status_has_been_updated'))
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_status_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

        async deleteDealOrder(item) {
            var _this = this;
            this.progress = 0
            this.loading = true

            await this.$http
                .delete(`admin/deal_order/${item.deal_order_uuid}`)
                .then(res => {
                    this.$toastr.success(this.$t('deal_type_has_been_deleted'))
                    if (res && res.body && res.body.data && res.body.data.extra_charge) {
                        this.extra_charge = res.body.data.extra_charge;
                        this.margin = res.body.data.margin;
                    }
                    let idx = this.dealOrderItems.indexOf(item);
                    this.dealOrderItems.splice(idx, 1);
                    this.recalculate()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_type_has_not_been_deleted'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },

        async saveDealOrder(item) {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (item.deal_type_id) {
                formData.append('deal_type', item.deal_type_id)
            }
            if (this.$route.params.id) {
                formData.append('deal', this.$route.params.id)
            }
            if (item.count) {
                formData.append('count', item.count)
            }
            if (item.price) {
                formData.append('price', item.price)
            }
            if (item.discount) {
                formData.append('discount', item.discount)
            }
            if (item.extra_charge) {
                formData.append('extra_charge', item.extra_charge)
            }
            if (item.exchange_rate) {
                formData.append('exchange_rate', item.exchange_rate)
            }
            if (item.currency_id) {
                formData.append('currency', item.currency_id)
            }
            if (item.total_usd) {
                formData.append('total_usd', item.total_usd)
            }
            if(item.nomenclature) {
                formData.append('nomenclature_id', item.nomenclature.id)
            }
            if(item.good) {
                formData.append('good', item.good)
            }

            if (item.deal_order_uuid) {
                // Save
                await this.$http
                    .put(`admin/deal_order/${item.deal_order_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.extra_charge) {
                            this.extra_charge = res.body.data.extra_charge;
                            this.margin = res.body.data.margin;
                        }

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/deal_order', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_order_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.uuid) {
                            item.deal_order_uuid = res.body.data.uuid;
                            this.dealOrderItems.push(item);
                        } else {
                            this.$toastr.error(this.$t('deal_order_has_not_been_added'))
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_order_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getDealOrders() {

            this.loadingDealOrders = true
            let params = {}
            params.filter = 'search'
            params.deal_uuid = this.deal_uuid
            await this.$http
                .get("admin/deal_order", {
                    params: params,
                })
                .then(res => {
                    this.dealOrderItems = res.body.data
                    this.recalculate();
                })
                .catch(err => {
                    this.dealOrderItems = []
                })
                .finally(end => {
                    this.loadingDealOrders = false
                })

        },

        clearResponsibles() {
            if (!this.responsibleAdd) {
                this.responsibleItems = []
            }
        },
        clearEmptyResponsibleItems(items) {
            if (items && items.length > 0) {
                this.responsibleAdd = items.filter(item => typeof item === 'object')
            }
        },
        async getResponsibles(str) {
            if (str) {
                this.loadingResponsibles = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.responsibleItems = res.body.data
                    })
                    .catch(err => {
                        this.responsibleItems = []
                    })
                    .finally(end => {
                        this.loadingResponsibles = false
                    })
            }
        },

        clearAuditors() {
            if (!this.auditorsAdd) {
                this.auditorItems = []
            }
        },
        clearEmptyAuditorItems(items) {
            if (items && items.length > 0) {
                this.auditorsAdd = items.filter(item => typeof item === 'object')
            }
        },
        async getAuditors(str) {
            if (str) {
                this.loadingAuditors = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.administrator = str
                }
                await this.$http
                    .get("admin/admin", {
                        params: params,
                    })
                    .then(res => {
                        this.auditorItems = res.body.data
                    })
                    .catch(err => {
                        this.auditorItems = []
                    })
                    .finally(end => {
                        this.loadingAuditors = false
                    })
            }
        },

        async getDealStatuses() {
            this.loadingDealStatuses = true
            let params = {}
            params.business_process = (this.business_process && this.business_process.id) ? this.business_process.id : null
            await this.$http
                .get("admin/deal_status", {
                    params: params,
                })
                .then(res => {
                    this.deal_statusItems = res.body.data
                })
                .catch(err => {
                    this.deal_statusItems = []
                })
                .finally(end => {
                    this.loadingDealStatuses = false
                })
        },
        async getDeal() {
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.deal_id = res.body.data.id
                    this.closed = res.body.data.closed
                    this.deal_currency = res.body.data.currency
                    this.deal_active = res.body.data.active
                    this.edit_business_process = res.body.data.business_process.id
                    this.favorite = res.body.data.favorite
                    this.payment_type = res.body.data.payment_type
                    this.deal_uuid = res.body.data.uuid
                    this.number = res.body.data.number
                    this.author = res.body.data.author
                    this.uved_show = res.body.data.uved
                    this.set_uved = res.body.data.uved
                    this.uved = res.body.data.uved.id
                    this.uvedItems = [res.body.data.uved]
                    this.text_deal = res.body.data.text
                    this.deal_status = res.body.data.deal_status.id
                    this.under_deal = res.body.data.deal_status.under_deal
                    this.deal_status_sort = res.body.data.deal_status.sort
                    this.responsibleAdd = res.body.data.responsibles
                    this.responsible = res.body.data.responsibles
                    this.auditorsAdd = res.body.data.auditors
                    this.auditors = res.body.data.auditors
                    this.executorsAdd = res.body.data.executors
                    this.executors = res.body.data.executors
                    this.is_moderator = res.body.data.is_moderator
                    this.moderators = res.body.data.moderators
                    this.auditor = res.body.data.auditors
                    this.company = res.body.data.company
                    this.companyItems = [res.body.data.company]
                    this.accompanying_document_urls = res.body.data.documents
                    this.created_at = res.body.data.created_at
                    this.heading = this.number + '. ' + res.body.data.uved.name
                    if(res.body.data.deal_step.color !== "#FF0000"){
                        this.step_color = res.body.data.deal_step.color
                    }
                    else {
                        this.step_color = res.body.data.deal_status_color
                    }
                    this.deal_step_id = res.body.data.deal_step.id
                    this.expenses = res.body.data.expenses
                    this.margin = res.body.data.margin
                    this.deal_kp_cost_of_services = res.body.data.deal_kp_cost_of_services
                    this.application_form_cost_of_services = res.body.data.application_form_cost_of_services
                    this.business_process = res.body.data.business_process
                    this.extra_charge = res.body.data.extra_charge
                    this.executor_deal_status = (res.body.data && res.body.data.executor_info && res.body.data.executor_info.status_text) ? res.body.data.executor_info.status_text : null
                    this.executor_text = (res.body.data && res.body.data.executor_info && res.body.data.executor_info.text )? res.body.data.executor_info.text : null
                    this.executor_date_approval = (res.body.data && res.body.data.executor_info && res.body.data.executor_info.date_approval) ? res.body.data.executor_info.date_approval : null
                    this.is_executor = res.body.data.is_executor

                })
                .catch(err => {
                    this.$router.push({name: 'error-404'})
                    this.$toastr.error(this.$t('failed_to_get_deal'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },
        async saveDeal() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.number) {
                formData.append('number', this.number)
            }

            if (this.text) {
                formData.append('text', this.text)
            }

            if (this.company) {
                if (this.company.id) {
                    formData.append('company', this.company.id)
                } else {
                    formData.append('company', this.company)
                }
            }

            if (this.deal_status) {
                if (this.deal_status.id) {
                    formData.append('deal_status', this.deal_status.id)
                } else {
                    formData.append('deal_status', this.deal_status)
                }
            }

            if (this.responsible && this.responsible.length > 0) {
                for (let i in this.responsible) {
                    if (this.responsible[i].id !== undefined && this.responsible[i].id > 0) {
                        formData.append(`responsibles[${i}]`, this.responsible[i].id)
                    }
                }
            }

            if (this.auditor && this.auditor.length > 0) {
                for (let i in this.auditor) {
                    if (this.auditor[i].id !== undefined && this.auditor[i].id > 0) {
                        formData.append(`auditors[${i}]`, this.auditor[i].id)
                    }
                }
            }

            if (this.files && this.files.length > 0) {
                let is_image = false;
                for (let i in this.files) {
                    is_image = this.mimeTypeImage(this.files[i].type);
                    formData.append(`type_mime[${i}]`, this.files[i].type)
                    formData.append(`size[${i}]`, this.files[i].size)
                    formData.append(`file_name[${i}]`, this.files[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)
                    formData.append(`upfiles[${i}]`, this.files[i])
                }
            }

            if (this.$route.params.id && this.deal_uuid) {
                await this.$http
                    .put(`admin/deal/${this.deal_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))

                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/deal', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_added'))
                        // if (res && res.body && res.body.data && res.body.data.id) {
                        //       this.$router.push({
                        //         name: 'deal.edit',
                        //         params: {
                        //             id: res.body.data.id
                        //         }
                        //     })
                        // } else {
                        //     this.$router.push({
                        //         name: 'deal'
                        //     })
                        // }
                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async DealTextUpdate() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.text_deal) {
                formData.append('text', this.text_deal)
            }

            if (this.$route.params.id && this.deal_uuid) {
                await this.$http
                    .put(`admin/deal_text/${this.deal_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.text_deal_edit = false
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        async getTracking() {
            var _this = this;
            this.progress = 0
            this.loadingTracking = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal/${this.$route.params.id}/tracking`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.trackings = res.body.data

                })
                .catch(err => {
                    this.trackings = [];
                    this.$toastr.error(this.$t('failed_to_get_list_trackings'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingTracking = false
                })
        },
        async getInvoices() {
            var _this = this;
            this.loadingInvoices = true;

            let params = {};
            if (this.language) {
                params.language = this.language
            }

            await this.$http
                .get(`admin/deal/${this.$route.params.id}/invoices`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.invoices = res.body.data
                })
                .catch(err => {
                    this.invoices = []
                    this.$toastr.error(this.$t('failed_to_get_list_invoices'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingInvoices = false
                })
        },
        async getCurrencies() {
            var _this = this;
            this.loadingCurrency = true;

            let params = {};
            if (this.language) {
                params.language = this.language
            }


            await this.$http
                .get(`admin/currency`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {

                    this.currencyItems = res.body.data

                })
                .catch(err => {
                    this.currencyItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_currencies'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loadingCurrency = false
                })
        },

        async saveRequest() {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()


            if (this.data_request_scanned.size) {
                formData.append('request_size', this.data_request_scanned.size)
            }
            if (this.data_request_scanned.file_name) {
                formData.append('request_file_name', this.data_request_scanned.file_name)
            }
            if (this.data_request_scanned.file_type) {
                formData.append('request_file_type', this.data_request_scanned.file_type)
            }

            if (this.request_scanned) {
                formData.append('request_scanned', this.request_scanned)
            }


            await this.$http
                .put(`admin/deal/${this.$route.params.id}/invoices`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('invoice_has_been_updated'))
                    if (res && res.body && res.body.data && res.body.data.exist_translations) {
                        this.exist_translations = res.body.data.exist_translations
                        this.all_translations = res.body.data.all_translations
                    }
                    //this.forceRerender()
                })
                .catch(err => {
                    this.$toastr.error(this.$t('invoice_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    if (prop === 'answer_scanned' || prop === 'request_scanned') {
                                        this.$toastr.error(err.body.errors[prop][0])
                                    }
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        showActiveClient(item) {
            this.trackings.map(detail => {
                    if (detail.id !== item.id) {
                        detail.show = false
                    }
                    detail.active_client = false

                }
            )
            item.active_client = !item.active_client;
        },
        showDetailsApplication(item) {
            this.detail_tracking = {};
            this.detail_tracking = item;
            this.dialogDetailTracking = true;
        },
        downloadFile(data) {
            fetch(data.url)
                .then((resp) => resp.blob())
                .then((blob) => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    this.$toastr.success(this.$t('file_download_started'))
                })
                .catch(() =>
                    this.$toastr.error(this.$t('file_download_error'))
                );
        },

        dropFile(event) {
            this.files = Array.from(event.dataTransfer.files);
            this.previewFiles();
        },
        previewFiles() {
            let i = this.document_urls.length
            let data = {}
            this.files.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.document_urls.push(data)
                i++
            })
            this.dialog_file = true
        },

        deleteFile(item) {
            let index = this.document_urls.indexOf(item)
            this.document_urls.splice(index, 1)
            if (this.document_urls.length === 0) {
                this.dialog_file = false;
                this.document_urls = [];
                this.documents = []
            }
        },
        deleteAccompanyingDocumentFile(item) {
            let index = this.accompanying_document_urls.indexOf(item)
            this.accompanying_document_urls.splice(index, 1)
        },
        deleteAccompanyingDocuments(item) {
            let index = this.accompanying_documents_preview.indexOf(item)
            this.accompanying_documents_preview.splice(index, 1)
            if (this.accompanying_documents_preview.length === 0) {
                this.accompanying_documents_dialog = false;
                this.accompanying_documents_preview = [];
                this.accompanying_documents = []
            }
        },


        async saveDealFile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                let is_image = false;
                for (let i in this.accompanying_documents_preview) {
                    is_image = this.mimeTypeImage(this.accompanying_documents_preview[i].type);
                    formData.append(`type_mime[${i}]`, this.accompanying_documents_preview[i].type)
                    formData.append(`size[${i}]`, this.accompanying_documents_preview[i].size)
                    formData.append(`file_name[${i}]`, this.accompanying_documents_preview[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    this.accompanying_documents_preview[i].blob = await this.convertBlobToBase64(this.accompanying_documents_preview[i].blob)
                    if (this.accompanying_documents_preview[i].blob) {
                        let blob = this.dataURL64toBlob(this.accompanying_documents_preview[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.accompanying_documents_preview[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                }
            }

            await this.$http
                .put(`admin/deal/file/${this.deal_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))

                    if (this.accompanying_documents_preview && this.accompanying_documents_preview.length > 0) {
                        for (let i in this.accompanying_documents_preview) {
                            let author = {
                                "author": {
                                    "name": this.$auth.user().name
                                }
                            };
                            Object.assign(this.accompanying_documents_preview[i], author);
                            this.accompanying_document_urls.push(this.accompanying_documents_preview[i])
                        }
                        this.accompanying_documents_dialog = false;
                        this.accompanying_documents_preview = [];
                        this.accompanying_documents = []
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('file_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },


        async deleteFileServer(item) {
            if (confirm(this.$t('want_to_delete_file'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/deal/file/${item.uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('deal_file_has_been_deleted'))
                        this.deleteAccompanyingDocumentFile(item)
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_file_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        DealMessageslWebsocket() {
            //console.log('ddddd' + `App.Models.Admin.${this.$auth.user().id}`)
            //  console.log('ddddd' + `.Event.Chat.${this.deal_uuid}`)
            window.Echo
                .private(`App.Models.Admin.${this.$auth.user().id}`)
                .listen(`.Event.DealChat.${this.deal_uuid}`, (event) => {
                    //   console.log(event)
                    //Если пришло добавление собщение
                    if (event && event.id && event.id > 0) {
                        if (event.comment && event.comment.type) {
                            // if(event.comment.type === 'text') {

                            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                                const newMessage = {
                                    [this.$moment().format('YYYY-MM-DD')]:
                                        [
                                            event
                                        ]
                                }
                                this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                            } else {
                                let index = Number(event.id_array) - 1;
                                let message_date = this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')];
                                if (typeof message_date[index] != "undefined") {
                                    message_date[index].id = event.id;
                                    //this.chatMessages[index].name = event.name;
                                    message_date[index].comment = event.comment;
                                    message_date[index].created_at = event.created_at;
                                    message_date[index].delivered = event.delivered;
                                    //  this.chatMessages[index].me = event.me;
                                    message_date[index].uuid = event.uuid;
                                } else {
                                    message_date.push(event);
                                }
                            }
                        }

                    } else if (event && event.type && event.type === 'delete') {
                        //Если пришло удаление собщение
                        if (event.id_array && event.id_array > 0 && event.date) {
                            //if(event.admin_id !== this.$auth.user().id){
                            this.chatMessagesDate[event.date].splice(event.id_array, 1);
                            // }

                        }
                    }
                    this.scrollCard();
                });
            /*
                .notification((notify) => {
                    //console.log('MainLayout notify')
                    console.log(notify)
                    if (notify.type.indexOf('broadcast.message') !== -1) {
                        this.$toastr.success(`${notify.title} ${notify.message}`)
                    }
                })
               */
        },
        handleEnterKey(event) {
            if (event.key === 'Enter' && !event.shiftKey && !event.ctrlKey && !event.altKey && !event.metaKey) {
                this.SentMessage();
            } else {
                event.preventDefault();
                if (!event.shiftKey) {
                    this.text += '\n';
                }
            }
        },
        async SentMessage() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (typeof this.text === 'undefined' || this.text === null || this.text === '') {
                return;
            }

            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.reply_uuid) {
                formData.append('reply_uuid', this.reply_uuid)
            }
            if (this.text) {
                formData.append('message', this.text)
            }
            formData.append('deal_uuid', this.deal_uuid)
            if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {
                formData.append('id_array', 1)
            } else {
                formData.append('id_array', this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1)
            }

            // Add
            await this.$http
                .post('admin/deal_message', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    let replyMessage = {}
                    if (this.reply_uuid) {
                        if (this.reply_msg.type === 'text') {
                            replyMessage = {
                                "type": this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid": this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                            }
                        } else {
                            replyMessage = {
                                "type": this.reply_msg.type,
                                "name": this.reply_name,
                                "reply_uuid": this.reply_msg.uuid,
                                "value": this.reply_msg.value,
                                "size": this.reply_msg.size,
                                "type_mime": this.reply_msg.type_mime,
                                "file_type": this.reply_msg.file_type,
                                "file_name": this.reply_msg.file_name,
                            }
                        }

                    }

                    if (this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')] === undefined) {

                        const newMessage = {
                            [this.$moment().format('YYYY-MM-DD')]:
                                [
                                    {
                                        "id": Number(new Date().getTime()) + 1,
                                        "uuid": "uuid",
                                        "me": true,
                                        "comment": {"type": "text", "value": this.text},
                                        "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                                        "delivered": false,
                                        "name": this.$auth.user().name,
                                        "phone": this.$auth.user().phone,
                                        "photo": this.$auth.user().photo,
                                        "reply": replyMessage
                                    }
                                ]
                        }
                        this.chatMessagesDate = {...this.chatMessagesDate, ...newMessage};
                    } else {
                        this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].push({
                            "id": Number(new Date().getTime()) + this.chatMessagesDate[this.$moment().format('YYYY-MM-DD')].length + 1,
                            "uuid": "uuid",
                            "me": true,
                            "comment": {"type": "text", "value": this.text},
                            "created_at": this.$moment().tz(this.timezone).format('YYYY-MM-DD HH:mm'),
                            "delivered": false,
                            "name": this.$auth.user().name,
                            "phone": this.$auth.user().phone,
                            "photo": this.$auth.user().photo,
                            "reply": replyMessage
                        });
                    }
                    this.text = ''
                    this.count_messages++;
                    this.replyClose();

                })
                .catch(err => {
                    this.$toastr.error(this.$t('message_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },

        accompanyingDocumentsPreviewFiles() {
            let i = 0;
            let data = {}
            this.accompanying_documents.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.accompanying_documents_preview.push(data)
                i++
            })
            this.accompanying_documents_dialog = true
        },
        showCompany(uuid) {
            this.$router.push({
                name: 'company.show',
                params: {
                    id: uuid
                }
            })
        },



        previewDocuments() {
            let i = this.documents_preview.length
            let data = {}
            this.getDocumentTypes();
            this.add_documents.forEach((file) => {
                data = {}
                data.id = i
                data.url = URL.createObjectURL(file)
                data.name = file.name
                data.blob = file
                data.size = file.size
                data.type = file.type
                this.documents_preview.push(data)
                i++
            })
            this.document_dialog = true
        },
        deleteDocument(item) {
            let index = this.documents_preview.indexOf(item)
            this.documents_preview.splice(index, 1)
            if (this.documents_preview.length === 0) {
                this.document_dialog = false;
                this.documents_preview = [];
                this.add_documents = [];
                this.document_type = null;
            }
        },
        async saveDocumentFile() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.document_type.id) {
                formData.append('document_type', this.document_type.id)
            }
            if (this.deal_uuid) {
                formData.append('deal_uuid', this.deal_uuid)
            }
            if (this.documents_preview && this.documents_preview.length > 0) {
                let is_image = false;
                for (let i in this.documents_preview) {
                    is_image = this.mimeTypeImage(this.documents_preview[i].type);
                    formData.append(`type_mime[${i}]`, this.documents_preview[i].type)
                    formData.append(`size[${i}]`, this.documents_preview[i].size)
                    formData.append(`file_name[${i}]`, this.documents_preview[i].name)
                    formData.append(`is_image[${i}]`, is_image ? 1 : null)

                    this.documents_preview[i].blob = await this.convertBlobToBase64(this.documents_preview[i].blob)
                    if (this.documents_preview[i].blob) {
                        let blob = this.dataURL64toBlob(this.documents_preview[i].blob)
                        if (blob) {
                            formData.append(`upfiles[${i}]`, blob, this.documents_preview[i].type)
                        }
                    }

                    // formData.append(`upfiles[${i}]`, this.accompanying_documents_preview[i].blob)
                }
            }

            await this.$http
                .post(`admin/deal_document`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                    _this.documents_preview = [];
                    _this.add_documents = [];
                    _this.document_type = null;
                    _this.document_dialog = false;
                    this.getDealDocuments();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('file_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        async getDocumentTypes() {

            this.loading = true
            let params = {}
            params.filter = 'deal'
            params.business_process = (this.business_process && this.business_process.id) ? this.business_process.id : null
            await this.$http
                .get("admin/document_type", {
                    params: params,
                })
                .then(res => {
                    this.documentTypeItems = res.body.data
                })
                .catch(err => {
                    this.documentTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getDealDocuments() {

            this.loading = true
            let params = {}
            // params.filter = 'search'
            params.deal_uuid = this.$route.params.id
            await this.$http
                .get("admin/deal_document", {
                    params: params,
                })
                .then(res => {
                    this.dealDocumentItems = res.body.data
                })
                .catch(err => {
                    this.dealDocumentItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async deleteDealDocument(item) {

            if (confirm(this.$t('want_to_delete_file'))) {
                var _this = this;
                this.progress = 0
                this.loading = true

                await this.$http
                    .delete(`admin/deal_document/${item.deal_document_uuid}`)
                    .then(res => {
                        this.$toastr.success(this.$t('document_type_has_been_deleted'))
                        let idx = this.dealDocumentItems.indexOf(item);
                        this.dealDocumentItems.splice(idx, 1);
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('document_type_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },

        async getSupplier() {

            this.loading = true
            let params = {}
            params.filter = 'task_bet';
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get("admin/supplier", {
                    params: params,
                })
                .then(res => {
                    this.supplierItems = res.body.data
                })
                .catch(err => {
                    this.supplierItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getTypeTransport() {

            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get("admin/type_transport", {
                    params: params,
                })
                .then(res => {
                    this.typeTransportItems = res.body.data
                })
                .catch(err => {
                    this.typeTransportItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getTypeContainer() {

            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get("admin/type_container", {
                    params: params,
                })
                .then(res => {
                    this.typeContainerItems = res.body.data
                })
                .catch(err => {
                    this.typeContainerItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async saveSupplier() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.supplier_text) {
                formData.append('supplier', this.supplier_text)
            }

            // Add
            await this.$http
                .post('admin/supplier', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('supplier_has_been_added'))
                    this.supplierDialogForm = false;
                    this.supplier_text = null;
                    this.getSupplier();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('supplier_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        async getPayers() {

            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get("admin/payer", {
                    params: params,
                })
                .then(res => {
                    this.payerItems = res.body.data
                })
                .catch(err => {
                    this.payerItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async getTypeExports() {

            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get("admin/type_export", {
                    params: params,
                })
                .then(res => {
                    this.type_exportItems = res.body.data
                })
                .catch(err => {
                    this.type_exportItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        async saveApplicationForm(){
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }
            if (this.shipper) {
                formData.append('shipper', this.shipper)
            }
            if (this.consignee) {
                formData.append('consignee', this.consignee)
            }
            if (this.place_loading) {
                formData.append('place_loading', this.place_loading)
            }
            if (this.unloading) {
                formData.append('unloading', this.unloading)
            }
            if (this.number_seats) {
                formData.append('number_seats', this.number_seats)
            }
            if (this.type_packaging) {
                formData.append('type_packaging', this.type_packaging)
            }
            if (this.weight_form) {
                formData.append('weight', this.weight_form)
            }
            if (this.volume_form) {
                formData.append('volume', this.volume_form)
            }
            if (this.cost_taking_cargo) {
                formData.append('cost_taking_cargo', this.cost_taking_cargo)
            }
            if (this.cost_export_clearance) {
                formData.append('cost_export_clearance', this.cost_export_clearance)
            }
            if (this.cost_cargo_transportation) {
                formData.append('cost_cargo_transportation', this.cost_cargo_transportation)
            }
            if (this.other) {
                formData.append('other', this.other)
            }
            if (this.note) {
                formData.append('note', this.note)
            }
            if (this.cost_of_product) {
                formData.append('cost_of_product', this.cost_of_product)
            }
            if (this.factory_contact_details) {
                formData.append('factory_contact_details', this.factory_contact_details)
            }
            if (this.special_requirements_factory) {
                formData.append('special_requirements_factory', this.special_requirements_factory)
            }
            if (this.additional_expenses_amount) {
                formData.append('additional_expenses_amount', this.additional_expenses_amount)
            }
            if (this.additional_expenses_text) {
                formData.append('additional_expenses_text', this.additional_expenses_text)
            }
            if (this.cost_of_services) {
                formData.append('cost_of_services', this.cost_of_services)
            }
            if (this.containers) {
                formData.append('containers', this.containers)
            }
            if (this.price_container) {
                formData.append('price_container', this.price_container)
            }
            if (this.loading_type_form) {
                if (this.loading_type_form.id) {
                    formData.append('loading_type', this.loading_type_form.id)
                } else {
                    formData.append('loading_type', this.loading_type_form)
                }
            }

            if (this.selectedCurrency) {
                if (this.selectedCurrency.id) {
                    formData.append('currency', this.selectedCurrency.id)
                } else {
                    formData.append('currency', this.selectedCurrency)
                }
            }

            if (this.order_number) {
                formData.append('order_number', this.order_number)
            }



            if (this.name_cargo) {
                formData.append('name_cargo', this.name_cargo)
            }
            if (this.supplier_form) {
                if (this.supplier_form.id) {
                    formData.append('supplier', this.supplier_form.id)
                } else {
                    formData.append('supplier', this.supplier_form)
                }
            }
            if (this.type_transport_form) {
                if (this.type_transport_form.id) {
                    formData.append('type_transport', this.type_transport_form.id)
                } else {
                    formData.append('type_transport', this.type_transport_form)
                }
            }
            if (this.payment_type_from) {
                if (this.payment_type_from.id) {
                    formData.append('payment_type', this.payment_type_from.id)
                } else {
                    formData.append('payment_type', this.payment_type_from)
                }
            }
            if (this.type_export) {
                if (this.type_export.id) {
                    formData.append('type_export', this.type_export.id)
                } else {
                    formData.append('type_export', this.type_export)
                }
            }
            if (this.type_container_form) {
                if (this.type_container_form.id) {
                    formData.append('type_container', this.type_container_form.id)
                } else {
                    formData.append('type_container', this.type_container_form)
                }
            }
            if (this.payer) {
                if (this.payer.id) {
                    formData.append('payer', this.payer.id)
                } else {
                    formData.append('payer', this.payer)
                }
            }


            if (this.application_form_uuid) {
                // Save
                await this.$http
                    .put(`admin/application_form/${this.application_form_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.margin = res.body.data.margin;
                        this.extra_charge = res.body.data.extra_charge;
                        this.deal_kp_cost_of_services = res.body.data.deal_kp_cost_of_services;
                        this.application_form_cost_of_services = res.body.data.application_form_cost_of_services;
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
            else {
                // Add
                await this.$http
                    .post('admin/application_form', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.application_form_uuid = res.body.data.application_form_uuid
                        this.margin = res.body.data.margin;
                        this.extra_charge = res.body.data.extra_charge;
                        this.deal_kp_cost_of_services = res.body.data.deal_kp_cost_of_services;
                        this.application_form_cost_of_services = res.body.data.application_form_cost_of_services;
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async getApplicationForm() {
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/application_form/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.deal_kp_uuid = null;
                    this.application_form_uuid = res.body.data.uuid
                    this.volume_form = res.body.data.volume;
                    this.order_number = res.body.data.order_number;
                    this.weight_form = res.body.data.weight;
                    this.cost_taking_cargo = res.body.data.cost_taking_cargo;
                    this.cost_export_clearance = res.body.data.cost_export_clearance;
                    this.cost_cargo_transportation = res.body.data.cost_cargo_transportation;
                    this.supplier_form = res.body.data.supplier;
                    this.type_transport_form = res.body.data.type_transport;
                    this.type_container_form = res.body.data.type_container;
                    this.payment_type_from = res.body.data.payment_type;
                    this.type_export = res.body.data.type_export;
                    this.payer = res.body.data.payer;
                    this.shipper = res.body.data.shipper;
                    this.consignee = res.body.data.consignee;
                    this.place_loading = res.body.data.loading;
                    this.unloading = res.body.data.unloading;
                    this.number_seats = res.body.data.number_seats;
                    this.type_packaging = res.body.data.type_packaging;
                    this.name_cargo = res.body.data.name_cargo;
                    this.other = res.body.data.other;
                    this.note = res.body.data.note;
                    this.cost_of_product = res.body.data.cost_of_product;
                    this.factory_contact_details = res.body.data.factory_contact_details;
                    this.special_requirements_factory = res.body.data.special_requirements_factory;
                    this.additional_expenses_amount = res.body.data.additional_expenses_amount;
                    this.additional_expenses_text = res.body.data.additional_expenses_text;
                    this.cost_of_services = res.body.data.cost_of_services;
                    this.containers = (res.body.data.containers && res.body.data.containers !== '') ? res.body.data.containers.toString() : null;
                    this.price_container = res.body.data.price_container;
                    this.loading_type_form = res.body.data.loading_type;
                    this.selectedCurrency = res.body.data.currency;
                    this.price = (parseFloat(res.body.data.cost_of_services) - parseFloat(res.body.data.additional_expenses_amount)).toString();

                })
                .catch(err => {
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(this.$t('failed_to_get_deal'))
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },
        async translationApplicationForm(){
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.application_form_uuid) {
                formData.append('application_form_uuid', this.application_form_uuid)
            }

            if (this.application_form_uuid) {

                // Save
                await this.$http
                    .post(`admin/translate_text`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('translated'))
                        this.getApplicationForm();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('translation_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },

        async downloadApplicationForm(){
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/application_file/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    const url = res.body.data.url;
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = res.body.data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_deal'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },

        async getLoadingType() {
            this.loading = true
            let params = {}
            await this.$http
                .get("admin/loading_type", {
                    params: params,
                })
                .then(res => {
                    this.loadingTypeItems = res.body.data
                })
                .catch(err => {
                    this.loadingTypeItems = []
                })
                .finally(end => {
                    this.loading = false
                })

        },
        checkTermsDelivery() {
            if (this.terms_delivery.toUpperCase().includes('EXW')) {
                this.show_place_argo_pickup = false;
            }
            else{
                this.show_place_argo_pickup = false
            }
        },


        async getDealKp() {
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal_kp/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.application_form_uuid = null;
                    this.deal_kp_uuid = res.body.data.uuid;
                    this.volume_form = res.body.data.volume;
                    this.weight_form = res.body.data.weight;
                    this.shipper = res.body.data.shipper;
                    this.from_city = res.body.data.from_city;
                    this.to_city = res.body.data.to_city;
                    this.departure = res.body.data.departure.id;
                    if(res.body.data.departure.id !== null){
                        this.departureItems = [res.body.data.departure];
                    }
                    if(res.body.data.destination.id !== null){
                        this.destination = res.body.data.destination.id;
                    }
                    this.destinationItems = [res.body.data.destination];
                    this.place_argo_pickup = res.body.data.place_argo_pickup;
                    this.name_cargo = res.body.data.name_cargo;
                    this.hs_code = res.body.data.hs_code;
                    this.special_text = res.body.data.special_text;
                    this.additional_expenses_text = res.body.data.additional_expenses_text;
                    this.terms_delivery = res.body.data.terms_delivery;
                    this.loading_type_form = res.body.data.loading_type;
                    this.type_transport_form = res.body.data.type_transport;
                    this.type_container_form = res.body.data.type_container;
                    this.dangerous_cargo = res.body.data.dangerous_cargo;
                    this.stackable_cargo = res.body.data.stackable_cargo;
                    this.insurance = res.body.data.insurance;
                    this.cost_of_services = res.body.data.cost_of_services;
                    this.price_container = res.body.data.price_container;
                    this.transit_station = res.body.data.transit_station;
                    this.containers = (res.body.data.containers && res.body.data.containers !== '') ? res.body.data.containers.toString() : null;
                    this.delivery_time = res.body.data.delivery_time;
                    this.additional_expenses_amount = res.body.data.additional_expenses_amount;
                    this.note = res.body.data.note;
                    this.selectedCurrency = res.body.data.currency;
                    this.price = (parseFloat(res.body.data.cost_of_services) - parseFloat(res.body.data.additional_expenses_amount)).toString();
                    this.checkTermsDelivery()
                })
                .catch(err => {

                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(this.$t('failed_to_get_deal'))
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },
        async saveDealKp(){
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.$route.params.id) {
                formData.append('deal_uuid', this.$route.params.id)
            }
            if (this.shipper) {
                formData.append('shipper', this.shipper)
            }
            if (this.from_city) {
                formData.append('from_city', this.from_city)
            }
            if (this.to_city) {
                formData.append('to_city', this.to_city)
            }
            if (this.place_argo_pickup) {
                formData.append('place_argo_pickup', this.place_argo_pickup)
            }
            if (this.name_cargo) {
                formData.append('name_cargo', this.name_cargo)
            }
            if (this.hs_code) {
                formData.append('hs_code', this.hs_code)
            }
            if (this.special_text) {
                formData.append('special_text', this.special_text)
            }
            if (this.additional_expenses_text) {
                formData.append('additional_expenses_text', this.additional_expenses_text)
            }
            if (this.terms_delivery) {
                formData.append('terms_delivery', this.terms_delivery)
            }
            if (this.loading_type_form) {
                if (this.loading_type_form.id) {
                    formData.append('loading_type', this.loading_type_form.id)
                } else {
                    formData.append('loading_type', this.loading_type_form)
                }
            }
            if (this.departure) {
                if (this.departure.id) {
                    formData.append('departure', this.departure.id)
                } else {
                    formData.append('departure', this.departure)
                }
            }
            if (this.destination) {
                if (this.destination.id) {
                    formData.append('destination', this.destination.id)
                } else {
                    formData.append('destination', this.destination)
                }
            }
            if (this.type_transport_form) {
                if (this.type_transport_form.id) {
                    formData.append('type_transport', this.type_transport_form.id)
                } else {
                    formData.append('type_transport', this.type_transport_form)
                }
            }
            if (this.type_container_form) {
                if (this.type_container_form.id) {
                    formData.append('type_container', this.type_container_form.id)
                } else {
                    formData.append('type_container', this.type_container_form)
                }
            }
            if (this.weight_form) {
                formData.append('weight', this.weight_form)
            }
            if (this.volume_form) {
                formData.append('volume', this.volume_form)
            }

            if (this.dangerous_cargo) {
                formData.append('dangerous_cargo', 1)
            }
            if (this.stackable_cargo) {
                formData.append('stackable_cargo', 1)
            }
            if (this.insurance) {
                formData.append('insurance', 1)
            }

            if (this.cost_of_services) {
                formData.append('cost_of_services', this.cost_of_services)
            }
            if (this.containers) {
                formData.append('containers', this.containers)
            }
            if (this.price_container) {
                formData.append('price_container', this.price_container)
            }
            if (this.selectedCurrency) {
                if (this.selectedCurrency.id) {
                    formData.append('currency', this.selectedCurrency.id)
                } else {
                    formData.append('currency', this.selectedCurrency)
                }
            }
            if (this.transit_station) {
                formData.append('transit_station', this.transit_station)
            }
            if (this.note) {
                formData.append('note', this.note)
            }
            if (this.delivery_time) {
                formData.append('delivery_time', this.delivery_time)
            }
            if (this.additional_expenses_amount) {
                formData.append('additional_expenses_amount', this.additional_expenses_amount)
            }





            if (this.deal_kp_uuid) {
                // Save
                await this.$http
                    .put(`admin/deal_kp/${this.deal_kp_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.margin = res.body.data.margin;
                        this.extra_charge = res.body.data.extra_charge;
                        this.deal_kp_cost_of_services = res.body.data.deal_kp_cost_of_services;
                        this.application_form_cost_of_services = res.body.data.application_form_cost_of_services;
                        this.deal_currency = this.selectedCurrency;
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('task_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
            else {
                // Add
                await this.$http
                    .post('admin/deal_kp', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('deal_has_been_updated'))
                        this.application_form_uuid = res.body.data.application_form_uuid
                        this.margin = res.body.data.margin;
                        this.extra_charge = res.body.data.extra_charge;
                        this.deal_kp_cost_of_services = res.body.data.deal_kp_cost_of_services;
                        this.application_form_cost_of_services = res.body.data.application_form_cost_of_services;
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('deal_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async translationDealKp(){
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.deal_kp_uuid) {
                formData.append('deal_kp_uuid', this.deal_kp_uuid)
            }

            if (this.deal_kp_uuid) {

                // Save
                await this.$http
                    .post(`admin/translate_text`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('translated'))
                        this.getDealKp();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('translation_error'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        async downloadDealKp(){
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal_kp_file/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    const url = res.body.data.url;
                    const a = document.createElement("a");
                    a.style.display = "none";
                    a.href = url;
                    a.download = res.body.data.name;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_deal'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },
        async getDepartures(str) {
            this.loadingDepartures = true
            let params = {};
            params.filter = 'search'
            if (str !== 'undefined') {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then(res => {
                    this.departureItems = res.body.data;
                })
                .catch(err => {
                    this.departureItems = []
                    this.$toastr.error(this.$t('failed_to_get_station'))
                })
                .finally(end => {
                    this.loadingDepartures = false
                })
        },
        clearDepartures() {
            if (!this.departure) {
                this.departuresItems = []
            }
        },
        async getDestination(str) {
            this.loadingDestination = true
            let params = {};
            params.filter = 'search'
            if (str !== 'undefined') {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then(res => {
                    this.destinationItems = res.body.data;
                })
                .catch(err => {
                    this.destinationItems = []
                    this.$toastr.error(this.$t('failed_to_get_station'))
                })
                .finally(end => {
                    this.loadingDestination = false
                })
        },
        clearDestination() {
            if (!this.destination) {
                this.destinationItems = []
            }
        },


        initHandler2(e) {
            var _this = this;
            if (this.coords && this.coords.length !== 2 && window.ymaps) {
                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: true,
                    autoReverseGeocode: true
                })
                location.then(
                    function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }
            e.geoObjects.options.set('draggable', true)
            _this.myMap = e;

        },
        onClickMap(e) {
            var _this = this
            this.coords = e.get('coords')
            // var myGeocoder = window.ymaps.geocode(this.coords)
            // myGeocoder.then(
            //     function (res) {
            //         let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
            //         if (GeocoderMetaData.Address.Components) {
            //             let Components = GeocoderMetaData.Address.Components
            //             let address_full = []
            //             let street_full = []
            //             let district = Components.filter(item => item.kind === 'district')
            //             if (district.length > 0) {
            //                 address_full.push(district[0].name)
            //                 street_full.push(district[0].name)
            //             }
            //             let street = Components.filter(item => item.kind === 'street')
            //             if (street.length > 0) {
            //                 address_full.push(street[0].name)
            //                 street_full.push(street[0].name)
            //             }
            //             let house = Components.filter(item => item.kind === 'house')
            //             if (house.length > 0) {
            //                 address_full.push(house[0].name)
            //                 _this.house = house[0].name
            //             }
            //             _this.street = street_full.join(', ')
            //             _this.address = address_full.join(', ')
            //         }
            //
            //     },
            //     function (err) {
            //         console.log('Error Load GeocoderMetaData')
            //     }
            // )
            //this.zoom = 16
        },
        ondDagendMap(e) {
            var _this = this
            this.coords = e.get('target').geometry.getCoordinates()
            // var myGeocoder = window.ymaps.geocode(this.coords)
            // myGeocoder.then(
            //     function (res) {
            //         let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
            //         if (GeocoderMetaData.Address.Components) {
            //             let Components = GeocoderMetaData.Address.Components
            //             let address_full = []
            //             let street_full = []
            //             let district = Components.filter(item => item.kind === 'district')
            //             if (district.length > 0) {
            //                 address_full.push(district[0].name)
            //                 street_full.push(district[0].name)
            //             }
            //             let street = Components.filter(item => item.kind === 'street')
            //             if (street.length > 0) {
            //                 address_full.push(street[0].name)
            //                 street_full.push(street[0].name)
            //             }
            //             let house = Components.filter(item => item.kind === 'house')
            //             if (house.length > 0) {
            //                 address_full.push(house[0].name)
            //                 _this.house = house[0].name
            //             }
            //             _this.street = street_full.join(', ')
            //             _this.address = address_full.join(', ')
            //         }
            //
            //     },
            //     function (err) {
            //         console.log('Error Load GeocoderMetaData')
            //     }
            // )
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getCountries() {
            var _this = this
            let params = {}
            params.list = 'all'
            await this.$http.get(`admin/country`, {
                    params: params,
                }
            )
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_countries'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },
        async saveStation() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.station_name) {
                formData.append('station_name', this.station_name)
            }
            if (this.code) {
                formData.append('code', this.code)
            }
            if (this.coords && this.coords.length > 0) {
                for (let i in this.coords) {
                    formData.append(`location[${i}]`, this.coords[i])
                }
            }
            if (this.country) {
                formData.append('country', this.country)
            }

            // Add
            await this.$http
                .post('admin/station', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('station_has_been_added'))
                    this.closeDialogStation();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('station_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },

        openAddStation(){
            this.dialogFormStation=true;
            this.add_type_station='departure';
            this.ymapInit()
        },
        closeDialogStation(){
            this.dialogFormStation = false;
            this.code = null;
            this.station_name =  null;
            this.exist_translations = {};
            this.all_translations = true;
            this.showAllMarkers = false;
            this.mapMarker = null;
            this.coordinates = [];
            this.zoom = 12;
            this.country = null;
            this.add_type_station = null;
        },

        async approvalSheet(){
            var _this = this;
            this.loading = true


            this.loading = true
            let params = {}
            if (this.language) {
                params.language =  this.language
            }
            if (this.$route.params.id) {
                params.deal_uuid =  this.$route.params.id
            }


            await this.$http
                .get(`admin/approval_sheet/deal`, {
                    params: params,
                })
                .then(res => {
                    if (res && res.body && res.body.data && res.body.data.url){
                        this.openFile(res.body.data)
                        //this.downloadFile(res.body.data)
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.loading = false
                })
        },

        markAsRead(message) {
            this.$http.post(`admin/deal_message/${message.id}/reader`).then(res => {
                message.is_readed = true;
            }).catch(err => {
            });
        },

        cancelDialogStatusExecutor(status) {
            this.status_executor = status
            this.displayCancelStatusExecutor = true;
        },

        cancelStatusExecutor() {
            this.editStatusExecutor(this.status_executor);
            this.displayCancelStatusExecutor = false;
            this.status_executor = null;
            this.status_executor_text = null;
        },
        closeDialogStatusExecutor() {
            this.displayCancelStatusExecutor = false;
            this.status_executor = null;
            this.status_executor_text = null;
        },

        async editStatusExecutor(status) {

            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)

            }

            if (this.status_executor_text) {
                formData.append('text', this.status_executor_text)
            }

            formData.append('status', status)

            formData.append('deal_step_id', this.deal_step_id)



            await this.$http
                .put(`admin/deal/status_executor/${this.deal_uuid}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('deal_has_been_updated'))
                    this.executor_deal_status = res.body.data.status
                    this.getDeal();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('deal_has_not_been_updated'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })


        },
        editApplication(item){
            this.$router.push({
                name: 'tracking.edit',
                params: {
                    uuid: item.uuid
                }
            })
        },
        async showDialogTracking(item){
            await this.getTypeContainer()
            await this.getTrackingEdit(item.uuid)
            this.dialogTracking = true;
        },
        async getTrackingEdit(uuid) {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/tracking/${uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.tracking_uuid = res.body.data.uuid
                    this.container_number = res.body.data.container_number
                    this.platform_number = res.body.data.platform_number
                    this.platform_number_knr = res.body.data.platform_number_knr
                    this.date_sending = res.body.data.date_sending ? this.$moment(res.body.data.date_sending).format('YYYY-MM-DD') : null;
                    this.date_border = res.body.data.date_border ? this.$moment(res.body.data.date_border).format('YYYY-MM-DD') : null;
                    this.date_gr = res.body.data.date_gr ? this.$moment(res.body.data.date_gr).format('YYYY-MM-DD') : null;
                    this.date_pr = res.body.data.date_pr ? this.$moment(res.body.data.date_pr).format('YYYY-MM-DD') : null;
                    this.distance = res.body.data.distance
                    this.station = (res.body.data.station && res.body.data.station.id) ? res.body.data.station.id : null;
                    this.stationItems = [res.body.data.station]
                    this.type_container = (res.body.data.type_container && res.body.data.type_container.id) ? res.body.data.type_container.id : null
                    this.status = (res.body.data.status && res.body.data.status.id) ? res.body.data.status.id : null;
                    this.sub_status = (res.body.data.sub_status && res.body.data.sub_status.id) ? res.body.data.sub_status.id : null
                    this.subStatusItems = [res.body.data.sub_status]
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_tracking'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveTracking() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.container_number) {
                formData.append('container_number', this.container_number)
            }
            if (this.platform_number) {
                formData.append('platform_number', this.platform_number)
            }
            if (this.platform_number_knr) {
                formData.append('platform_number_knr', this.platform_number_knr)
            }
            if (this.date_sending) {
                formData.append('date_sending', this.date_sending)
            }
            if (this.date_border) {
                formData.append('date_border', this.date_border)
            }
            if (this.date_gr) {
                formData.append('date_gr', this.date_gr)
            }
            if (this.date_pr) {
                formData.append('date_pr', this.date_pr)
            }
            if (this.distance) {
                formData.append('distance', this.distance)
            }

            if (this.station) {
                if (this.station.id) {
                    formData.append('station', this.station.id)
                } else {
                    formData.append('station', this.station)
                }
            }
            if (this.type_container) {
                if (this.type_container.id) {
                    formData.append('type_container', this.type_container.id)
                } else {
                    formData.append('type_container', this.type_container)
                }
            }

            if (this.status) {
                if (this.status.id) {
                    formData.append('status', this.status.id)
                } else {
                    formData.append('status', this.status)
                }
            }
            if (this.sub_status) {
                if (this.sub_status.id) {
                    formData.append('sub_status', this.sub_status.id)
                } else {
                    formData.append('sub_status', this.sub_status)
                }
            }

            if (this.tracking_uuid ) {
                await this.$http
                    .put(`admin/tracking_station/${this.tracking_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('tracking_has_been_updated'))
                        this.closeDialogTracking();
                        this.getDeal();
                        this.getTracking();

                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('tracking_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        closeDialogTracking(){
            this.dialogTracking = false;
            this.tracking_uuid = null;
            this.container_number = null;
            this.platform_number = null;
            this.platform_number_knr = null;
            this.date_sending = null;
            this.date_border = null;
            this.date_gr = null;
            this.date_pr = null;
            this.distance = null;
            this.station = null;
            this.stationItems = [];
            this.type_container = null;
            this.loading_type = null;
            this.type_transport = null;
            this.status =  null;
            this.sub_status =  null;
            this.subStatusItems = []

        },
        async getSubStatusList() {
            if(this.status && this.status > 0){
                this.loadingSubStatuses = true
                let params = {};
                params.filter = 'search'
                params.status_id = this.status

                await this.$http
                    .get("admin/sub_status", {
                        params: params,
                    })
                    .then(res => {
                        this.subStatusItems = res.body.data;
                    })
                    .catch(err => {
                        this.subStatusItems = []
                        this.$toastr.error(this.$t('failed_to_get_sub_status'))
                    })
                    .finally(end => {
                        this.loadingSubStatuses = false
                    })
            }

        },

        async getStations(str) {
            this.loadingStations = true
            let params = {};
            params.filter = 'search'
            if (str !== 'undefined') {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then(res => {
                    this.stationItems = res.body.data;
                })
                .catch(err => {
                    this.stationItems = []
                    this.$toastr.error(this.$t('failed_to_get_station'))
                })
                .finally(end => {
                    this.loadingStations = false
                })
        },
        clearStation() {
            if (!this.station) {
                this.stationItems = []
            }
        },

        onSelectNomenclatureBtnClick() {
            this.nomenclatureSelectPopupVisibility = false;

            this.saveDealOrder({
                nomenclature: this.nomenclature,
                price: 0,
                currency_id: this.currencyItems[0].id,
                exchange_rate: this.currencyItems[0].exchange_rate
            });

            this.nomenclature = null;
        },

        onGoodAddBtnClick() {
            this.goodSelectPopupVisibility = false;

            this.saveDealOrder({
                good: this.good_name,
                price: 0,
                currency_id: this.currencyItems[0].id,
                exchange_rate: this.currencyItems[0].exchange_rate
            });

            this.good_name = null;
        }
    }
}
</script>

<style scoped>
.photo_li{
    position: absolute;
    left: -10px;
    top: -44px;
}
.photo_li_last{
    position: absolute;
    left: 34px;
    top: -44px;
}
ul.pod_status li.my_divider{
    width: 24%;
}

.roadmap_wrapper {
    overflow-x: auto;
    white-space: nowrap;
}


.roadmap {
    list-style: none;
    display: flex;
    align-items: center;
    padding: 0;
    margin: 60px 0 0 0;
    height: 50px;

}
.li1_2 {
    text-align: center;
    position: relative;

}
.li1_2 p{
    position: absolute;
    top: -60px;
    min-width: 200px;
    left: 0;
    text-align: left;
}
.node_2 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 5px;
}
.blue_point_2 {
    background-color: #3498db;
}
.grey_point_2 {
    background-color: #bdc3c7;
}
.my_divider_2 {
    height: 1px;
    position: relative;
    top: 0;
    min-width: 287px !important;
}
.my_divider_3 {
    height: 1px;
    position: relative;
    top: 0;
    min-width: 590px !important;
}
.blue_line_2 {
    background-color: #3498db;
}
.grey_line_2 {
    background-color: #bdc3c7;
}
.li_last_bold {
    font-weight: bold;
}
.li_last_finish_2 {
    left: -190px !important;
    text-align: right !important;
}
.blue_color_2 {
    color: #3498db;
}
.grey_color_2 {
    color: #bdc3c7;
}

.grey_date_2{
    color: #B3B3B3 !important;
}

.li_last_center_2{
    left: -98px !important;
    text-align: center !important;
}

@media (max-width: 960px) {
    .v-toolbar{
        flex: none;
    }
    .tabs-nav{
        margin-bottom: 8px;
    }

    .my_divider_3{
        min-width: 93% !important
    }

    .tabs-nav__item{
        margin-right: 0;
    }
    .distance_0{
        padding: 10px 0 20px 0 !important;
    }


}
</style>


